import CryptoJS from 'crypto-js';


export function formatNumberWithCommas(number) {
    if(number){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return null;
}

export const planCategoryMap = {
    '1': 'Bike Investment',
    '2': 'Vehicle Investment',
    '3': 'Bond Yield Investment'
};

export const planIconMap = {
    '1': 'assets/img/icons/plan-price-01.svg',
    '2': 'assets/img/icons/plan-price-02.svg',
    '3': 'assets/img/icons/plan-price-03.svg'
};

export const planDurationMap = {
    '1': 'Weekly',
    '2': 'Monthly'
};

export const planDurationMapMinusLy = {
  '1': 'weeks',
  '2': 'months'
};

export function calculateToBePaid(returns, noOf, duration) {
    let toBePaid = 0;
  
    if (duration.toLowerCase() === 'weekly') {
      toBePaid = returns * noOf;
    } else if (duration.toLowerCase() === 'monthly') {
      // Assuming 4 weeks in a month
      toBePaid = returns * (noOf);
    }
  
    return toBePaid;
}
// const returns = 16000;
// const noOf = 50;
// const duration = 'weekly';

export function calculatePercentagePaid(amountPaidSoFar, amountToBeReturned) {
    if (amountToBeReturned <= 0) {
      return 0;  // Handle division by zero or negative amount to be returned
    }
  
    const percentage = (amountPaidSoFar / amountToBeReturned) * 100;
    return Math.min(100, percentage);  // Cap the percentage at 100% if needed
}

export function addEllipses(text, limit) {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
}

export const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data.toString(), secretKey).toString();
}

// Decrypt Data
export const decryptData = (data, secretKey) => {
    const thedata = data.replace(/ /g, '+'); // Replace spaces with +
    const decrypted = CryptoJS.AES.decrypt(thedata, secretKey);
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

    // console.log('Decrypted Data:', decryptedData);
    return decryptedData;
}

// Format Date
export const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  const amOrPm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12; // Handle midnight (0 hours)

  // Add leading zeros to minutes if needed
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${day} ${month} ${year}, ${hours}:${minutes} ${amOrPm}`;
};

export function formatOnlyDate(inputDate) {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const [day, month, year] = inputDate.split('-');
  const monthName = months[parseInt(month, 10) - 1];
  return `${parseInt(day, 10)} ${monthName} ${year}`;
}

export function todayDate() {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
}

export function convertDateFormatToyyyy_mm_dd(dateString) {
  // date format from 23-08-2021 to 2021-08-23
  // Check if the date string is in the format 'dd-mm-yyyy'
  const isDdMmYyyyFormat = /\d{2}-\d{2}-\d{4}/.test(dateString);

  if (isDdMmYyyyFormat) {
    const parts = dateString.split('-');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    return `${year}-${month}-${day}`;
  }

  // If the date string is already in the 'yyyy-mm-dd' format, return as is
  return dateString;
}

export function getCurrentPayFormattedMondayDate() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilMonday = dayOfWeek === 0 ? 1 : 1 - dayOfWeek;
  const mondayDate = new Date(today);
  mondayDate.setDate(today.getDate() + daysUntilMonday);

  const mondayFormattedDate = `${mondayDate.getDate().toString().padStart(2, '0')}-${(mondayDate.getMonth() + 1).toString().padStart(2, '0')}-${mondayDate.getFullYear()}`;
  const mondayFormattedMonthYear = `${mondayDate.getDate().toString().padStart(2, '0')} ${mondayDate.toLocaleString('en-us', { month: 'short' })} ${mondayDate.getFullYear()}`;

  return { mondayFormattedDate, mondayFormattedMonthYear };
}

// Loader
export const Loader = () => (
    <div className="loader">
      <p>Loading Data...</p>
    </div>
);

// Pagination
export const replaceQuotationMarks = (label) => {
    return label.replace(/&raquo;/g, '»').replace(/&laquo;/g, '«');
};

// Capitalize Each Word
export const capitalizeEveryWord =(sentence)=> {
    if (!sentence) return ''; // Handle empty input
  
    const words = sentence.split(' ');
  
    const capitalizedWords = words.map((word) => {
      // Capitalize the first letter and append the rest of the word
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    return capitalizedWords.join(' ');
  }


// Function to check the internet connection status
export function checkInternetConnection() {
  if (navigator.onLine) {
    // There is an internet connection.
    return true;
  } else {
    // There is no internet connection.
    return false;
  }
}