import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader } from '../../utilities/reusablefunctions_variables';

const Settings = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [passwordVisible3, setPasswordVisible3] = useState(false);

  const [loading, setLoading] = useState(true);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prevVisible => !prevVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(prevVisible => !prevVisible);
  }
  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(prevVisible => !prevVisible);
  }

  useEffect(() => {
    handleSettingsManagement();
  }, []);

const handleSettingsManagement =() =>{
    /* -------------------------------- GET USER ------------------------------- */
    $.ajax({
        type: 'GET',
        url: uRL+'admin',
        dataType: 'json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          console.log('Single User Object:', response);
          setLoading(false);
          if(response.user){
            $('#firstname').val(response.user.firstname);
            $('#lastname').val(response.user.lastname);
            $('#phone').val(response.user.phone);
            $('#address').val(response.user.address);

            $('#username').html(response.user.firstname+" "+response.user.lastname);
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
          
        }
    });
    /* -------------------------------- GET USER ------------------------------- */


    /* -------------------------------------------------------------------------- */
    /*                            EDIT USER PROFILE                              */
    /* -------------------------------------------------------------------------- */
    $('#updateprofile').off('submit');  // Unbind previous event handlers

    $('#updateprofile').on('submit',function(e){
        e.preventDefault();

        var user = $(this).serialize();
        if(user){
          $.ajax({
              type: 'POST',
              url: uRL+'admin/updateprofile',
              data: user,
              dataType:'json',
              beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn1").show(); $(".nlbtn1").hide();
              },
              success: function(response){
                // console.log(response);
                $(".lbtn1").show(); $(".nlbtn1").hide();
                toast.success(response.message);
                handleSettingsManagement();
              },
              error: function (xhr, textStatus, errorThrown) {
                setInterval(function() {$(".nlbtn1").show(); $(".lbtn1").hide(); },1500);
                if (xhr.responseJSON) {
                  if(Array.isArray(xhr.responseJSON.message)){
                    toast.error(xhr.responseJSON.message[0]);
                    return;
                  }
                  toast.error(xhr.responseJSON.message);
                  // console.error('Error message:', xhr.responseJSON.message);
                  // console.error('Error status:', xhr.responseJSON.status);
                }
              }
          });
        }
    });
    /* -------------------------------------------------------------------------- */
    /*                            EDIT USER PROFILE                              */
    /* -------------------------------------------------------------------------- */


    /* -------------------------------------------------------------------------- */
    /*                            EDIT USER PASSWORD                              */
    /* -------------------------------------------------------------------------- */
    $('#updatepassword').off('submit');  // Unbind previous event handlers

    $('#updatepassword').on('submit',function(e){
        e.preventDefault();

        var password = $(this).serialize();
        if(password){
          $.ajax({
              type: 'POST',
              url: uRL+'admin/changepassword',
              data: password,
              dataType:'json',
              beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn2").show(); $(".nlbtn2").hide();
              },
              success: function(response){
                // console.log(response);
                $(".lbtn2").show(); $(".nlbtn2").hide();
                toast.success(response.message);
                setTimeout(()=>{
                  logout();
                },2000)
                
              },
              error: function (xhr, textStatus, errorThrown) {
                setInterval(function() {$(".nlbtn2").show(); $(".lbtn2").hide(); },1500);
                if (xhr.responseJSON) {
                  if(Array.isArray(xhr.responseJSON.message)){
                    toast.error(xhr.responseJSON.message[0]);
                    return;
                  }
                  toast.error(xhr.responseJSON.message);
                  // console.error('Error message:', xhr.responseJSON.message);
                  // console.error('Error status:', xhr.responseJSON.status);
                }
              }
          });
        }
    });
    /* -------------------------------------------------------------------------- */
    /*                            EDIT USER PASSWORD                              */
    /* -------------------------------------------------------------------------- */
  }

  function clearCookies() {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  const logout =()=>{
    $.ajax({
      type: 'POST',
      url: uRL+'admin/logout',
      data: {},
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        toast.warning("Logging you out!!!");
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        localStorage.clear();
        clearCookies();
        setTimeout(()=>{
          // navigate('/login');
          window.location.replace('/login');
        },2000)
      },
      error: function (xhr, textStatus, errorThrown) {
        if (xhr.responseJSON) {
          if(Array.isArray(xhr.responseJSON.message)){
            toast.error(xhr.responseJSON.message[0]);
            return;
          }
          toast.error(xhr.responseJSON.message);
          // console.error('Error message:', xhr.responseJSON.message);
          // console.error('Error status:', xhr.responseJSON.status);
        }
      }
    });
  }



  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
    
            <div className="col-xl-12 col-md-12">
              <div className="content w-100 pt-0">
                <div className="content-page-header">
                  <h5>Account Settings</h5>
                </div>

                <form id='updateprofile'>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-title">
                        <h5>General Information</h5>
                      </div>
                    </div>
                    {loading && <Loader />}
                    {!loading && (
                    <>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="text" id='firstname' className="form-control" placeholder="Enter First Name"
                          name='firstname' required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="text" id='lastname' className="form-control" placeholder="Enter Last Name"
                          name='lastname' required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input type="number" id='phone' className="form-control" placeholder="Enter Mobile Number"
                          name='phone' length={11} required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" id='address' className="form-control" placeholder="Enter Address"
                          name='address' required />
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <button type='reset' className="btn btn-cancel bg-primary-light me-3">Cancel</button>
                        <button type='submit' className="nlbtn btn btn-primary">Save Changes</button>
                        <button type="button" className="lbtn btn btn-danger" disabled style={{display:'none'}}>Loading...</button>
                      </div>
                    </>
                    )}
                  </div>
                </form>

                <form id='updatepassword' className='mt-5'>
                  <div className='row'>
                    <div className="col-lg-12">
                      <div className="form-title">
                        <h5>Password Information</h5>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="pass-group" id="password1">
                        <div className="form-group">
                          <label>Old Password</label>
                          <div className="pass-group">
                            <input type={passwordVisible ? 'text' : 'password'} className="form-control pass-input" 
                            name="oldpassword" required />
                            <span className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                onClick={togglePasswordVisibility} style={{top: '25%'}}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="pass-group" id="passwordInput2">
                        <div className="form-group">
                          <label>New Password</label>
                          <div className="pass-group">
                            <input type={passwordVisible2 ? 'text' : 'password'} className="form-control pass-input"
                            name="newpassword" required />
                            <span className={`fas ${passwordVisible2 ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                onClick={togglePasswordVisibility2} style={{top: '25%'}}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="pass-group" id="passwordInput2">
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <div className="pass-group">
                            <input type={passwordVisible3 ? 'text' : 'password'} className="form-control pass-input"
                            name="cnewpassword" required />
                            <span className={`fas ${passwordVisible3 ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                onClick={togglePasswordVisibility3} style={{top: '25%'}}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="btn-path">
                        <button type='reset' className="btn btn-cancel bg-primary-light me-3">Cancel</button>
                        <button type='submit' className="nlbtn2 btn btn-primary">Save Changes</button>
                        <button type="button" className="lbtn2 btn btn-danger" disabled style={{display:'none'}}>Loading...</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Settings