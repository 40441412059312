import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Loader, addEllipses, calculateToBePaid, convertDateFormatToyyyy_mm_dd, decryptData, formatNumberWithCommas, planCategoryMap, planDurationMap, planDurationMapMinusLy, planIconMap, todayDate } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';

const EditInvestmentStartStopDateM = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        $(function() {
          handleInvestmentManagement();
        });
    }, []);

    const [investmentToEdit, setInvestmentToEdit] = useState([]);
    const [fetchedPlans, setFetchedPlans] = useState([]);
    const [fetchedBanks, setFetchedBanks] = useState([]);
    const [fetchedAccounts, setFetchedAccounts] = useState([]);
    const [fetchedNoks, setFetchedNoks] = useState([]);
    const [loading, setLoading] = useState(true);

    const params = new URLSearchParams(window.location.search);
    const encryptedInvestmentID = params.get('investment');
    console.log(encryptedInvestmentID)
    // Decrypt the Investor Code Number
    const decryptedInvestmentID = decryptData(encryptedInvestmentID, 'investmentid')
    // console.log('Decrypted Investment ID: ', decryptedInvestmentID);
    const encryptedInvestorCode = Cookies.get('investorcode');
    const decryptedInvestorCode = decryptData(encryptedInvestorCode, 'investorcode');


    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA
    const getBankNameFromCode = (bankCode) => {
        const bank = fetchedBanks.find(bank => bank.bankcode === bankCode);
        console.log(bank)
        return bank ? bank.name : 'Unknown Bank';
    };
    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA


    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */
    
    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */

    const handleInvestmentManagement =() =>{
        if(!encryptedInvestmentID){
            navigate('/investors');
            return;
        }

        /* --------------------- Fetch Single Investment Details -------------------- */
        setLoading(true);
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investments/get-investment/'+decryptedInvestmentID,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Single Investment to Edit:', response);
              setLoading(false);
              setInvestmentToEdit(response.investment);
              $('#edit_investmenttimeduration').html(response.investment.timeduration+" "+planDurationMapMinusLy[response.investment.period]);
              $('#edit_investmenttimedurationValue').val(response.investment.timeduration);
              $('#edit_investmentamountpaid').val(response.investment.amountpaid);
              $('#edit_investmentreturn').html("₦"+formatNumberWithCommas(response.investment.return)+" "+planDurationMap[response.investment.period]);
              $('#edit_investmenttimeremaining').html(response.investment.timeremaining);
              $('#edit_investmentperiod').html(planDurationMapMinusLy[response.investment.period]);
              $('#edit_investmentagreementdate').html(convertDateFormatToyyyy_mm_dd(response.investment.agreementdate));
              let status;
              if(response.investment.status === "0"){ status='<span class="badge bg-warning text-white">Not Ready for Payment</span>'}
              else if(response.investment.status === "1"){ status='<span class="badge bg-success text-white">On Payment</span>'}
              else if(response.investment.status === "2"){ status='<span class="badge bg-danger-light text-danger">Expired</span>'}
              else{ status=`<span class="badge bg-secondary text-white">Undefined Status</span>`}
              $('#edit_investmentstatus').html(status);
              $('#edit_investmentstartdate').val(convertDateFormatToyyyy_mm_dd(response.investment.startdate));
              $('#edit_investmentstopdate').val(convertDateFormatToyyyy_mm_dd(response.investment.stopdate));
              
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
            },
            complete: function() {
              
            }
          });
        /* --------------------- Fetch Single Investment Details -------------------- */


        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
        $.ajax({
          type: 'GET',
          url: uRL+'admin/plans',
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function(response) {
            console.log('Plans:', response);
            setLoading(false);
            setFetchedPlans(response.data);
          },
          error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
          },
          complete: function() {
            
          }
        });
        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'fetchbanks'}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Banks:', response);
              setLoading(false);
              setFetchedBanks(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */


        /* -------------------------------------------------------------------------- */
        /*                          FETCH INVESTMENT BANK ACCOUNTS                    */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'admin/investor/accounts?investor='+decryptedInvestorCode}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investment Account to Edit:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                          FETCH INVESTMENT BANK ACCOUNTS                    */
        /* -------------------------------------------------------------------------- */


        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/accounts?investor='+decryptedInvestorCode,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Bank Accounts:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */


        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/nextofkins?investor='+decryptedInvestorCode,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Nok:', response);
              setLoading(false);
              setFetchedNoks(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                                EDIT INVESTMENT                             */
        /* -------------------------------------------------------------------------- */
        $('#editInvestmentStopStartDate').off('submit');  // Unbind previous event handlers

        $('#editInvestmentStopStartDate').on('submit',function(e){
            e.preventDefault();
            var investmentdates = $(this).serialize();
            // Append the additional value to the serialized form data
            // const updatedInvestment = investment + `&investor=${investorid}`;
            if(investmentdates){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/investments/quickupdatemonth/'+decryptedInvestmentID,
                    data: investmentdates,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        $(".lbtn").hide(); $(".nlbtn").show();
                        // navigate('/investor-details?investor='+encryptedInvestorID+'&investorcode='+encryptedInvestorCodeNumber);
                        // window.history.go(-1);
                        handleInvestmentManagement();
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                EDIT INVESTMENT                             */
        /* -------------------------------------------------------------------------- */

    }



    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
            <div className="page-header">
                <div className="content-page-header">
                <h5>Edit Investment Period</h5>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    <div className="card customer-details-group">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No of Payment Duration</label>
                                        <span id='edit_investmenttimeduration' className='d-block'></span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Returns Amount (Eg. Monthly)</label>
                                        <span id='edit_investmentreturn' className='d-block'></span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <span id='edit_investmentstatus' className='d-block'></span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Agreement Date</label>
                                        <span id='edit_investmentagreementdate' className='d-block'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form id='editInvestmentStopStartDate'>
                        <div className="card-body">
                            <div className="form-group-customer customer-additional-form">
                                <div className="row">
                                    <h5 className="form-title">Details 
                                        (<span className='text-danger'>*</span> Required fields)
                                    </h5>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Start Date <span className='text-danger'>*</span></label>
                                            <input type="date" id="edit_investmentstartdate" className="form-control" placeholder="Start Date"
                                            name="startdate" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="date" id="edit_investmentstopdate"className="form-control" placeholder="End Date"
                                            name="stopdate" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>No of Payment Duration <span className='text-danger'>*</span></label>
                                            <input type="number" id="edit_investmenttimedurationValue"className="form-control" placeholder="Eg. 50"
                                            name="no_of" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Amount Paid <span className='text-danger'>*</span></label>
                                            <input type="number" id="edit_investmentamountpaid"className="form-control" placeholder="Eg. 1000000"
                                            name="amountpaid" required />
                                        </div>
                                    </div>                           
                                </div>
                                <div className="text-end">
                                    <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                    <button type="submit" className="nlbtn btn btn-primary">Edit Period</button>
                                    <button type="button" className="lbtn btn btn-danger" style={{display:'none'}}>Loading...</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    
                </div>
            </div>
            </div>
        </div>
    )
}

export default EditInvestmentStartStopDateM