import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, decryptData, encryptData, formatDate, formatNumberWithCommas, planDurationMap, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';
import { CSVLink } from 'react-csv';

const Investments = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const navigate = useNavigate();

  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedInvestments, setFetchedInvestments] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState([]);
  const [fetchedSearchPagination, setFetchedSearchPagination] = useState([]);

  const [fetchedWholeInvestments, setFetchedWholeInvestments] = useState([]);
  const [fetchedWholeOpenedInvestments, setFetchedWholeOpenedInvestments] = useState([]);

  const [investmentIDToDelete, setInvestmentIDToDelete] = useState(null);
  const [investmentIDToApprove, setInvestmentIDToApprove] = useState(null);
  const [investmentIDForHalt, setInvestmentIDForHalt] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [partURL, setPartURL] = useState('');
  const [loading, setLoading] = useState(true);

  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */
  const getPlanNameFromPlanID = (planID) => {
    let plan = fetchedPlans.find(plan => plan.id === parseInt(planID));
    return plan ? plan.name : 'Unknown Plan';
  };
  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */

  const handleInvestmentsManagement =(paginationRoute, fetchedplan_data) =>{

    /* -------------------------------- GET INVESTOR ------------------------------- */
    // $.ajax({
    //     type: 'GET',
    //     url: uRL+'admin/investors/'+decryptedInvestorId,
    //     dataType: 'json',
    //     beforeSend: function(xhr) {
    //       xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    //     },
    //     success: function(response) {
    //       console.log('Single Investor:', response);
    //       setLoading(false);
    //       setFetchedInvestor(response.investor);
    //     },
    //     error: function(xhr, textStatus, errorThrown) {
    //       console.error('Error:', textStatus);
    //       console.error('Status:', xhr.status);
    //       // Handle the error as needed
    //     },
    //     complete: function() {
          
    //     }
    // });
    /* -------------------------------- GET INVESTOR ------------------------------- */


    /* -------------------------------------------------------------------------- */
    /*                             FETCH ALL INVESTMENTS                          */
    /* -------------------------------------------------------------------------- */
    var parturl;
    if (partURL=="") {
      parturl='&search='+searchValue+'&perpage=10';
    }else{
      parturl='&'+partURL;
    }
    $.ajax({
        type: 'GET',
        url: `
          ${
            !paginationRoute ? uRL+'admin/investments/fetchinvestments?search='+searchValue+'&perpage=10' :
            paginationRoute+parturl
          }
        `,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
            //console.log('All Investments:', response);
            setLoading(false);
            setFetchedInvestments(response.data);
            setFetchedPagination(response.links);

            generateCsvData(response.data, fetchedplan_data); // Call generateCsvData after fetching data
        },
        error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
            // Handle the error as needed
        },
        complete: function() {
            
        }
    });
    /* -------------------------------------------------------------------------- */
    /*                             FETCH ALL INVESTMENTS                          */
    /* -------------------------------------------------------------------------- */
    


    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/plans',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Plans:', response);
        setLoading(false);
        setFetchedPlans(response.data);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */

    $(document).on('ready',function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }


  const fetchInvestmentPlans =()=>{
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/plans',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Plans:', response);
        setLoading(false);
        setFetchedPlans(response.data);
        handleInvestmentsManagement("", response.data);
        fetchTheWholeInvestments(response.data);
        fetchTheWholeOpenedInvestments(response.data);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
  }


  const fetchTheWholeInvestments =(allplans)=>{
    $.ajax({
      type: 'GET',
      url: `
          ${uRL+'admin/investments/fetchinvestments?perpage=100000000000000000000000000'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('The Whole Investments:', response);
        setLoading(false);
        setFetchedWholeInvestments(response.data);

        //console.log(response.data);

        generateWholeCsvData(response.data, allplans); // Call generateCsvData after fetching data
     
      },
      error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
      },
      complete: function() {
          
      }
    });
  }

  const fetchFilteredInvestments =(allplans)=>{
    var search = $('#search').val();
    //setSearchValue(search);
    var period = $('#period').val();
    var statuss = $('#statuss').val();
    var startdate = $('#startdate').val();
    var stopdate = $('#stopdate').val();
    $.ajax({
      type: 'GET',
      url: `
          ${uRL+'admin/investments/fetchinvestments?search='+search+'&status='+statuss+'&type='+period+'&startdate='+startdate+'&stopdate='+stopdate+'&perpage=100000000000000000000000000'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('The Whole Investments:', response);
        setLoading(false);
        setFetchedWholeInvestments(response.data);

        //console.log(response.data);

        generateWholeCsvData(response.data, allplans); // Call generateCsvData after fetching data
     
      },
      error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
      },
      complete: function() {
          
      }
    });
  }

  const fetchTheWholeOpenedInvestments =(allplans)=>{
    $.ajax({
      type: 'GET',
      url: `
          ${uRL+'admin/investments/fetchexportableinvestments?sortorder&sortby&perpage'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('The Whole Opened Investments:', response);
        setLoading(false);
        setFetchedWholeOpenedInvestments(response.data);

        //console.log(response.data);

        generateOpenedWholeCsvData(response.data, allplans); // Call generateCsvData after fetching data
     
      },
      error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
      },
      complete: function() {
          
      }
    });
  }


  useEffect(() => {
    // handleInvestmentsManagement();
    fetchInvestmentPlans();
    fetchTheWholeInvestments();
    fetchTheWholeOpenedInvestments();
    fetchFilteredInvestments();
  }, []);


  const handleSearchChange = () => {
    var search = $('#search').val();
    //setSearchValue(search);
    var period = $('#period').val();
    var statuss = $('#statuss').val();
    var startdate = $('#startdate').val();
    var stopdate = $('#stopdate').val();
    console.log('Search value:', search);
    searchInvestment(search, period, statuss, startdate, stopdate);
  };

  const searchInvestment =(searchword, period, statuss, startdate, stopdate)=> {
    var parturl='search='+searchword+'&status='+statuss+'&type='+period+'&startdate='+startdate+'&stopdate='+stopdate+'&perpage=10';
    setPartURL(parturl);
    $.ajax({
      type: 'GET',
      url: `${uRL+'admin/investments/fetchinvestments?'+parturl}`,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        //console.log('Investment Search:', response);
        setLoading(false);
        setFetchedInvestments(response.data);
        setFetchedPagination(response.links);
        fetchFilteredInvestments();
        // if(!searchword){
        //     handleInvestmentsManagement();
        // }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
  }

  const deleteInvestment =(investmentID)=>{
    if(investmentID){
        // alert(investmentCode);
        $.ajax({
            type: 'DELETE',
            url: uRL+'admin/investments/delete/'+investmentID,
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                $('#delete_modal').removeClass('show');
                $(".nlbtn").show(); $(".lbtn").hide();
                handleInvestmentsManagement();
                setLoading(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                $('#delete_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  }

  const approveInvestment =(investmentID)=>{
    if(investmentID){
        // alert(investmentCode);
        $.ajax({
            type: 'POST',
            url: uRL+'admin/investments/approve/'+investmentID,
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                $('#approve_modal').removeClass('show');
                $(".nlbtn").show(); $(".lbtn").hide();
                //handleInvestmentsManagement();
                setLoading(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                $('#approve_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  }

  const haltInvestment =(investmentID)=>{
    if(investmentID){
        $.ajax({
            type: 'POST',
            url: uRL+'admin/investments/haltinvestments',
            data: {
              investmentlist: [investmentID]
            },
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                $('#halt_modal').removeClass('show');
                $(".nlbtn").show(); $(".lbtn").hide();
                //handleInvestmentsManagement();
                setLoading(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                $('#halt_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  }

  const unhaltInvestment =(investmentID)=>{
    if(investmentID){
        $.ajax({
            type: 'POST',
            url: uRL+'admin/investments/unhaltinvestments',
            data: {
              investmentlist: [investmentID]
            },
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                $('#unhalt_modal').removeClass('show');
                $(".nlbtn").show(); $(".lbtn").hide();
                //handleInvestmentsManagement();
                setLoading(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                $('#unhalt_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  }

  const storeInvestorIDandCode =(investorID, investorCode)=> {
    // alert(investorID);
    Cookies.set('investorid', encryptData(investorID, 'investorid'), { expires: 5 });
    Cookies.set('investorcode', encryptData(investorCode, 'investorcode'), { expires: 5 });
  }



  /* ------------------------------- CSV REPORT ------------------------------- */
  const [csvData, setCsvData] = useState([]);
  const [csvWholeData, setWholeCsvData] = useState([]);
  const [csvWholeOpenedData, setWholeOpenedCsvData] = useState([]);
  // const headers = [
  //   { label:  }
  // ];

  const generateCsvData = (investmentData, fetchedplan_data) => {
    //console.log("investmentData ", investmentData);
    //console.log("fetchedplan_data ", fetchedplan_data);

    const data = investmentData.map((investment) => {
      // Find the plan corresponding to the investment's planid
      const plan = !fetchedplan_data ? "" : fetchedplan_data.find((plan) => plan.id === parseInt(investment.planid));

      // Check if the plan is found
      const planName = plan ? plan.name : "Plan Not Found";

      return {
        Investor: `${investment.investment_owner.firstname} ${investment.investment_owner.lastname} ${investment.investment_owner.othername}`,
        "Investor Code": `${investment.investment_owner.codenumber}`,
        // Add other fields as needed
        // Example:
        // "Account Details": `${investment.bank.name} ${investment.accountnumber}`,
        "Investment ID": investment.investmentid,
        Plan: !fetchedplan_data ? "" : planName,
        Period: planDurationMap[investment.period],
        "Agreement Date": investment.agreementdate,
        "Amount Paid": investment.amountpaid ? "₦" + formatNumberWithCommas(investment.amountpaid) : "",
        "AmountToBeReturned": investment.amount_to_be_returned ? "₦" + formatNumberWithCommas(investment.amount_to_be_returned) : "",
        Return: investment.return ? "₦" + formatNumberWithCommas(investment.return) : "",
        AmountPaidSoFar: investment.amountpaidsofar ? "₦" + formatNumberWithCommas(investment.amountpaidsofar) : "",
        LastPaymentDate: investment.lastpaymentdate ? investment.lastpaymentdate : "",
        "Start Date": investment.startdate,
        "Stop Date": investment.stopdate,
        Status:
          investment.status === "0" ? "Not Ready for Payment" : 
          investment.status === "1" ? "On Payment" : 
          investment.status === "2" ? "Expired" : 
          "Undefined Status",
        // Add more fields based on your requirements
      };
    });

    // Set CSV data to state
    //console.log("generateCsvData: ", data);
    setCsvData(data);
  };

  // const csvReport = {
  //   filename: "Investible_weekly_investment.csv",
  //   headers: Headers,
  //   data: data
  // }

  const generateWholeCsvData = (investmentData, fetchedplan_data) => {
    //console.log("investmentData ", investmentData);
    //console.log("fetchedplan_data ", fetchedplan_data);

    const data = investmentData.map((investment) => {
      // Find the plan corresponding to the investment's planid
      const plan = !fetchedplan_data ? "" : fetchedplan_data.find((plan) => plan.id === parseInt(investment.planid));

      // Check if the plan is found
      const planName = plan ? plan.name : "Plan Not Found";

      return {
        Investor: `${investment.investment_owner.firstname} ${investment.investment_owner.lastname} ${investment.investment_owner.othername}`,
        "Investor Code": `${investment.investment_owner.codenumber}`,
        // Add other fields as needed
        // Example:
        // "Account Details": `${investment.bank.name} ${investment.accountnumber}`,
        "Investment ID": investment.investmentid,
        Plan: !fetchedplan_data ? "" : planName,
        Period: planDurationMap[investment.period],
        "Agreement Date": investment.agreementdate,
        "Amount Paid": investment.amountpaid ? "₦" + formatNumberWithCommas(investment.amountpaid) : "",
        "AmountToBeReturned": investment.amount_to_be_returned ? "₦" + formatNumberWithCommas(investment.amount_to_be_returned) : "",
        Return: investment.return ? "₦" + formatNumberWithCommas(investment.return) : "",
        AmountPaidSoFar: investment.amountpaidsofar ? "₦" + formatNumberWithCommas(investment.amountpaidsofar) : "",
        LastPaymentDate: investment.lastpaymentdate ? investment.lastpaymentdate : "",
        "Start Date": investment.startdate,
        "Stop Date": investment.stopdate,
        Status:
          investment.status === "0" ? "Not Ready for Payment" : 
          investment.status === "1" ? "On Payment" : 
          investment.status === "2" ? "Expired" : 
          "Undefined Status",
        // Add more fields based on your requirements
      };
    });

    // Set CSV data to state
    //console.log("generateWholeCsvData: ", data);
    setWholeCsvData(data);
  };

  const generateOpenedWholeCsvData = (investmentData, fetchedplan_data) => {
    //console.log("investmentData ", investmentData);
    //console.log("fetchedplan_data ", fetchedplan_data);

    const data = investmentData.map((investment) => {
      // Find the plan corresponding to the investment's planid
      const plan = !fetchedplan_data ? "" : fetchedplan_data.find((plan) => plan.id === parseInt(investment.planid));

      // Check if the plan is found
      const planName = plan ? plan.name : "Plan Not Found";

      return {
        Investor: `${investment.investment_owner.firstname} ${investment.investment_owner.lastname} ${investment.investment_owner.othername}`,
        "Investor Code": `${investment.investment_owner.codenumber}`,
        // Add other fields as needed
        // Example:
        // "Account Details": `${investment.bank.name} ${investment.accountnumber}`,
        "Investment ID": investment.investmentid,
        Plan: !fetchedplan_data ? "" : planName,
        Period: planDurationMap[investment.period],
        "Agreement Date": investment.agreementdate,
        "Amount Paid": investment.amountpaid ? "₦" + formatNumberWithCommas(investment.amountpaid) : "",
        "AmountToBeReturned": investment.amount_to_be_returned ? "₦" + formatNumberWithCommas(investment.amount_to_be_returned) : "",
        Return: investment.return ? "₦" + formatNumberWithCommas(investment.return) : "",
        AmountPaidSoFar: investment.amountpaidsofar ? "₦" + formatNumberWithCommas(investment.amountpaidsofar) : "",
        LastPaymentDate: investment.lastpaymentdate ? investment.lastpaymentdate : "",
        "Start Date": investment.startdate,
        "Stop Date": investment.stopdate,
        Status:
          investment.status === "0" ? "Not Ready for Payment" : 
          investment.status === "1" ? "On Payment" : 
          investment.status === "2" ? "Expired" : 
          "Undefined Status",
        // Add more fields based on your requirements
      };
    });

    // Set CSV data to state
    //console.log("generateWholeOpenedCsvData: ", data);
    setWholeOpenedCsvData(data);
  };
  /* ------------------------------- CSV REPORT ------------------------------- */



  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
            
          {/*  --------------------------- Investor Investment Table --------------------------  */}
          <div className="page-header">
              <div className="content-page-header">
                <h5>Investments</h5>
              </div>
          </div>


          {/* <!-- search bar --> */}
          <div className="search-bar justify-content-end mb-2">
            <form className="form-inline row my-2 mx-1">
              <div className="col-md-4">
                <label>Name or InvestmentID or AccountNumber</label>
                <input className="form-control mr-sm-2" id="search" type="search" placeholder="Search" aria-label="Search" onInput={()=>handleSearchChange()}/>
              </div>
              <div className="col-md-2">
                <label>Period</label>
                <select className="form-control mr-sm-2" id="period" onChange={()=>handleSearchChange()}>
                  <option value="">--Select Period --</option>
                  <option value="1">Weekly</option>
                  <option value="2">Monthly</option>
                </select>
              </div>
              <div className="col-md-2">
                <label>Investment Status</label>
                <select className="form-control mr-sm-2" id="statuss" onChange={()=>handleSearchChange()}>
                  <option value="">--Select Status --</option>
                  <option value="0">Upcoming</option>
                  <option value="1">Ongoing</option>
                  <option value="2">Expired</option>
                </select>
              </div>
              <div className="col-md-2">
                <label>Start Date</label>
                <input className="form-control mr-sm-2" id="startdate" type="date" placeholder="Start Date" aria-label="Start Date" onBlur={()=>handleSearchChange()}/>
              </div>
              <div className="col-md-2">
              <label>Stop Date</label>
                <input className="form-control mr-sm-2" id="stopdate" type="date" placeholder="Stop Date" aria-label="Stop Date" onBlur={()=>handleSearchChange()}/>
              </div>
            </form>
            &nbsp;&nbsp;
            {/* <CSVLink data={csvData} filename="general_investments_batch.csv">
              <button className="btn btn-dark"><i className='fa fa-download'></i> CSV</button>
            </CSVLink>
            &nbsp;&nbsp;
            <CSVLink data={csvWholeOpenedData} filename="general_openedinvestments_all.csv">
              <button className="btn btn-primary"><i className='fa fa-download'></i> CSV(Opened)</button>
            </CSVLink> */}
            &nbsp;&nbsp;
            <CSVLink data={csvWholeData} filename="general_investments_all.csv">
              <button className="btn btn-dark"><i className='fa fa-download'></i> CSV</button>
            </CSVLink>
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-stripped table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Investor</th>
                            <th>Account Details</th>
                            <th>ID</th>
                            <th>Plan</th>
                            <th>Period</th>
                            <th>Agreement<br/>Date</th>
                            <th>Amount<br/>Paid</th>
                            <th>Amount to<br/>be Returned</th>
                            <th>Return Per <br/>Period</th>
                            <th>Amount Paid<br/>So Far</th>
                            <th>Last Payment<br/>Date</th>
                            <th>Start Date</th>
                            <th>Stop Date</th>
                            <th>Halted</th>
                            <th>Status</th>
                            <th>Approved?</th>
                            <th className="text-end">Action</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedInvestments.map((investment,index) => (
                            <tr key={index}>
                              <td>
                                {investment.investment_owner.firstname} {investment.investment_owner.lastname}&nbsp;
                                {investment.investment_owner.othername}<br/><small>{investment.investment_owner.codenumber}</small>
                              </td>
                              <td>
                                {investment.bank.name}<br/>{investment.accountnumber}
                              </td>
                              <td>
                                <b>{investment.investmentid}</b><br/>
                                <span className='d-flex'>
                                  {!investment.pop ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.pop} target="_blank">
                                            <img src="assets/img/receipt.png" width={30} alt="Receipt"
                                              data-toggle="tooltip"
                                              title="Proof of Payment"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }

                                  {!investment.agreementdoc ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.agreementdoc} target="_blank">
                                            <img src="assets/img/document.png" width={30} alt="Agreement doc" 
                                              data-toggle="tooltip"
                                              title="Agreement Document"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }
                                </span>

                              </td>
                              <td>{getPlanNameFromPlanID(investment.planid)}</td>
                              <td>{planDurationMap[investment.period]}</td>
                              <td>{investment.agreementdate}</td>
                              <td>{investment.amountpaid ? "₦"+formatNumberWithCommas(investment.amountpaid) : ""}</td>
                              <td>{investment.amount_to_be_returned ? "₦"+formatNumberWithCommas(investment.amount_to_be_returned) : ""}</td>
                              <td>{investment.return ? "₦"+formatNumberWithCommas(investment.return) : ""}</td>
                              <td>{investment.amountpaidsofar ? "₦"+formatNumberWithCommas(investment.amountpaidsofar) : ""}</td>
                              <td>{investment.lastpaymentdate ? investment.lastpaymentdate : ""}</td>
                              <td>{investment.startdate}</td>
                              <td>{investment.stopdate}</td>
                              <td>
                                {
                                  investment.hold === "2" ? <span className="badge bg-light-gray text-secondary">Halted</span> :
                                  "--"
                                }
                              </td>
                              <td>
                                {
                                  investment.status === "0" ? <span className="badge bg-warning-light text-warning">Not Ready for Payment</span> :
                                  investment.status === "1" ? <span className="badge bg-success-light">On Payment</span> :
                                  investment.status === "2" ? <span className="badge bg-danger-light text-danger">Expired</span> :
                                  <span className="badge bg-light-gray text-secondary">Undefined Status</span>
                                }
                              </td>
                              <td>
                                {
                                  investment.approve === "0" ? <span className="badge bg-warning-light text-warning">Not Approved</span> :
                                  investment.approve === "1" ? <span className="badge bg-success-light">Approved</span> :
                                  <span className="badge bg-light-gray text-secondary">Unknown</span>
                                }
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <span role='button' className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end customer-dropdown">
                                    <ul>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/edit-investment?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-edit me-2"></i>Edit 
                                        </Link>
                                      </li>
                                      <li>
                                        {
                                          investment.type=='1' ?
                                          <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                          to={`/edit-investment-startstopdate?investment=${encryptData(investment.id, 'investmentid')}`}>
                                            <i className="far fa-calendar me-2"></i>Start/Stop Date 
                                          </Link>
                                          :
                                          <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                          to={`/edit-investment-startstopdate-month?investment=${encryptData(investment.id, 'investmentid')}`}>
                                            <i className="far fa-calendar me-2"></i>Start/Stop Date 
                                          </Link>
                                        }
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/investment-details?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-eye me-2"></i>View 
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td>
                                { investment.approve === "0" ? <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#approve_modal" onClick={()=>setInvestmentIDToApprove(investment.id)}>Approve</button>  : "" } &nbsp;
                                { investment.hold === "2" ? 
                                <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#unhalt_modal" onClick={()=>setInvestmentIDForHalt(investment.investmentid)}>Unhalt</button>  : 
                                <button type="button" className="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#halt_modal" onClick={()=>setInvestmentIDForHalt(investment.investmentid)}>Halt</button> } &nbsp;
                                <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={()=>setInvestmentIDToDelete(investment.id)}>Delete</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestmentsManagement(data.url, fetchedPlans)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))}

                        {/* {fetchedSearchPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestmentsManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))} */}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  --------------------------- Investor Investment Table --------------------------  */}







          {/* Start Delete Modal */}
          <div className="modal custom-modal fade" id="delete_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Investment</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn" onClick={()=>deleteInvestment(investmentIDToDelete)}>Delete</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Delete Modal */}

          {/* Stare Approve Modal */}
          <div className="modal custom-modal fade" id="approve_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Approve Investment</h3>
                    <p>Are you sure want to approve?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn" onClick={()=>approveInvestment(investmentIDToApprove)}>Approve</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Approve Modal */}

          {/* Stare Halt Modal */}
          <div className="modal custom-modal fade" id="halt_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Halt Investment</h3>
                    <p>Are you sure want to halt?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn" onClick={()=>haltInvestment(investmentIDForHalt)}>Halt</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Halt Modal */}

          {/* Stare UnHalt Modal */}
          <div className="modal custom-modal fade" id="unhalt_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Unhalt Investment</h3>
                    <p>Are you sure want to unhalt?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn" onClick={()=>unhaltInvestment(investmentIDForHalt)}>UnHalt</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End UnHalt Modal */}
          
        </div>
    </div>
  )
}

export default Investments