import React, { useEffect, useState, useRef } from 'react'
import $ from 'jquery'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';


const Login = () => {
    const uRL = process.env.REACT_APP_URL;

    const [passwordVisible, setPasswordVisible] = useState(false);
    // const [OTP, setOTP] = useState(null);
    const [Phone, setPhone] = useState(null);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prevVisible => !prevVisible);
    };

    useEffect(() => {
        handleLoginManagement();
    });

    const handleLoginManagement =() =>{

        // Password Show
     
        $('#userLogin').off('submit');  // Unbind previous event handlers

        $('#userLogin').on('submit',function(e){
            e.preventDefault();
            var user = $(this).serialize();
            if(user){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/auth/login',
                    data: user,
                    dataType:'json',
                    beforeSend: function(){$(".lbtn").show(); $(".nlbtn").hide();},
                    success: function(response){
                        console.log(response);
                        setPhone(response.phone);
                        toast.success(response.message);
                        $('#otp_modal').show();
                        document.getElementById("otp_modal").style.opacity=100;
                        // Cookies.set('user', JSON.stringify(response.admin), { expires: 1 });
                        // Cookies.set('userAuth', response.token, { expires: 1 });
                        // window.location.replace('/dashboard');
                        setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        // Log the error details
                        // console.error('Error:', textStatus);
                        // console.error('Status:', xhr.status);
                        // console.error('Error thrown:', errorThrown);
                        // If the response is in JSON, you can log the message and status
                        if (xhr.responseJSON) {
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
    }

    const Login =(Phone)=>{
        var OTP = $('#otpp').val();
        if(OTP==""){ toast.error("OTP not entered"); return; }
        if(OTP!=""){
            console.log(OTP+` `+Phone);
            $.ajax({
                type: 'POST',
                url: uRL+'admin/auth/verifyotp',
                data: {
                    otp: OTP,
                    phone: Phone
                },
                dataType:'json',
                beforeSend: function(){$(".lbtn").show(); $(".nlbtn").hide();},
                success: function(response){
                    console.log(response);
                    toast.success("Login Granted");
                    $('#otp_modal').hide();
                    document.getElementById("otp_modal").style.opacity=0;
                    Cookies.set('user', JSON.stringify(response.admin), { expires: 1 });
                    Cookies.set('userAuth', response.token, { expires: 1 });
                    window.location.replace('/dashboard');
                },
                error: function (xhr, textStatus, errorThrown) {
                    // Log the error details
                    // console.error('Error:', textStatus);
                    // console.error('Status:', xhr.status);
                    // console.error('Error thrown:', errorThrown);
                    // If the response is in JSON, you can log the message and status
                    if (xhr.responseJSON) {
                        toast.error(xhr.responseJSON.message);
                        // console.error('Error message:', xhr.responseJSON.message);
                        // console.error('Error status:', xhr.responseJSON.status);
                        setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                    }
                }
            });
        }
    }
    const CancelLogin =(Phone)=>{
        $('#otp_modal').hide();
        document.getElementById("otp_modal").style.opacity=0;
    }
    return (
        <div className="main-wrapper login-body">
        <div className="login-wrapper">
            <div className="container">
                {/* <img className="img-fluid logo-dark mb-2" src="assets/img/w_logo1.png" alt="Logo" /> */}
                <div style={{fontSize:30,color:'#28084B',fontWeight:'bold',textAlign:'center'}}>
                    INVESTIBLE
                </div>
                <div className="loginbox">
                    <div className="login-right">
                    <div className="login-right-wrap">
                        <h1>Login</h1>
                        <p className="account-subtitle">Access to your dashboard</p>
                        <form id='userLogin'>
                        <div className="form-group">
                            <label className="form-control-label">Username</label>
                            <input type="username" name='username' className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label">Password</label>
                            <div className="pass-group">
                                <input type={passwordVisible ? 'text' : 'password'} name="password"
                                    className="form-control pass-input"
                                />
                                <span className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} toggle-password`}
                                    onClick={togglePasswordVisibility}
                                ></span>
                            </div>
                        </div>
                        
                        <button className="btn btn-lg btn-block btn-primary w-100 nlbtn" type="submit">Login</button>
                        <button className="btn btn-lg btn-block btn-danger w-100 lbtn" style={{display:'none'}} disabled type="button">Loading...</button>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>



            {/* Start OTP Modal */}
            <div className="modal custom-modal fade mymodal" id="otp_modal" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className="form-group">
                        <label className="form-control-label">Enter OTP</label>
                        <input type="text" id='otpp' name='otp' className="form-control"/>
                    </div>
                    <div className="modal-btn delete-action">
                        <div className="row">
                        <div className="col-6">
                            <span className="btn btn-primary paid-continue-btn nlbtn" onClick={()=>Login(Phone)}>Continue</span>
                            <span className="btn btn-primary paid-continue-btn lbtn" style={{display:'none'}} disabled>Processing</span>
                        </div>
                        <div className="col-6">
                            <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn" onClick={()=>CancelLogin()}>Cancel</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* End OTP Modal */}

        </div>
        </div>
    )
}

export default Login