import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';

const Header = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleSettingsManagement();
  }, []);

  const handleSettingsManagement =() =>{
    /* -------------------------------- GET USER ------------------------------- */
    $.ajax({
        type: 'GET',
        url: uRL+'admin',
        dataType: 'json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          // console.log('Single User Object:', response);
          setLoading(false);
          if(response.user){
            let user = response.user;
            $('#role').html(user.role === "0" ? "Admin" : user.role === "1" ? "Sub Admin" : user.role === "2" ? "Tech Admin" : "Random Admin");
            $('#username').html(user.firstname+" "+user.lastname);
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
          
        }
    });
    /* -------------------------------- GET USER ------------------------------- */
  }


  function clearCookies() {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  const logout =()=>{
    $.ajax({
      type: 'POST',
      url: uRL+'admin/logout',
      data: {},
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        // toast.warning("Logging you out!!!");
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        localStorage.clear();
        clearCookies();
        setTimeout(()=>{
          // navigate('/login');
          window.location.replace('/login');
        },2000)
      },
      error: function (xhr, textStatus, errorThrown) {
        if (xhr.responseJSON) {
          if(Array.isArray(xhr.responseJSON.message)){
            toast.error(xhr.responseJSON.message[0]);
            return;
          }
          toast.error(xhr.responseJSON.message);
          // console.error('Error message:', xhr.responseJSON.message);
          // console.error('Error status:', xhr.responseJSON.status);
        }
        localStorage.clear();
        clearCookies();
        setTimeout(()=>{
          // navigate('/login');
          window.location.replace('/login');
        },2000)
      }
    });
    localStorage.clear();
    clearCookies();
    window.location.replace('/login');
  }





  return (
    <div className="header header-one">
        <span id="toggle_btn" role='button'>
          <span className="toggle-bars">
            <span className="bar-icons"></span>
            <span className="bar-icons"></span>
            <span className="bar-icons"></span>
            <span className="bar-icons"></span>
          </span>
        </span>
        {/* <div className="top-nav-search">
          <form>
            <input type="text" className="form-control" placeholder="Search here" />
            <button className="btn" type="submit">
              <img src="assets/img/icons/search.svg" alt="Gicon" />
            </button>
          </form>
        </div> */}
        <span className="mobile_btn" id="mobile_btn">
          <i className="fas fa-bars"></i>
        </span>

        <ul className="nav nav-tabs user-menu">
          <li className="nav-item dropdown">
            <a className="user-link nav-link show" data-bs-toggle="dropdown" aria-expanded="true">
              <span className="user-img">
                <img src="../assets/img/favicon.jpg" alt="img" className="profilesidebar" />
                <span className="animate-circle"></span>
              </span>
              <span className="user-content">
                <span className="user-details" id='role'>
                  {/* <!--Admin--> */}
                </span>
                <span className="user-name" id='username'> </span>
              </span>
            </a>
            <div className="dropdown-menu menu-drop-user" style={{position: 'absolute',inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 62px)'}} data-popper-placement="bottom-end">
              <div className="profilemenu">
                <div className="subscription-menu">
                  <ul>
                    {/* <li>
                      <Link className="dropdown-item" to="/profile">Profile</Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/settings">Settings</Link>
                    </li>
                  </ul>
                </div>
                <div className="subscription-logout">
                  <ul>
                    <li className="pb-0">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#logout_modal">Log Out</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>

        {/* Stare Logout Modal */}
        <div className="modal custom-modal fade mymodal" id="logout_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Logout</h3>
                  <p>Are you sure want to logout?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn" onClick={logout}>Logout</span>
                    </div>
                    <div className="col-6">
                      <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Approve Modal */}

    </div>


  )
}

export default Header