import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { CSVLink } from 'react-csv';
import { Loader, decryptData, encryptData, formatDate, formatNumberWithCommas, getCurrentPayFormattedMondayDate, planDurationMap, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';

const InvestmentsMonthly = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const navigate = useNavigate();

  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedInvestments, setFetchedInvestments] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState([]);
  const [fetchedSearchPagination, setFetchedSearchPagination] = useState([]);

  const [fetchedWholeInvestments, setFetchedWholeInvestments] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [splitedvalue, setSplitedValue] = useState(0);
  const [monthReadyValue, setMonthReadyValue] = useState(0);

  // Pay Modal
  const [investmentIDToPay, setInvestmentIDToPay] = useState(null);

  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */
  const getPlanNameFromPlanID = (planID) => {
    let plan = fetchedPlans.find(plan => plan.id === parseInt(planID));
    return plan ? plan.name : 'Unknown Plan';
  };
  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */

  // Get Previous Payment Monday Date
  const { mondayFormattedDate, mondayFormattedMonthYear } = getCurrentPayFormattedMondayDate();
  // console.log('Monday Formatted Date:', mondayFormattedDate);
  // console.log('Monday Formatted Month and Year:', mondayFormattedMonthYear);

  const handleInvestmentsManagement =(paginationRoute, fetchedplan_data) =>{

    /* -------------------------------------------------------------------------- */
    /*                             FETCH ALL INVESTMENTS                          */
    /* -------------------------------------------------------------------------- */
    $.ajax({
        type: 'GET',
        url: `
            ${!paginationRoute ? uRL+'admin/investments/fetchinvestments?search='+searchValue+'&perpage=10&type=2&status=1' : 
            paginationRoute+'&search='+searchValue+'&perpage=10&type=2&status=1'}
        `,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          // console.log('All Investments:', response);
          setLoading(false);
          setFetchedInvestments(response.data);
          setFetchedPagination(response.links);

          generateCsvData(response.data, fetchedplan_data); // Call generateCsvData after fetching data
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
            
        }
    });
    /* -------------------------------------------------------------------------- */
    /*                             FETCH ALL INVESTMENTS                          */
    /* -------------------------------------------------------------------------- */
    


    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/plans',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Plans:', response);
        setLoading(false);
        setFetchedPlans(response.data);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */



    /* ------------------------- Total Payable Amount Ajax Request ------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/getmonthlypayoutamount',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        // console.log('Monthly Payable Amount:', response);
        setLoading(false);
        $('#totalmonthlypayableamount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------- Total Payable Amount Ajax Request ------------------------- */

    /* ------------------------- Total Payed Amount ------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/getmonthlypayoutamount?date='+mondayFormattedDate,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $('#totalpayoutAmount').html('---');
      },
      success: function(response) {
        console.log('Monthly Payout Amount:', response);
        setLoading(false);
        $('#totalpayoutAmount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------- Total Payed Amount ------------------------- */

    /* ------------------------- Total Remaining Payed-out Amount ------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/getremainingpayoutamountmonthly?date='+mondayFormattedDate,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $('#totalremaining_payoutamount').html('---');
      },
      success: function(response) {
        //console.log('Weekly Payout Amount:', response);
        setLoading(false);
        $('#totalremaining_payoutamount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------- Total Remaining Payed-out Amount ------------------------- */

  

    $(document).on('ready',function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  
  }


  const fetchInvestmentPlans =()=>{
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/plans',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Plans:', response);
        setLoading(false);
        setFetchedPlans(response.data);
        handleInvestmentsManagement("", response.data);
        fetchTheWholeInvestments(response.data);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
  }


  const fetchTheWholeInvestments =(allplans)=>{
    $.ajax({
      type: 'GET',
      url: `
          ${uRL+'admin/investments/fetchexportableinvestments?perpage=100000000000000000000000000&type=2&status=1'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        // console.log('The Whole Investments:', response);
        setLoading(false);
        setFetchedWholeInvestments(response.data);

        // console.log(response.data);

        generateWholeCsvData(response.data, allplans); // Call generateCsvData after fetching data
     
      },
      error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
      },
      complete: function() {
          
      }
    });
  }


  useEffect(() => {
    fetchInvestmentPlans();
    // handleInvestmentsManagement();    
    // console.log("csvData: ",csvData);
    getMonthReadyStatus();
    fetchTheWholeInvestments();
  }, []);


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log('Search value:', event.target.value);
    searchInvestment(event.target.value);
  };

  const searchInvestment =(searchword)=> {
    $.ajax({
      type: 'GET',
      url: `
        ${!searchword ? uRL+'admin/investments/fetchinvestments?perpage=10&type=2&status=1' : 
        uRL+'admin/investments/fetchinvestments?search='+searchword+'&perpage=10&type=2&status=1'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        console.log('Investment Search:', response);
        setLoading(false);
        setFetchedInvestments(response.data);
        setFetchedSearchPagination(response.links);
        if(!searchword){
            handleInvestmentsManagement();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
  }

  const storeInvestorIDandCode =(investorID, investorCode)=> {
    // alert(investorID);
    Cookies.set('investorid', encryptData(investorID, 'investorid'), { expires: 5 });
    Cookies.set('investorcode', encryptData(investorCode, 'investorcode'), { expires: 5 });
  }

  /* ----------------------------- PAY INVESTMENT ----------------------------- */
  const SendOTP =(investmentCode)=>{
    if(investmentCode){
      setInvestmentIDToPay(investmentCode);
        // alert(investmentCode);
        $.ajax({
            type: 'POST',
            url: uRL+'admin/investments/sendotp',
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
            },
            error: function (xhr, textStatus, errorThrown) {
                $('#pay_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                }
            }
        });
    }
  }
  const SendOTP2 =()=>{
    $.ajax({
        type: 'POST',
        url: uRL+'admin/investments/sendotp',
        dataType:'json',
        beforeSend: function(xhr){
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response){
            // console.log(response);
            toast.success(response.message);
        },
        error: function (xhr, textStatus, errorThrown) {
            $('#pay_modal').removeClass('show');
            if (xhr.responseJSON) {
                if(Array.isArray(xhr.responseJSON.message)){
                    toast.error(xhr.responseJSON.message[0]);
                    return;
                }
                toast.error(xhr.responseJSON.message);
            }
        }
    });
  }

  const payModal = document.getElementById('pay_modal');
  const paySingleInvestment =(investmentCode)=>{
    var OTP = $('#otp1').val();
    if(OTP==""){ toast.error("OTP not entered"); return; }
    if(investmentCode){
        // alert(investmentCode);
        $.ajax({
            type: 'POST',
            url: uRL+'admin/investments/payinvestment',
            data: {
                otp: OTP,
                investmentid: investmentCode
            },
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                $('#pay_modal').removeClass('show').hide();
                $(".nlbtn").show(); $(".lbtn").hide();
                handleInvestmentsManagement();
                setLoading(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                //$('#pay_modal').removeClass('show');
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  }
  /* ----------------------------- PAY INVESTMENT ----------------------------- */

  /* -------------------------- GET READY FOR PAYMENT ------------------------- */
  const getReadyForPayment =()=>{
    $.ajax({
      type: 'POST',
      url: uRL+'admin/investments/getreadymonth',
      data: {},
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
        // $('#batchPaymentBtn').removeClass('d-inline-block').addClass('d-none');
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        $('#getreadyforpayment_modal').removeClass('show').hide();
        $(".nlbtn").show(); $(".lbtn").hide();
        $('#groupBtns').addClass('d-inline-block').removeClass('d-none');
        handleInvestmentsManagement();
        setLoading(false);
        getsplitedAmount();
        getMonthReadyStatus();
      },
      error: function (xhr, textStatus, errorThrown) {
        // $('#getreadyforpayment_modal').removeClass('show');
        getsplitedAmount();
        getMonthReadyStatus();
        if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
                toast.error(xhr.responseJSON.message[0]);
                return;
            }
            toast.error(xhr.responseJSON.message);
            // console.error('Error message:', xhr.responseJSON.message);
            // console.error('Error status:', xhr.responseJSON.status);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      }
    });
  }

  const resetInvestible =()=>{
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/resetinvestible?type=2',
      data: {},
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
        //$('#batchPaymentBtn').removeClass('d-inline-block').addClass('d-none');
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        window.location.reload();
      },
      error: function (xhr, textStatus, errorThrown) {
        if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
                toast.error(xhr.responseJSON.message[0]);
                return;
            }
            toast.error(xhr.responseJSON.message);
            // console.error('Error message:', xhr.responseJSON.message);
            // console.error('Error status:', xhr.responseJSON.status);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      }
    });
  }

  const splitInvestments =()=>{
    var splitamount = $('#splitamount').val();
    var stopamount = $('#stopamount').val();
    if(splitamount==""){ toast.error("Split Amount not entered"); return; }
    if(stopamount==""){ toast.error("Stop Amount not entered"); return; }
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/freezebiginvestments?type=2&splitamount='+splitamount+'&stopamount='+stopamount,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        toast.success(response.message);
        $('#splitconfirm_modal').removeClass('show').hide();
        $(".nlbtn").show(); $(".lbtn").hide();
        setLoading(false);
        //$('#totalpayableamount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
        getsplitedAmount();
      },
      error: function(xhr, textStatus, errorThrown) {
        getsplitedAmount();
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
  }

  const getsplitedAmount =()=>{
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investments/getsplitamount?type=2',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Weekly Payable Amount:', response);
        setLoading(false);
        //$('#totalpayableamount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
        setSplitedValue(response.amount);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
  }

  const getMonthReadyStatus =()=>{
    $.ajax({
      type: 'GET',
      url: uRL+'admin/auxcolumn',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        //console.log('Weekly Payable Amount:', response);
        setLoading(false);
        //$('#totalpayableamount').html(response.amount ? "₦"+formatNumberWithCommas(response.amount) : "Null");
        setMonthReadyValue(response.auxcolumns.ready_month_payment);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
  }

  /* -------------------------- GET READY FOR PAYMENT ------------------------- */

  /* -------------------------- PAY MONTH INVESTMENTS -------------------------- */
  const payMonthInvestment =()=>{
    var OTP = $('#otp2').val();
    if(OTP==""){ toast.error("OTP not entered"); return; }
    $.ajax({
      type: 'POST',
      url: uRL+'admin/investments/paymonthlyinvestment',
      data: {
        otp: OTP
      },
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        $('#batchmonthlypay_modal').removeClass('show').hide();
        $(".nlbtn").show(); $(".lbtn").hide();
        $('#batchPaymentBtn').removeClass('d-inline-block').addClass('d-none');
        window.location.reload();
        handleInvestmentsManagement();
        setLoading(false);
      },
      error: function (xhr, textStatus, errorThrown) {
        //$('#batchmonthlypay_modal').removeClass('show');
        if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
              toast.error(xhr.responseJSON.message[0]);
              return;
            }
            toast.error(xhr.responseJSON.message);
            // console.error('Error message:', xhr.responseJSON.message);
            // console.error('Error status:', xhr.responseJSON.status);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      }
    });
  }

  const payMonthInvestment2 =()=>{
    var OTP = $('#otp3').val();
    if(OTP==""){ toast.error("OTP not entered"); return; }
    $.ajax({
      type: 'POST',
      url: uRL+'admin/investments/paymonthlyfrozeninvestment',
      data: {
        otp: OTP
      },
      dataType:'json',
      beforeSend: function(xhr){
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
      },
      success: function(response){
        // console.log(response);
        toast.success(response.message);
        $('#batchmonthlypay2_modal').removeClass('show').hide();
        $(".nlbtn").show(); $(".lbtn").hide();
        $('#batchPaymentBtn2').removeClass('d-inline-block').addClass('d-none');
        window.location.reload();
        handleInvestmentsManagement();
        setLoading(false);
      },
      error: function (xhr, textStatus, errorThrown) {
        //$('#batchmonthlypay_modal').removeClass('show');
        if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
              toast.error(xhr.responseJSON.message[0]);
              return;
            }
            toast.error(xhr.responseJSON.message);
            // console.error('Error message:', xhr.responseJSON.message);
            // console.error('Error status:', xhr.responseJSON.status);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      }
    });
  }
  /* -------------------------- PAY MONTH INVESTMENTS -------------------------- */


  /* ------------------------------- CSV REPORT ------------------------------- */
  const [csvData, setCsvData] = useState([]);
  const [csvWholeData, setWholeCsvData] = useState([]);
  // const headers = [
  //   { label:  }
  // ];

  const generateCsvData = (investmentData, fetchedplan_data) => {
    //console.log("investmentData ", investmentData);
    //console.log("fetchedplan_data ", fetchedplan_data);

    const data = investmentData.map((investment) => {
      // Find the plan corresponding to the investment's planid
      const plan = !fetchedplan_data ? "" : fetchedplan_data.find((plan) => plan.id === parseInt(investment.planid));

      // Check if the plan is found
      const planName = plan ? plan.name : "Plan Not Found";

      return {
        Investor: `${investment.investment_owner.firstname} ${investment.investment_owner.lastname} ${investment.investment_owner.othername}`,
        "Investor Code": `${investment.investment_owner.codenumber}`,
        // Add other fields as needed
        // Example:
        // "Account Details": `${investment.bank.name} ${investment.accountnumber}`,
        "Investment ID": investment.investmentid,
        Plan: !fetchedplan_data ? "" : planName,
        Period: planDurationMap[investment.period],
        "Agreement Date": investment.agreementdate,
        "Amount Paid": investment.amountpaid ? "₦" + formatNumberWithCommas(investment.amountpaid) : "",
        "AmountToBeReturned": investment.amount_to_be_returned ? "₦" + formatNumberWithCommas(investment.amount_to_be_returned) : "",
        Return: investment.return ? "₦" + formatNumberWithCommas(investment.return) : "",
        AmountPaidSoFar: investment.amountpaidsofar ? "₦" + formatNumberWithCommas(investment.amountpaidsofar) : "",
        LastPaymentDate: investment.lastpaymentdate ? investment.lastpaymentdate : "",
        "Start Date": investment.startdate,
        "Stop Date": investment.stopdate,
        Status:
          investment.status === "0" ? "Not Ready for Payment" : 
          investment.status === "1" ? "On Payment" : 
          investment.status === "2" ? "Expired" : 
          "Undefined Status",
        // Add more fields based on your requirements
      };
    });

    // Set CSV data to state
    // console.log("generateCsvData: ", data);
    setCsvData(data);
  };

  // const csvReport = {
  //   filename: "Investible_monthly_investment.csv",
  //   headers: Headers,
  //   data: data
  // }

  const generateWholeCsvData = (investmentData, fetchedplan_data) => {
    //console.log("investmentData ", investmentData);
    //console.log("fetchedplan_data ", fetchedplan_data);

    const data = investmentData.map((investment) => {
      // Find the plan corresponding to the investment's planid
      const plan = !fetchedplan_data ? "" : fetchedplan_data.find((plan) => plan.id === parseInt(investment.planid));

      // Check if the plan is found
      const planName = plan ? plan.name : "Plan Not Found";

      return {
        Investor: `${investment.investment_owner.firstname} ${investment.investment_owner.lastname} ${investment.investment_owner.othername}`,
        "Investor Code": `${investment.investment_owner.codenumber}`,
        // Add other fields as needed
        // Example:
        // "Account Details": `${investment.bank.name} ${investment.accountnumber}`,
        "Investment ID": investment.investmentid,
        Plan: !fetchedplan_data ? "" : planName,
        Period: planDurationMap[investment.period],
        "Agreement Date": investment.agreementdate,
        "Amount Paid": investment.amountpaid ? "₦" + formatNumberWithCommas(investment.amountpaid) : "",
        "AmountToBeReturned": investment.amount_to_be_returned ? "₦" + formatNumberWithCommas(investment.amount_to_be_returned) : "",
        Return: investment.return ? "₦" + formatNumberWithCommas(investment.return) : "",
        AmountPaidSoFar: investment.amountpaidsofar ? "₦" + formatNumberWithCommas(investment.amountpaidsofar) : "",
        LastPaymentDate: investment.lastpaymentdate ? investment.lastpaymentdate : "",
        "Start Date": investment.startdate,
        "Stop Date": investment.stopdate,
        Status:
          investment.status === "0" ? "Not Ready for Payment" : 
          investment.status === "1" ? "On Payment" : 
          investment.status === "2" ? "Expired" : 
          "Undefined Status",
        // Add more fields based on your requirements
      };
    });

    // Set CSV data to state
    // console.log("generateWholeCsvData: ", data);
    setWholeCsvData(data);
  };
  /* ------------------------------- CSV REPORT ------------------------------- */



  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
            
          {/*  --------------------------- Investor Investment Table --------------------------  */}
          <div className="d-flex justify-content-between">
              <div className="content-page-header">
                <h5>Monthly Investments</h5>
              </div>
              <div className="content-page-header">
                <h6>
                  Total Payable Amount: <span id='totalmonthlypayableamount'></span> &nbsp;
                  <span className="btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#getreadyforpayment_modal">
                    Get Ready for Payment
                  </span> &nbsp;

                  <div className="d-inline-block" id="groupBtns">
                    {monthReadyValue=="1" ?
                      <div>
                        <span className="btn btn-sm btn-success mx-1" id="batchPaymentBtn" data-bs-toggle="modal" data-bs-target="#batchmonthlypay_modal" onClick={()=>SendOTP2()}>
                        Batch Payment
                        </span>
                        <span className="btn btn-sm btn-info mx-1" id="batchPaymentBtn2" data-bs-toggle="modal" data-bs-target="#batchmonthlypay2_modal" onClick={()=>SendOTP2()}>
                        Batch FW Payment
                        </span>
                        <span className="btn btn-sm btn-outline-danger mx-1" data-bs-toggle="modal" data-bs-target="#resetinvestible_modal">
                        Reset Investible
                        </span>
                      </div>
                      :
                      <div></div>
                    }
                  </div>

                </h6>
              </div>
          </div>

          {/* -------------------------- GET DIFFERENT AMOUNT -------------------------- */}
          <div className="card customer-details-group">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Total Payed-out Amount for {mondayFormattedMonthYear}</label>
                            <span id='totalpayoutAmount' className='d-block text-primary fw-bold'></span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Remaining Payed-out Amount for {mondayFormattedMonthYear}</label>
                            <span id='totalremaining_payoutamount' className='d-block text-primary fw-bold'></span>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          {/* -------------------------- GET DIFFERENT AMOUNT -------------------------- */}
    
          {/* <!-- search bar --> */}
          <div className="search-bar d-flex justify-content-end mb-2">
            <form className="form-inline">
              <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" 
              value={searchValue}
              onInput={handleSearchChange}/>
            </form>

            &nbsp;&nbsp;
            {/* <CSVLink data={csvData} filename="monthly_investments_batch.csv">
              <button className="btn btn-dark"><i className='fa fa-download'></i> CSV</button>
            </CSVLink>
            &nbsp;&nbsp;
            <CSVLink data={csvWholeData} filename="monthly_investments_all.csv">
              <button className="btn btn-primary"><i className='fa fa-download'></i> CSV(All)</button>
            </CSVLink> */}
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-stripped table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Investor</th>
                            <th>Account Details</th>
                            <th>ID</th>
                            <th>Plan</th>
                            <th>Period</th>
                            <th>Agreement<br/>Date</th>
                            <th>Amount<br/>Paid</th>
                            <th>Amount to<br/>be Returned</th>
                            <th>Return Per <br/>Period</th>
                            <th>Amount Paid<br/>So Far</th>
                            <th>Last Payment<br/>Date</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedInvestments.map((investment,index) => (
                            <tr key={index}>
                              <td>
                                {investment.investment_owner.firstname} {investment.investment_owner.lastname}&nbsp;
                                {investment.investment_owner.othername}<br/><small>{investment.investment_owner.codenumber}</small>
                              </td>
                              <td>
                                {investment.bank.name}<br/>{investment.accountnumber}
                              </td>
                              <td>
                                <b>{investment.investmentid}</b><br/>
                                <span className='d-flex'>
                                  {!investment.pop ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.pop} target="_blank">
                                            <img src="assets/img/receipt.png" width={30} alt="Receipt"
                                              data-toggle="tooltip"
                                              title="Proof of Payment"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }

                                  {!investment.agreementdoc ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.agreementdoc} target="_blank">
                                            <img src="assets/img/document.png" width={30} alt="Agreement doc" 
                                              data-toggle="tooltip"
                                              title="Agreement Document"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }
                                </span>

                              </td>
                              <td>{getPlanNameFromPlanID(investment.planid)}</td>
                              <td>{planDurationMap[investment.period]}</td>
                              <td>{investment.agreementdate}</td>
                              <td>{investment.amountpaid ? "₦"+formatNumberWithCommas(investment.amountpaid) : ""}</td>
                              <td>{investment.amount_to_be_returned ? "₦"+formatNumberWithCommas(investment.amount_to_be_returned) : ""}</td>
                              <td>{investment.return ? "₦"+formatNumberWithCommas(investment.return) : ""}</td>
                              <td>{investment.amountpaidsofar ? "₦"+formatNumberWithCommas(investment.amountpaidsofar) : ""}</td>
                              <td>{investment.lastpaymentdate ? investment.lastpaymentdate : ""}</td>
                              <td>{investment.startdate}</td>
                              <td>{investment.stopdate}</td>
                              <td>
                                {
                                  investment.status === "0" ? <span className="badge bg-warning-light text-warning">Not Ready for Payment</span> :
                                  investment.status === "1" ? <span className="badge bg-success-light">On Payment</span> :
                                  investment.status === "2" ? <span className="badge bg-danger-light text-danger">Expired</span> :
                                  <span className="badge bg-light-gray text-secondary">Undefined Status</span>
                                }
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <span role='button' className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end customer-dropdown">
                                    <ul>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/edit-investment?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-edit me-2"></i>Edit 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/edit-investment-startstopdate-month?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-calendar me-2"></i>Start/Stop Date 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/investment-details?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-eye me-2"></i>View 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#pay_modal" onClick={()=>SendOTP(investment.investmentid)}>
                                          <i className="far fa-paper-plane me-2"></i>Pay 
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestmentsManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))}

                        {/* {fetchedSearchPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestmentsManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))} */}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  --------------------------- Investor Investment Table --------------------------  */}








          <div className="modal custom-modal fade" id="delete_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Customer Details</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn">Delete</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="modal modal-backdrop fade" id="pay_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Payment Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <h6 className="my-2 text-center">Are you sure you want to pay this investor?</h6>
                  <div className="form-group">
                      <label className="form-control-label">Enter OTP</label>
                      <input type="text" id='otp1' className="form-control"/>
                  </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>paySingleInvestment(investmentIDToPay)}>Pay</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>

          {/* PAY MONTHLY INVESTMENT IN BATCH */}
          <div className="modal modal-backdrop fade" id="batchmonthlypay_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Payment Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                  <h6 className="my-2 text-center">Are you sure you want to run a batch payment for monthly investment?</h6>
                  <div className="form-group">
                      <label className="form-control-label">Enter OTP</label>
                      <input type="text" id='otp2' className="form-control"/>
                  </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>payMonthInvestment()}>I'm Sure. Proceed to payment</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>
          {/* PAY MONTHLY INVESTMENT IN BATCH */}

          {/* PAY MONTHLY INVESTMENT IN BATCH 2 */}
          <div className="modal modal-backdrop fade" id="batchmonthlypay2_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Payment Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                  <h6 className="my-2 text-center">Are you sure you want to run the batch 2 payment for monthly investment?</h6>
                  <div className="form-group">
                      <label className="form-control-label">Enter OTP</label>
                      <input type="text" id='otp3' className="form-control"/>
                  </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>payMonthInvestment2()}>I'm Sure. Proceed to payment</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>
          {/* PAY MONTHLY INVESTMENT IN BATCH */}

          {/* SPLIT INVESTMENTS */}
          <div className="modal modal-backdrop fade" id="splitconfirm_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Action Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                  <h6 className="my-2 text-center">Are you sure you want to split monthly investment?</h6>
                  <div className="form-group">
                      <label className="form-control-label">Enter Split Amount</label>
                      <input type="text" id='splitamount' className="form-control"/>
                  </div>
                  <div className="form-group">
                      <label className="form-control-label">Enter Stop Amount</label>
                      <input type="text" id='stopamount' className="form-control"/>
                  </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>splitInvestments()}>Split</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>
          {/* SPLIT INVESTMENT */}

          {/* GET READY FOR PAYMENT */}
          <div className="modal modal-backdrop fade" id="getreadyforpayment_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Action Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                Do you wish to proceed?
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>getReadyForPayment()}>Yes</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>
          {/* GET READY FOR PAYMENT */}

          {/* RESET INVESTIBLE */}
          <div className="modal modal-backdrop fade" id="resetinvestible_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Action Confirmation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                Do you wish to proceed?
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>resetInvestible()}>Yes</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                </div>
                </div>
            </div>
          </div>
          {/* RESET INVESTIBLE */}


        </div>
    </div>
  )
}

export default InvestmentsMonthly