import React from 'react'

const MembershipPlans = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header ">
              <h5>Membership Plans</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <span className="btn btn-primary">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Plan </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="price-table-main">
            <div className="plan-selected">
              <h4>Monthly</h4>
              <div className="status-toggle me-2 ms-2">
                <input id="rating_1" className="px-4 check" type="checkbox" checked />
                <label for="rating_1" className="px-4 checktoggle checkbox-bg">checkbox</label>
              </div>
              <h4>Annually</h4>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="plan-header">
                      <span className="plan-widget-icon">
                        <img src="assets/img/icons/plan-price-01.svg" alt="Gicon" />
                      </span>
                      <div className="plan-title">
                        <h6>For individuals</h6>
                        <h4 className="plan-name">Basic</h4>
                      </div>
                    </div>
                    <div className="description-content">
                      <p>Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                    </div>
                    <div className="price-dollar">
                      <h1 className="d-flex align-items-center">$99 <span className="ms-1">/monthly</span>
                      </h1>
                    </div>
                    <div className="plan-description">
                      <h6>What’s included</h6>
                      <ul>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> All analytics features
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Up to 250,000 tracked visits
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Normal support
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Up to 3 team members
                        </li>
                      </ul>
                    </div>
                    <div className="plan-button">
                      <span className="btn btn-primary d-flex align-items-center justify-content-center">Get Started <span className="ms-2">
                          <i className="fe fe-arrow-right"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card price-selected">
                  <div className="card-body plan-header-selected">
                    <div className="d-flex">
                      <div className="plan-header">
                        <span className="plan-widget-icon">
                          <img src="assets/img/icons/plan-price-02.svg" alt="Gicon" />
                        </span>
                        <div className="plan-title">
                          <h6 className="text-white">For Startups</h6>
                          <h4 className="plan-name text-white">Pro</h4>
                        </div>
                      </div>
                      <span className="price-popular">Popular</span>
                    </div>
                    <div className="description-content">
                      <p>Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                    </div>
                    <div className="price-dollar">
                      <h1 className="d-flex align-items-center text-white">$199 <span className="text-white ms-1">/monthly</span>
                      </h1>
                    </div>
                    <div className="plan-description">
                      <h6 className="text-white">What’s included</h6>
                      <ul>
                        <li>
                          <span className="rounded-circle bg-white me-2">
                            <i className="text-primary fe fe-check"></i>
                          </span> All analytics features
                        </li>
                        <li>
                          <span className="rounded-circle bg-white me-2">
                            <i className="text-primary fe fe-check"></i>
                          </span> Up to 1,000,000 tracked visits
                        </li>
                        <li>
                          <span className="rounded-circle bg-white me-2">
                            <i className="text-primary fe fe-check"></i>
                          </span> Premium support
                        </li>
                        <li>
                          <span className="rounded-circle bg-white me-2">
                            <i className="text-primary fe fe-check"></i>
                          </span> Up to 10 team members
                        </li>
                      </ul>
                    </div>
                    <div className="plan-button">
                      <span className="btn btn-white d-flex align-items-center justify-content-center">Get Started <span className="ms-2">
                          <i className="fe fe-arrow-right"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="plan-header">
                      <span className="plan-widget-icon">
                        <img src="assets/img/icons/plan-price-03.svg" alt="Gicon" />
                      </span>
                      <div className="plan-title">
                        <h6>For individuals</h6>
                        <h4 className="plan-name">Basic</h4>
                      </div>
                    </div>
                    <div className="description-content">
                      <p>Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                    </div>
                    <div className="price-dollar">
                      <h1 className="d-flex align-items-center">$399 <span className="ms-1">/monthly</span>
                      </h1>
                    </div>
                    <div className="plan-description">
                      <h6>What’s included</h6>
                      <ul>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> All analytics features
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Up to 5,000,000 tracked visits
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Dedicated support
                        </li>
                        <li>
                          <span className="rounded-circle me-2">
                            <i className="fe fe-check"></i>
                          </span> Up to 50 team members
                        </li>
                      </ul>
                    </div>
                    <div className="plan-button">
                      <span className="btn btn-primary d-flex align-items-center justify-content-center">Get Started <span className="ms-2">
                          <i className="fe fe-arrow-right"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MembershipPlans