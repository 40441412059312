import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, decryptData } from '../../utilities/reusablefunctions_variables';

const SingleInvestmentPlan = () => {

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    const navigate = useNavigate();

    const [fetchedPlan, setFetchedPlan] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        category: fetchedPlan.category,
        name: fetchedPlan.name,
        amount: fetchedPlan.amount,
        percentage: fetchedPlan.percentage,
        duration: fetchedPlan.duration,
        no_of: fetchedPlan.no_of,
        returns: fetchedPlan.returns,
        description: fetchedPlan.description
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    useEffect(() => {
        handlePlanManagement();
    }, []);

    const handlePlanManagement =() =>{
        const params = new URLSearchParams(window.location.search);
        const encryptedPlanId = params.get('plan');
        // console.log(encryptedPlanId)

        if(!encryptedPlanId){
            navigate('/investment-plans');
            return;
        }
        // Decrypt the user ID
        const decryptedPlanId = decryptData(encryptedPlanId, 'investible123')
        // console.log('Decrypted User ID:', decryptedUserId);

        /* -------------------------------- GET PLAN ------------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/plans/'+decryptedPlanId,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Single Plan:', response);
              setLoading(false);
              setFetchedPlan(response.plan);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------- GET PLAN ------------------------------- */

        /* -------------------------------------------------------------------------- */
        /*                            EDIT INVESTMENT PLAN                            */
        /* -------------------------------------------------------------------------- */
        $('#editInvestmentPlan').off('submit');  // Unbind previous event handlers

        $('#editInvestmentPlan').on('submit',function(e){
            e.preventDefault();

            var plan = $(this).serialize();
            if(plan){
                $.ajax({
                    type: 'PUT',
                    url: uRL+'admin/plans/'+decryptedPlanId,
                    data: plan,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        navigate('/investment-plans');
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                            EDIT INVESTMENT PLAN                            */
        /* -------------------------------------------------------------------------- */

        
    }

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
            <div className="page-header">
                <div className="content-page-header">
                <h5>Investment Plan</h5>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    <form id='editInvestmentPlan'>
                        <div className="card-body">
                            {loading && <Loader />}
                            {!loading && (
                                <div className="form-group-customer customer-additional-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Select Category</label>
                                                <select defaultValue={fetchedPlan.category} className="form-control" name="category" 
                                                required onChange={handleInputChange}>
                                                    <option value="">Select Category</option>
                                                    <option value="1">Bike Investment</option>
                                                    <option value="2">Vehicle Investment</option>
                                                    <option value="3">Bond Yield</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Plan Name</label>
                                                <input defaultValue={fetchedPlan.name} type="text" className="form-control" placeholder="Enter Plan Name Eg. Standard Bike, Cruiser Bike, Mini Bus etc"
                                                name="name" required onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Amount</label>
                                                <input defaultValue={fetchedPlan.amount} type="number" className="form-control" placeholder="Enter Amount, Eg. 500000"
                                                name="amount" required onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Percentage Profit (%)</label>
                                                <input defaultValue={fetchedPlan.percentage} type="number" max={1000} min={1} className="form-control percentageNumber" placeholder='%' name='percentage' />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Payment Duration Type</label>
                                                <select defaultValue={fetchedPlan.duration} className="form-control" name="duration" required onChange={handleInputChange}>
                                                    <option value="">Select Duration Type</option>
                                                    <option value="1">Weekly</option>
                                                    <option value="2">Monthly</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>No of Payment Duration</label>
                                                <input defaultValue={fetchedPlan.no_of} type="number" className="form-control" placeholder="Enter No of Duration Eg. 50 weeks, 10 months etc."
                                                name="no_of" required onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Returns Amount (Based on duration types Eg. Weekly) </label>
                                                <input defaultValue={fetchedPlan.returns} type="number" className="form-control" placeholder="Enter Returns, Eg. 16000"
                                                name="returns" required onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <label>Description <span className='text-muted'>(Optional)</span></label>
                                                <textarea defaultValue={fetchedPlan.description} type="text" className="form-control" placeholder="Enter Description (Optional)"
                                                name="description" onChange={handleInputChange}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                        <button type="submit" className="btn btn-primary">Update Plan</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    
                </div>
            </div>
            </div>
        </div>
    )
}

export default SingleInvestmentPlan