import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader, decryptData, encryptData, formatDate, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';


const InvestorNoks = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        $(function() {
            handleInvestorNokManagement();
        });
    }, []);

    const [fetchedNoks, setFetchedNoks] = useState([]);
    const [fetchedPagination, setFetchedPagination] = useState(null);
    const [loading, setLoading] = useState(true);

    const params = new URLSearchParams(window.location.search);
    const encryptedInvestorId = params.get('investorcodenumber');
    // console.log(encryptedInvestorId)
    // Decrypt the user ID
    const decryptedInvestorId = decryptData(encryptedInvestorId, 'investorcode')

    const handleInvestorNokManagement =(paginationRoute) =>{

        if(!encryptedInvestorId){
            navigate('/investors');
            return;
        }

        /* ------------------------------ Ajax Request ------------------------------ */
        $.ajax({
          type: 'GET',
          url: `${!paginationRoute ? uRL+'admin/investor/nextofkins?investor='+decryptedInvestorId : paginationRoute}`,
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function(response) {
            console.log('Investor Nok:', response);
            setLoading(false);
            setFetchedNoks(response.data);
            setFetchedPagination(response.links);
          },
          error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
            // Handle the error as needed
          },
          complete: function() {
            
          }
        });
        /* ------------------------------ Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                               ADD NEXT OF KIN                              */
        /* -------------------------------------------------------------------------- */
        $('#addInvestorNok').off('submit');  // Unbind previous event handlers

        $('#addInvestorNok').on('submit',function(e){
            e.preventDefault();

            var user = $(this).serialize();
            // Append "investor" field
            var updatedSerializedData = `${user}&investor=${decryptedInvestorId}`;
            console.log(updatedSerializedData)
            if(user){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/investor/nextofkins',
                    data: updatedSerializedData,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        handleInvestorNokManagement();
                        $('#addInvestorNok').trigger('reset');
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                               ADD NEXT OF KIN                              */
        /* -------------------------------------------------------------------------- */
    }


    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {loading && <Loader />}
                {!loading && (
                    <div className="page-header">
                        <div className="content-page-header d-flex">
                            <h5>Next of Kin</h5>
                            <h6>Investor's Code Number: {decryptedInvestorId}</h6>
                        </div>
                    </div>
                )}


                <div className="row">
                    <div className="col-md-12">
                        {/* ------------------------------ Nok Details ------------------------------ */}
                        <form id='addInvestorNok'>
                            <div className="card-body">
                                <div className="form-group-customer customer-additional-form">
                                    <div className="row">
                                        <h5 className="form-title">Nok's Details</h5>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" placeholder="Enter First Name"
                                            name="firstname" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Last Name"
                                            name="lastname" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                            <label>Other Names</label>
                                            <input type="text" className="form-control" placeholder="Enter Other Names"
                                            name="othername" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Relationship</label>
                                                <input type="text" className="form-control" placeholder="Enter Relationship"
                                                name="relationship" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <input type="number" className="form-control" placeholder="Enter Phone Number"
                                                name="phonenumber" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input type="text" className="form-control" placeholder="Enter Address"
                                                name="address" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                        <button type="submit" className="btn btn-primary">Add Nok</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* ------------------------------ Nok Details ------------------------------ */}


                        {/* -------------------------------- Noks List ------------------------------- */}
                        {loading && <Loader />}
                        {!loading && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-center table-hover datatable">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Relationship</th>
                                                            <th>Phone Number</th>
                                                            <th>Address</th>
                                                            <th>Created</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fetchedNoks.map((nok,index) => (
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{nok.firstname+" "+nok.lastname+" "+nok.othername}</td>
                                                                <td>{nok.relationship}</td>
                                                                <td>{nok.phone}</td>
                                                                <td>{nok.address}</td>
                                                                <td>{formatDate(nok.created_at)}</td>
                                                                <td className="d-flex align-items-center">
                                                                    <div className="dropdown dropdown-action">
                                                                        <Link to="/user-profile" className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </Link>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link className="dropdown-item" to={`/edit-nok?nok=${encryptData(nok.id, 'nok')}`}>
                                                                                    <i className="far fa-edit me-2"></i>Edit 
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                                <ul className="pagination">
                                                    {fetchedPagination.map((data, index) => (
                                                    <li key={index} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                                                    ${data.active ? 'active':'disabled'}`} 
                                                    id="DataTables_Table_0_previous">
                                                        <Link aria-controls="DataTables_Table_0" className="page-link" onClick={()=>handleInvestorNokManagement(data.url)}>
                                                        {replaceQuotationMarks(data.label).trim()}
                                                        </Link>
                                                    </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* -------------------------------- Noks List ------------------------------- */}

                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorNoks