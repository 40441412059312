import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader, encryptData, formatDate, formatNumberWithCommas, planDurationMap, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';


const PaymentsHistory = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  useEffect(() => {
    $(function() {
      handlePaymentHistoryManagement();
    });
  }, []);

  const [fetchedPaymentsHistory, setFetchedPaymentsHistory] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState(null);

  const [loading, setLoading] = useState(true);

  
  const handlePaymentHistoryManagement =(paginationRoute) =>{
    /* -------------------------------------------------------------------------- */
    /*                            FETCH PAYMENT HISTORY                           */
    /* -------------------------------------------------------------------------- */
    setLoading(true);
    $.ajax({
        type: 'GET',
        url: `
            ${!paginationRoute ?uRL+'admin/payments/fetchhistories?perpage=10' : 
            paginationRoute+'&perpage=10'}
        `,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
            console.log('All Payment History:', response);
            setLoading(false);
            setFetchedPaymentsHistory(response.data);
            setFetchedPagination(response.links);
        },
        error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
            // Handle the error as needed
        },
        complete: function() {
            
        }
    });
    /* -------------------------------------------------------------------------- */
    /*                            FETCH PAYMENT HISTORY                           */
    /* -------------------------------------------------------------------------- */
  }


  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Payments History</h5>
              {/* <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <span className="btn btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download">
                        <span>
                            <i className="fe fe-download"></i>
                        </span>
                    </span>
                  </li>
                  <li>
                    <span className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </span>
                  </li>
                  <li>
                    <Link className="btn btn-primary" to="/add-user">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add User 
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>


          {loading && <Loader />}
          {!loading && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-center table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Tranfer Code</th>
                            <th>Investor</th>
                            <th>Investment</th>
                            <th>Amount </th>
                            <th>Payment<br/>Date</th>
                            <th>Status </th>
                            <th>Bank </th>
                            <th>Naration </th>
                          </tr>
                        </thead>
                        <tbody>

                          {fetchedPaymentsHistory.map((pay,index) => (
                            <tr key={index}>
                              <td>{pay.transfercode}</td>
                              <td>
                                {pay.investment_owner.firstname+" "+pay.investment_owner.lastname+" "+pay.investment_owner.othername}<br/>
                                {pay.investment_owner.codenumber}
                              </td>
                              <td>
                                {pay.investmentid}<br/>
                                ₦{formatNumberWithCommas(pay.investment.return)} {planDurationMap[pay.investment.period]}
                              </td>
                              <td>{pay.amount ? formatNumberWithCommas(pay.amount) : ""}</td>
                              <td>{pay.pdate}</td>
                              <td>
                                {
                                  pay.status
                                }
                              </td>
                              <td>{pay.bank.name}<br/>{pay.accountnumber}</td>
                              <td>{pay.narration}</td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} onClick={()=>handlePaymentHistoryManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>


    </div>
  )
}

export default PaymentsHistory