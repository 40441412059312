import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader, encryptData, formatDate, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';

const Investors = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  useEffect(() => {
    $(function() {
      handleInvestorsManagement();
    });
  }, []);

  const [fetchedInvestors, setFetchedInvestors] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  // Delete Modal
  // const [investorIdToDelete, setInvestorIdToDelete] = useState(null);
  
  const handleInvestorsManagement =(paginationRoute) =>{
    /* ------------------------------ Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: `${!paginationRoute ? uRL+'admin/investors?search='+searchValue+'&perpage=10' : 
      paginationRoute+'&search='+searchValue+'&perpage=10'}`,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        // console.log('Investor:', response);
        setLoading(false);
        setFetchedInvestors(response.data);
        setFetchedPagination(response.links);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Ajax Request ------------------------------ */
  }


  const saveInvestorID_Code =(investorid, investorcode)=>{
    // alert(investorid);
    Cookies.set('investorid', investorid, { expires: 5 });
    Cookies.set('investorcode', investorcode, { expires: 5 });
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log('Search value:', event.target.value);
    searchInvestor(event.target.value);
  };

  const searchInvestor =(searchword)=> {
    $.ajax({
      type: 'GET',
      url: `${!searchword ? uRL+'admin/investors?perpage=10' : 
        uRL+'admin/investors?search='+searchword+'&sortorder=desc&sortby=id&perpage=10'}`,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        console.log('Investor:', response);
        setLoading(false);
        setFetchedInvestors(response.data);
        setFetchedPagination(response.links);
        if(!searchword){
          handleInvestorsManagement();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
  }

  /* ------------------------------- DELETE INVESTOR ------------------------------ */
  const deleteModal = document.getElementById('delete_modal');
  const deleteInvestor =(investorID)=>{
    $.ajax({
      type: 'DELETE',
      url: uRL+'admin/delete/'+investorID,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
      },
      success: function(response) {
        // console.log('Response:', response);
        deleteModal.style.display="none";
        toast.success(response.message);
        navigate('/investors');
        handleInvestorsManagement();
        setLoading(false);
      },
      error: function(xhr, textStatus, errorThrown) {
        deleteModal.style.display="none";
        if (xhr.responseJSON) {
          if(Array.isArray(xhr.responseJSON.message)){
            toast.error(xhr.responseJSON.message[0]);
            return;
          }
          toast.error(xhr.responseJSON.message);
          setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      },
      complete: function() {
        
      }
    });
  }
  /* ------------------------------- DELETE INVESTOR ------------------------------ */


  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Investors</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  {/* <li>
                    <span className="btn btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Download">
                      <span>
                        <i className="fe fe-download"></i>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </span>
                  </li> */}
                  <li>
                    <Link className="btn btn-primary" to="/add-investor">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Investor 
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <!-- search bar --> */}
          <div className="search-bar d-flex justify-content-end mb-2">
            <form className="form-inline">
              <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" 
              value={searchValue}
              onInput={handleSearchChange}/>
            </form>
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-center table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Code No</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedInvestors.map((investor,index) => (
                            <tr key={index}>
                              <td>
                                <h2 className="table-avatar">
                                  <Link onClick={()=>saveInvestorID_Code(encryptData(investor.id,'investorid'), encryptData(investor.codenumber,'investorcode'))}
                                  to={`/investor-details?investor=${encryptData(investor.id, 'investorid')}&investorcode=${encryptData(investor.codenumber, 'investorcode')}`} 
                                  className="avatar avatar-md me-2">
                                    <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-14.jpg" alt="User Gimage" />
                                  </Link>

                                  <Link onClick={()=>saveInvestorID_Code(encryptData(investor.id,'investorid'), encryptData(investor.codenumber,'investorcode'))}
                                  to={`/investor-details?investor=${encryptData(investor.id, 'investorid')}&investorcode=${encryptData(investor.codenumber, 'investorcode')}`}>
                                    {investor.firstname+" "+investor.lastname+" "+investor.othername} 
                                    <span>
                                      <span>{investor.email}</span>
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>{investor.codenumber}</td>
                              <td>{investor.phonenumber}</td>
                              <td>{investor.address}</td>
                              <td>{formatDate(investor.created_at)}</td>
                              <td>
                                {
                                  investor.status === "1" ? <span className="badge badge-pill bg-danger-light">Inactive</span>
                                  : investor.status === "0" ? <span className="badge badge-pill bg-success-light">Active</span>
                                  : <span className="badge badge-pill bg-secondary-light">{investor.status}</span>
                                }
                              </td>
                              <td className="d-flex align-items-center">
                                {/* <Link to={`/add-investment?investor=${encryptData(investor.id, 'investorid')}&investorcodenumber=${encryptData(investor.codenumber, 'investorcode')}`} className="btn btn-greys me-2">
                                  <i className="fa fa-plus-circle me-1"></i>Investment
                                </Link> */}
                                <div className="dropdown dropdown-action" role='button'>
                                  <span className=" btn-action-icon px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <ul>
                                      <li>
                                        <Link className="dropdown-item" to={`/edit-investor?investor=${encryptData(investor.id, 'investorid')}`}>
                                          <i className="far fa-edit me-2"></i>Edit 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" 
                                        to={`/investor-details?investor=${encryptData(investor.id, 'investorid')}&investorcode=${encryptData(investor.codenumber, 'investorcode')}`}>
                                          <i className="far fa-eye me-2"></i>View 
                                        </Link>
                                      </li>
                                      {/* <li>
                                        <span className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={() => setInvestorIdToDelete(investor.id)}>
                                          <i className="far fa-trash-alt me-2"></i>Delete </span>
                                      </li> */}
                                      <li>
                                        <Link className="dropdown-item">
                                          <i className="fa-solid fa-power-off me-2"></i>Activate 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item">
                                          <i className="far fa-bell-slash me-2"></i>Deactivate 
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestorsManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          )}
        </div>





        {/* <div className="modal modal-backdrop fade" id="delete_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                  Are you sure you want to delete this item?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>deleteInvestor(investorIdToDelete)}>Delete</button>
                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
              </div>
              </div>
          </div>
        </div> */}


    </div>
  )
}

export default Investors