import React, { useEffect, useState } from 'react'
// import { handleUserManagement } from '../../customjs/user';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, decryptData } from '../../utilities/reusablefunctions_variables';


const EditUser = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');


    const navigate = useNavigate();

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prevVisible => !prevVisible);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(prevVisible => !prevVisible);
    };

    const [fetchedUser, setFetchedUser] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const [formData, setFormData] = useState({
        firstname: fetchedUser.firstname,
        lastname: fetchedUser.lastname,
        phone: fetchedUser.phone,
        email: fetchedUser.email,
        username: fetchedUser.username
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    useEffect(() => {
        handleUserManagement();
    }, []);

    const params = new URLSearchParams(window.location.search);
    const encryptedUserId = params.get('userID');
    // console.log(encryptedUserId)
    // Decrypt the user ID
    const decryptedUserId = decryptData(encryptedUserId, 'investible123')
    // console.log('Decrypted User ID:', decryptedUserId);

    const handleUserManagement =() =>{
        
        if(!encryptedUserId){
            navigate('/users');
            return;
        }

        /* -------------------------------------------------------------------------- */
        /*                               GET SINGLE USER                              */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/get-admin/'+decryptedUserId,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              setLoading(true);
            },
            success: function(response) {
              console.log('Single User:', response);
              setLoading(false);
              setFetchedUser(response.admin);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                               GET SINGLE USER                              */
        /* -------------------------------------------------------------------------- */
    }

    /* -------------------------------------------------------------------------- */
    /*                              EDIT SINGLE USER                              */
    /* -------------------------------------------------------------------------- */
    const handleEditUserSubmit = (e) => {
        e.preventDefault();

        const password = $('input[name="password"]').val();
        const confirmPassword = $('input[name="confirmPassword"]').val();
        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        const user = {};
        user.firstname = e.target.firstname.value;
        user.lastname = e.target.lastname.value;
        user.username = e.target.username.value;
        user.email = e.target.email.value;
        user.phone = e.target.phone.value;
        user.password = e.target.password.value;

        console.log(user)
        if(user){
            $.ajax({
                type: 'POST',
                url: uRL+'admin/edit/'+decryptedUserId,
                data: user,
                dataType:'json',
                beforeSend: function(xhr){
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    $(".lbtn").show(); $(".nlbtn").hide();
                },
                success: function(response){
                    // console.log(response);
                    toast.success(response.message);
                    navigate('/users');
                },
                error: function (xhr, textStatus, errorThrown) {
                    if (xhr.responseJSON) {
                        if(Array.isArray(xhr.responseJSON.message)){
                            toast.error(xhr.responseJSON.message[0]);
                            return;
                        }
                        toast.error(xhr.responseJSON.message);
                        // console.error('Error message:', xhr.responseJSON.message);
                        // console.error('Error status:', xhr.responseJSON.status);
                        setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                    }
                }
            });
        }
    };
    /* -------------------------------------------------------------------------- */
    /*                              EDIT SINGLE USER                              */
    /* -------------------------------------------------------------------------- */



    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="content-page-header">
                <h5>Edit User</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card-body">
                            {loading && <Loader />}
                            {!loading && (
                                <form onSubmit={handleEditUserSubmit}>
                                    <div className="form-group-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input defaultValue={fetchedUser.firstname} type="text" className="form-control" placeholder="Enter First Name"
                                                    name="firstname" required onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input defaultValue={fetchedUser.lastname} type="text" className="form-control" placeholder="Enter Last Name"
                                                    name="lastname" required onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>User Name</label>
                                                    <input defaultValue={fetchedUser.username} type="text" className="form-control" placeholder="Enter User Name"
                                                    name="username" required onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input defaultValue={fetchedUser.email} type="email" className="form-control" placeholder="Enter Email"
                                                    name="email" required onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Phone</label>
                                                    <input defaultValue={fetchedUser.phone} type="number" className="form-control" placeholder="Enter Phone Number"
                                                    name="phone" required onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Role</label>
                                                    <select defaultValue={fetchedUser.category} className="select form-control" 
                                                    name="role"  onChange={handleInputChange}>
                                                        <option value="">Select Role</option>
                                                        <option value="1">Sub-admin</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="pass-group" id="password1">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <div className="pass-group">
                                                            <input type={passwordVisible ? 'text' : 'password'} className="form-control pass-input" 
                                                            name="password" required />
                                                            <span className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                                                onClick={togglePasswordVisibility} style={{top: '25%'}}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="pass-group" id="passwordInput2">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <div className="pass-group">
                                                            <input type={passwordVisible2 ? 'text' : 'password'} className="form-control pass-input"
                                                            name="confirmPassword" required />
                                                            <span className={`fas ${passwordVisible2 ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                                                onClick={togglePasswordVisibility2} style={{top: '25%'}}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                        <button type="submit" className="btn btn-primary nlbtn">Edit User</button>
                                        <button type="button" className="btn btn-danger lbtn" disabled style={{display:'none'}}>Loading...</button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUser