import React from 'react'
import Login from './pages/Auth/Login'
import { Navigate, Route, Routes } from 'react-router-dom'

const AuthRoutes = () => {
  return (
    
    <Routes>
        <Route path="/" element={ <Login/> } />

        <Route path="/login" element={ <Login/> } />

        {/* Redirect for unknown routes */}
        {/* <Route path="*" element={ <Login/> } /> */}
        <Route path="*" element={<Navigate to="/login" />} />
    </Routes>

  )
}

export default AuthRoutes