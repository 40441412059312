import React, { useEffect, useState } from 'react'
// import { handleUserManagement } from '../../customjs/user';
import $ from 'jquery';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();
    
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prevVisible => !prevVisible);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(prevVisible => !prevVisible);
    };

    useEffect(() => {
        handleUserManagement();
    });

    const handleUserManagement =() =>{
        $('#addUser').off('submit');  // Unbind previous event handlers

        $('#addUser').on('submit',function(e){
            e.preventDefault();

            const password = $('input[name="password"]').val();
            const confirmPassword = $('input[name="confirmPassword"]').val();
            if (password !== confirmPassword) {
                toast.error('Passwords do not match');
                return;
            }

            var user = $(this).serialize();
            if(user){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/create',
                    data: user,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        navigate('/users');
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
    }



    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="content-page-header">
                <h5>Add User</h5>
                </div>
                <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <form id='addUser'>
                            <div className="form-group-item">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" placeholder="Enter First Name"
                                            name="firstname" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Last Name"
                                            name="lastname" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>User Name</label>
                                            <input type="text" className="form-control" placeholder="Enter User Name"
                                            name="username" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" placeholder="Enter Email"
                                            name="email" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="number" className="form-control" placeholder="Enter Phone Number"
                                            name="phone" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Role</label>
                                            <select className="select form-control" 
                                            name="role" required>
                                                <option value="">Select Role</option>
                                                <option value="1">Sub-admin</option>
                                                <option value="2">Tech-admin</option>
                                                <option value="3">Random-admin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="pass-group" id="passwordInput1">
                                            <div className="form-group">
                                                <label>Password</label>
                                                <div className="pass-group">
                                                    <input type={passwordVisible ? 'text' : 'password'} className="form-control pass-input" 
                                                    name="password" required />
                                                    <span className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                                        onClick={togglePasswordVisibility} style={{top: '25%'}}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="pass-group" id="passwordInput2">
                                            <div className="form-group">
                                                <label>Confirm Password</label>
                                                <div className="pass-group">
                                                    <input type={passwordVisible2 ? 'text' : 'password'} className="form-control pass-input"
                                                    name="confirmPassword" required />
                                                    <span className={`fas ${passwordVisible2 ? 'fa-eye-slash' : 'fa-eye'} toggle-password text-muted`}
                                                        onClick={togglePasswordVisibility2} style={{top: '25%'}}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                <button type="submit" className="btn btn-primary nlbtn">Add User</button>
                                <button type="button" className="btn btn-danger lbtn" disabled style={{display:'none'}}>Loading...</button>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser