import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';

const Sidebar = () => {

  useEffect(() => {
    handleSideMenuManagement();
    handleSettingsManagement();
  }, []);

  const handleSideMenuManagement =() =>{
    $('#sidebar-menu a').off('click');  // Unbind previous event handlers

    $('#sidebar-menu a').on('click', function (e) {
      if ($(this).parent().hasClass('submenu')) { 
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    })
    $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

  }

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const [showManageUser, setShowManageUser] = useState(false);
  const [showUserSubMenu, setShowUserSubMenu] = useState(false);

  const handleSettingsManagement =() =>{
    /* -------------------------------- GET USER ------------------------------- */
    $.ajax({
        type: 'GET',
        url: uRL+'admin',
        dataType: 'json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          // console.log('Single User Object:', response);
          // setLoading(false);
          if(response.user){
            let user = response.user;
            if(user.role === "0"){
              setShowManageUser(true);
            }
            if(user.role === "1"){
              setShowManageUser(false);
            }
            // $('#role').html(user.role === "0" ? "Admin" : user.role === "1" ? "Sub Admin" : "Random Admin");
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
          
        }
    });
    /* -------------------------------- GET USER ------------------------------- */
  }


  return (
    <div className="sidebar" id="sidebar">
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <Link to="/dashboard" style={{fontSize:20,color:'#28084B',fontWeight:'bold'}}>
              {/* <img src="assets/img/logo.png" className="img-fluid logo" alt="true" /> */}
              <span className="img-fluid logo" alt="true">Investible</span>
            </Link>
            <Link to="/dashboard">
              {/* <img src="assets/img/logo-small.png" className="img-fluid logo-small" alt="true" /> */}
              <img src="../assets/img/favicon.jpg" className="img-fluid logo-small" alt="true" />
              {/* Gavice Investment */}
            </Link>
          </div>
        </div>
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">
                <span>Main</span>
              </li>
              <li className="submenu">
                <Link>
                  <i className="fe fe-home"></i>
                  <span> Dashboard</span>
                  <span className="menu-arrow"></span>
                </Link>
                <ul>
                  <li>
                    <Link className="active" to="/dashboard">Admin Overview</Link>
                  </li>
                </ul>
              </li>
            </ul>
            
            <ul>
              <li className="menu-title">
                <span>Investors</span>
              </li>
              <li>
                <Link to="/add-investor">
                  <i className="fe fe-user-plus"></i>
                  <span>Add Investor</span>
                </Link>
              </li>
              <li>
                <Link to="/investors">
                  <i className="fe fe-users"></i>
                  <span>Investors</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/investor-details">
                  <i className="fe fe-file"></i>
                  <span>Investor Details</span>
                </Link>
              </li> */}
            </ul>

            <ul>
              <li className="menu-title">
                <span>Investment Plans</span>
              </li>
              <li className="submenu">
                <Link>
                  <i className="fe fe-book"></i>
                  <span> Plans</span>
                  <span className="menu-arrow"></span>
                </Link>
                <ul>
                  <li>
                    <Link to="/add-investmentplan">Add Investment Plan</Link>
                  </li>
                  <li>
                    <Link to="/investment-plans">Investment Plans</Link>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li className="menu-title">
                <span>Investments</span>
              </li>
              <li className="submenu">
                <Link>
                  <i className="fe fe-book"></i>
                  <span>Investments</span>
                  <span className="menu-arrow"></span>
                </Link>
                <ul>
                  <li>
                    <Link to="/investments">All Investments</Link>
                  </li>
                  <li>
                    <Link to="/investments-weekly">Weekly Investments</Link>
                  </li>
                  <li>
                    <Link to="/investments-monthly">Monthly Investments</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/paymenthistory">
                  <i className="fa fa-money-check"></i>
                  <span>Payment History</span>
                </Link>
              </li>
              
            </ul>
            
            {showManageUser && (
            <ul>
              <li className="menu-title">
                <span>User Management</span>
              </li>
              <li className="submenu">
                <Link onClick={() => setShowUserSubMenu(!showUserSubMenu)}>
                  <i className="fe fe-user"></i>
                  <span> Manage Users</span>
                  <span className="menu-arrow"></span>
                </Link>
                <ul style={{ display: showUserSubMenu ? 'block' : 'none' }}>
                  <li>
                    <Link to="/add-user">Add User</Link>
                  </li>
                  <li>
                    <Link to="/users">Users</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/action-logs">
                  <i className="fas fa-tasks"></i>
                  <span>Action Logs</span>
                </Link>
              </li>
            </ul>
          )}

          </div>
        </div>
    </div>
  )
}

export default Sidebar