import React from 'react'
import { Link } from 'react-router-dom'

const DeactiveCustomer = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Customers</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <div className="dropdown dropdown-action" data-bs-toggle="tooltip" data-bs-placement="top" title="Download">
                      <Link className="btn btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>
                          <i className="fe fe-download"></i>
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <Link className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="btn btn-primary" to="/addcustomer">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Customer 
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="filter_inputs" className="card filter-card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Balance </th>
                          <th>Total Invoice </th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-14.jpg" alt="User Gimage" />
                              </Link>
                              <Link>John Smith <span>
                                  <span className="__cf_email__" data-cfemail="452f2a2d2b05203d24283529206b262a28">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 989-438-3131</td>
                          <td>$4,220</td>
                          <td>2</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-15.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Johnny Charles <span>
                                  <span className="__cf_email__" data-cfemail="0c6663646262754c69746d617c6069226f6361">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 843-443-3282</td>
                          <td>$1,862</td>
                          <td>1</td>
                          <td>15 Dec 2022, 06:12 PM</td>
                          <td>
                            <span className="badge badge-pill bg-danger-light">Deactive</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-16.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Robert George <span>
                                  <span className="__cf_email__" data-cfemail="5c2e333e392e281c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 917-409-0861</td>
                          <td>$2,789</td>
                          <td>3</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-danger-light">Deactive</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-17.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Sharonda Letha <span>
                                  <span className="__cf_email__" data-cfemail="57243f3625383917322f363a273b327934383a">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 956-623-2880</td>
                          <td>$7,923</td>
                          <td>6</td>
                          <td>14 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-18.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Pricilla Maureen <span>
                                  <span className="__cf_email__" data-cfemail="88f8fae1ebe1e4e4e9c8edf0e9e5f8e4eda6ebe7e5">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 956-613-2880</td>
                          <td>$2,763</td>
                          <td>4</td>
                          <td>12 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-danger-light">Deactive</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-19.jpg" alt="User Gimage" />
                              </Link>
                              <Link>Randall Hollis <span>
                                  <span className="__cf_email__" data-cfemail="5c2e3d32383d30301c39243d312c3039723f3331">[email&#160;protected]</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 117-409-0861</td>
                          <td>$4,642</td>
                          <td>1</td>
                          <td>04 Dec 2022, 12:38 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i> Invoice </Link>
                            <div className="dropdown dropdown-action">
                              <Link className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/active-customers">
                                      <i className="fa-solid fa-power-off me-2"></i>Activate 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/deactive-customers">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="toggle-sidebar">
        <div className="sidebar-layout-filter">
          <div className="sidebar-header">
            <h5>Filter</h5>
            <Link className="sidebar-closes">
              <i className="fa-regular fa-circle-xmark"></i>
            </Link>
          </div>
          <div className="sidebar-body">
            <form action="customers.html#" autoComplete="off">
              <div className="accordion" id="accordionMain1">
                <div className="card-header-new" id="headingOne">
                  <h6 className="filter-title">
                    <Link className="w-100" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Customer <span className="float-end">
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </Link>
                  </h6>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                  <div className="card-body-chat">
                    <div className="row">
                      <div className="col-md-12">
                        <div id="checkBoxes1">
                          <div className="form-custom">
                            <input type="text" className="form-control" id="member_search1" placeholder="Search here" />
                            <span>
                              <img src="assets/img/icons/search.svg" alt="img" />
                            </span>
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Russell Copeland </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Greg Lynch </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> John Blair </label>
                            <div className="view-content">
                              <div className="viewall-One">
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Barbara Moore </label>
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Hendry Evan </label>
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Richard Miles </label>
                              </div>
                              <div className="view-all">
                                <Link className="viewall-button-One">
                                  <span className="me-2">View All</span>
                                  <span>
                                    <i className="fa fa-circle-chevron-down"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion border-0" id="accordionMain3">
                <div className="card-header-new" id="headingThree">
                  <h6 className="filter-title">
                    <Link className="w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"> By Status <span className="float-end">
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </Link>
                  </h6>
                </div>
                <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                  <div className="card-body-chat">
                    <div id="checkBoxes2">
                      <div className="selectBox-cont">
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> All Status </label>
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> Activate </label>
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> Deactivate </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-buttons">
                <button type="submit" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"> Apply </button>
                <button type="submit" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"> Reset </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeactiveCustomer