import './App.css';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import AppRoutes from './AppRoutes';
import { useEffect } from 'react';
import AuthRoutes from './AuthRoutes';
import Cookies from 'js-cookie';

function App() {
  
  const isUserLoggedIn = Cookies.get('userAuth');
  

  useEffect(() => {
    
  }, []);



  const headerComponent = isUserLoggedIn ? <Header /> : null;
  const sidebarComponent = isUserLoggedIn ? <Sidebar /> : null;
  const accessRoute = isUserLoggedIn ? <AppRoutes /> : <AuthRoutes />;

  return (
    <div className="main-wrapper">
      { headerComponent }
      { sidebarComponent }

      { accessRoute }
    </div>
  );
}

export default App;
