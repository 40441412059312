import React from 'react'

const RolesPermission = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header ">
              <h5>Roles & Permission</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <div className="short-filter">
                      <img className="me-2" src="assets/img/icons/sort.svg" alt="Sort by select" />
                      <div className="sort-by sort-by-ticket">
                        <select className="sort select">
                          <option>Sort by: Date</option>
                          <option>Sort by: Date 1</option>
                          <option>Sort by: Date 2</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                      <span className="me-2">
                        <img src="assets/img/icons/filter-icon.svg" alt="filter" />
                      </span>Filter </span>
                  </li>
                  <li>
                    <span className="btn-filters" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Grid-View">
                      <span>
                        <i className="fe fe-grid"></i>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="active btn-filters" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="List-View">
                      <span>
                        <i className="fe fe-list"></i>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_role">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Roles </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Role Name</th>
                          <th>Created at</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Admin</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Customer</td>
                          <td>28 Nov 2022, 03:25 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Shop Owner</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Receptionist</td>
                          <td>9 Dec 2022, 06:12 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Shop Owner</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Admin</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Customer</td>
                          <td>28 Nov 2022, 03:25 PM</td>
                          <td className="d-flex align-items-center">
                            <span className="btn btn-greys me-2" data-bs-toggle="modal" data-bs-target="#edit_role">
                              <i className="fa fa-edit me-1"></i> Edit Role </span>
                            <span className="btn btn-greys me-2">
                              <i className="fa fa-shield me-1"></i> Permissions </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default RolesPermission