import React from 'react'

const UserProfile = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
            <div className="row justify-content-lg-center">
                <div className="col-lg-10">

                    <div className="page-header">
                        <div className="content-page-header">
                        <h5>Profile</h5>
                        </div>
                    </div>

                    <div className="profile-cover">
                        <div className="profile-cover-wrap">
                            <img className="profile-cover-img" src="assets/img/img-2.jpg" alt="Profile Cover" id="cover-image" />
                        </div>
                    </div>

                    <div className="text-center mb-5">
                        <label className="avatar avatar-xxl profile-cover-avatar" for="avatar_upload">
                            <img className="avatar-img" src="assets/img/logo-small.png" alt="Profile Gimage" id="blah" />
                            <input type="file" id="avatar_upload" />
                            <span className="avatar-edit">
                                <i className="fe fe-edit avatar-uploader-icon shadow-soft"></i>
                            </span>
                        </label>
                        <h2>Charles Hafner <i className="fas fa-certificate text-primary small" data-toggle="tooltip" data-placement="top" title data-original-title="Verified"></i>
                        </h2>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <i className="far fa-building"></i>
                                <span>Hafner Pvt Ltd.</span>
                            </li>
                            <li className="list-inline-item">
                                <i className="fas fa-map-marker-alt"></i> West Virginia, US
                            </li>
                            <li className="list-inline-item">
                                <i className="far fa-calendar-alt"></i>
                                <span>Joined November 2017</span>
                            </li>
                        </ul>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title d-flex justify-content-between">
                                        <span>Profile</span>
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-0">
                                            <h6>About</h6>
                                        </li>
                                        <li> Charles Hafner </li>
                                        <li> Hafner Pvt Ltd. </li>
                                        <li className="pt-2 pb-0">
                                            <h6>Contacts</h6>
                                        </li>
                                        <li>
                                            <span className="">example@email.com</span>
                                        </li>
                                        <li> +1 (304) 499-13-66 </li>
                                        <li className="pt-2 pb-0">
                                            <h6>Address</h6>
                                        </li>
                                        <li> 4663 Agriculture Lane, <br/> Miami, <br/> Florida - 33165, <br/> United States. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                <h5 className="card-title">Activity</h5>
                                </div>
                                <div className="card-body card-body-height">
                                <ul className="activity-feed">
                                    <li className="feed-item">
                                    <div className="feed-date">Nov 16</div>
                                    <span className="feed-text">
                                        <span>Brian Johnson</span> has paid the invoice <span>"#DF65485"</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Nov 7</div>
                                    <span className="feed-text">
                                        <span>Marie Canales</span> has accepted your estimate <span>#GTR458789</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Oct 24</div>
                                    <span className="feed-text">New expenses added <span>"#TR018756</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Oct 24</div>
                                    <span className="feed-text">New expenses added <span>"#TR018756</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Oct 24</div>
                                    <span className="feed-text">New expenses added <span>"#TR018756</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Oct 24</div>
                                    <span className="feed-text">New expenses added <span>"#TR018756</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Oct 24</div>
                                    <span className="feed-text">New expenses added <span>"#TR018756</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Jan 27</div>
                                    <span className="feed-text">
                                        <span>Robert Martin</span> gave a review for <span>"Dell Laptop"</span>
                                    </span>
                                    </li>
                                    <li className="feed-item">
                                    <div className="feed-date">Jan 14</div>
                                    <span className="feed-text">New customer registered <span>"Tori Carter"</span>
                                    </span>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default UserProfile