import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Loader, addEllipses, calculateToBePaid, formatNumberWithCommas, planCategoryMap, planDurationMap, planIconMap } from '../../utilities/reusablefunctions_variables';

const InvestmentPlans = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  useEffect(() => {
    $(function() {
      handlePlanManagement();
    });
  }, []);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
  
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12; // Handle midnight (0 hours)
  
    // Add leading zeros to minutes if needed
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${day} ${month} ${year}, ${hours}:${minutes} ${amOrPm}`;
  };


  const replaceQuotationMarks = (label) => {
    return label.replace(/&raquo;/g, '»').replace(/&laquo;/g, '«');
  };

  const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data.toString(), secretKey).toString();
  }

  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  const handlePlanManagement =(paginationRoute) =>{
    /* ------------------------------ Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: `${!paginationRoute ? uRL+'admin/plans' : paginationRoute}`,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        console.log('Users:', response);
        setLoading(false);
        setFetchedPlans(response.data);
        setFetchedPagination(response.links);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Ajax Request ------------------------------ */
  }


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log('Search value:', event.target.value);
    searchInvestment(event.target.value);
  };

  const searchInvestment =(searchword)=> {
    $.ajax({
      type: 'GET',
      url: `
        ${!searchword ? uRL+'admin/plans?perpage=10' : 
        uRL+'admin/plans?search='+searchword+'&perpage=10'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        console.log('Investment Plan Search:', response);
        setLoading(false);
        setFetchedPlans(response.data);
        setFetchedPagination(response.links);
        if(!searchword){
          handlePlanManagement();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
  }


  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header ">
              <h5>Investment Plans</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <Link className="btn btn-primary" to="/add-investmentplan">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Plan 
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          {/* <!-- search bar --> */}
          <div className="search-bar d-flex justify-content-end mb-2">
            <form className="form-inline">
              <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" 
              value={searchValue}
              onInput={handleSearchChange}/>
            </form>
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="price-table-main">
              <div className="row">

                {fetchedPlans.map((plan,index) => (
                  <div key={index} className="col-lg-4 col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="plan-header">
                          <span className="plan-widget-icon">
                            <img src={planIconMap[plan.category] || 'assets/img/icons/plan-price-01.svg'} alt="Gicon" />
                          </span>
                          <div className="plan-title">
                            <h6>For 
                              {planCategoryMap[plan.category] || 'Unknown Category'}
                            </h6>
                            <h4 className="plan-name">{plan.name}</h4>
                          </div>
                        </div>
                        <div className="price-dollar">
                          <h3 className="d-flex align-items-center">₦{formatNumberWithCommas(plan.amount)}</h3>
                        </div>
                        <div className="price-dollar d-flex">
                          <span className='me-2'>Percentage Profit </span>
                          <h4 className="d-flex align-items-center text-success">{formatNumberWithCommas(plan.percentage)}%</h4>
                        </div>
                        <div className="price-dollar d-flex">
                          <span className='me-2'>Return </span>
                          <h4 className="d-flex align-items-center text-success">
                            ₦{formatNumberWithCommas(plan.returns)}&nbsp;
                            {planDurationMap[plan.duration].toLowerCase()}
                          </h4>
                        </div>
                        <div className="price-dollar d-flex">
                          <span className='me-2'>Duration </span>
                          <h4 className="d-flex align-items-center text-success">
                            {formatNumberWithCommas(plan.no_of)}&nbsp;
                            {planDurationMap[plan.duration].toLowerCase().replace("ly","")}(s)
                          </h4>
                        </div>
                        <div className="price-dollar d-flex mt-2">
                            <span className='me-2'>To be Paid </span>
                            <h4 className="d-flex align-items-center">
                              {/* calculateToBePaid(amountInvested, returns, noOf, duration) */}
                              ₦{formatNumberWithCommas(calculateToBePaid(plan.returns, plan.no_of, planDurationMap[plan.duration]))}
                            </h4>
                        </div>
                        <div className="plan-description mt-2">
                          <h6>What’s included</h6>
                          <div className='d-flex'>
                            <ul>
                              <li>
                                  <span className="rounded-circle me-2">
                                      <i className="fe fe-check"></i>
                                  </span>
                              </li>
                            </ul>
                            <div id='investment_description'>
                                {addEllipses(plan.description,35)}
                            </div>
                          </div>
                        </div>
                        <div className="plan-button">
                          <Link to={`/single-investmentplan?plan=${encryptData(plan.id, 'investible123')}`} className="btn btn-primary d-flex align-items-center justify-content-center">
                              View <span className="ms-2">
                              <i className="fe fe-arrow-right"></i>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>

              <div className="dataTables_paginate paging_simple_numbers my-4" id="DataTables_Table_0_paginate">
                <ul className="pagination">
                  {fetchedPagination.map((data, index) => (
                    <li key={index} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                    ${data.active ? 'active':'disabled'}`} 
                    id="DataTables_Table_0_previous">
                      <Link aria-controls="DataTables_Table_0" className="page-link" onClick={()=>handlePlanManagement(data.url)}>
                        {replaceQuotationMarks(data.label).trim()}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

        </div>
    </div>
  )
}

export default InvestmentPlans