import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const ActionLogs = () => {

    useEffect(() => {
        handleActionLog();
    }, []);
    
    const handleActionLog =() =>{
        
    }

  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Action Logs</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Date</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/user-profile" className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-15.jpg" alt="User Gicon" />
                              </Link>
                              <Link to="/user-profile">John Smith <span>
                                <span className="">user@email.com</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td>Action Details</td>
                        </tr>

                        <tr>
                          <td>2</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/user-profile" className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-15.jpg" alt="User Gicon" />
                              </Link>
                              <Link to="/user-profile">John Smith <span>
                                <span className="">user@email.com</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td>Action Details</td>
                        </tr>

                        <tr>
                          <td>3</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/user-profile" className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-15.jpg" alt="User Gicon" />
                              </Link>
                              <Link to="/user-profile">John Smith <span>
                                <span className="">user@email.com</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td>Action Details</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal modal-backdrop fade" id="delete_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    Are you sure you want to delete this item?
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-danger ms-2">Delete</button>
                </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default ActionLogs