import React from 'react'
import { Link } from 'react-router-dom'

const ActiveCustomer = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Active Investors</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <Link className="btn btn btn-primary" aria-expanded="false">
                      <span>
                        <i className="fe fe-download"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="btn btn-primary" to="/add-investor">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Investor 
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="filter_inputs" className="card filter-card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Balance </th>
                          <th>Total Invoice </th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="investor-details" className="avatar avatar-md me-2">
                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-14.jpg" alt="User Gimage" />
                              </Link>
                              <Link to="investor-details">John Smith <span>
                                  <span className="">email@email.com</span>
                                </span>
                              </Link>
                            </h2>
                          </td>
                          <td>+1 989-438-3131</td>
                          <td>$4,220</td>
                          <td>2</td>
                          <td>19 Dec 2022, 06:12 PM</td>
                          <td>
                            <span className="badge badge-pill bg-success-light">Active</span>
                          </td>
                          <td className="d-flex align-items-center">
                            <Link to="/add-investment" className="btn btn-greys me-2">
                              <i className="fa fa-plus-circle me-1"></i>Investment
                            </Link>
                            <div className="dropdown dropdown-action">
                              <Link to="/customers" className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul>
                                  <li>
                                    <Link className="dropdown-item" to="editcustomer">
                                      <i className="far fa-edit me-2"></i>Edit 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal">
                                      <i className="far fa-trash-alt me-2"></i>Delete 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="/customerdetails">
                                      <i className="far fa-eye me-2"></i>View 
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item">
                                      <i className="far fa-bell-slash me-2"></i>Deactivate 
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ActiveCustomer