import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, calculatePercentagePaid, capitalizeEveryWord, decryptData, encryptData, formatDate, formatNumberWithCommas, planCategoryMap, planDurationMap, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';

const InvestmentDetails = () => {

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    const navigate = useNavigate();

    const [fetchedInvestment, setFetchedInvestment] = useState([]);

    const [fetchedPlan, setFetchedPlan] = useState([]);
    const [fetchedInvestor, setFetchedInvestor] = useState([]);
    const [fetchedBanks, setFetchedBanks] = useState([]);
    const [fetchedBankAccount, setFetchedBankAccount] = useState([]);
    const [fetchedPaymentHistory, setFetchedPaymentHistory] = useState([]);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        handleInvestmentManagement();
    }, []);


    const handleInvestmentManagement =() =>{
        const params = new URLSearchParams(window.location.search);
        const encryptedInvestmentId = params.get('investment');
        console.log(encryptedInvestmentId)
    
        if(!encryptedInvestmentId){
            navigate('/investors');
            return;
        }
        // Decrypt the user ID
        const decryptedInvestmentId = decryptData(encryptedInvestmentId, 'investmentid')
        // console.log('Decrypted Investment ID:', decryptedInvestmentId);
    
    
        /* -------------------------------- GET INVESTMENT ------------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investments/get-investment/'+decryptedInvestmentId,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Single Investment:', response);
              setLoading(false);
              setFetchedInvestment(response.investment);
              const planID = response.investment.planid;
              fetchPlanDetails(planID);  // Call function to fetch plan details
              // const investorCode = response.investment.investor;
              const investorID = decryptData(Cookies.get('investorid'), 'investorid');
              // console.log("investorID", investorID);
              fetchInvestorDetails(investorID);
              fetchInvestmentAccDetails(response.investment.account);
              fetchedPaymentsHistory(response.investment.investmentid);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------- GET INVESTMENT ------------------------------- */


        /* ------------------------- GET PLAN VIA INVESTMENT ------------------------ */
        const fetchPlanDetails = (planID) => {
            $.ajax({
                type: 'GET',
                url: uRL + 'admin/plans/'+planID,
                dataType: 'json',
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                success: function(response) {
                    console.log('Plan Details:', response);
                    setFetchedPlan(response.plan); // Assuming plan details are in response.plan
                },
                error: function(xhr, textStatus, errorThrown) {
                    console.error('Error fetching plan details:', textStatus);
                    console.error('Status:', xhr.status);
                    // Handle the error as needed
                },
                complete: function() {
                    // Handle completion if needed
                }
            });
        }
        /* ------------------------- GET PLAN VIA INVESTMENT ------------------------ */

        /* ------------------------- GET INVESTOR VIA INVESTMENT ------------------------ */
        const fetchInvestorDetails = (investorID) => {
            $.ajax({
                type: 'GET',
                url: uRL+'admin/investors/'+investorID,
                dataType: 'json',
                beforeSend: function(xhr) {
                  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                success: function(response) {
                  console.log('Single Investor:', response);
                  setLoading(false);
                  setFetchedInvestor(response.investor);
                },
                error: function(xhr, textStatus, errorThrown) {
                  console.error('Error:', textStatus);
                  console.error('Status:', xhr.status);
                  // Handle the error as needed
                },
                complete: function() {
                  
                }
            });
        }
        /* ------------------------- GET INVESTOR VIA INVESTMENT ------------------------ */

        /* ------------------- GET ACCOUNT DETAILS VIA INVESTMENT ------------------- */
        const fetchInvestmentAccDetails =(accountID)=>{
            $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/accounts/'+accountID,
            dataType: 'json',
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
                console.log('Single Bank Account:', response);
                setFetchedBankAccount(response.account);
            },
            error: function(xhr, textStatus, errorThrown) {
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                }
            },
            complete: function() {
                
            }
            });
        }
        /* ------------------- GET ACCOUNT DETAILS VIA INVESTMENT ------------------- */

        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'fetchbanks'}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Banks:', response);
              setLoading(false);
              setFetchedBanks(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
    }

    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA
    const getBankNameFromCode = (bankCode) => {
        const bank = fetchedBanks.find(bank => bank.bankcode === bankCode);
        console.log(bank)
        return bank ? bank.name : 'Unknown Bank';
    };
    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA

    const fetchedPaymentsHistory =(investmentcode)=>{
        /* -------------------------------------------------------------------------- */
        /*                            FETCH PAYMENT HISTORY                           */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/payments/fetchhistories?investment='+investmentcode,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              setLoading(true);
            },
            success: function(response) {
              console.log('Single Investment Payment History:', response);
              setLoading(false);
              setFetchedPaymentHistory(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                            FETCH PAYMENT HISTORY                           */
        /* -------------------------------------------------------------------------- */
    }


    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row justify-content-lg-center">
                    <div className="col-lg-12">

                        <div className="page-header">
                            <div className="content-page-header d-flex">
                                <h3>{planCategoryMap[fetchedPlan.id]} - {fetchedPlan.name} &nbsp;
                                    <i className="fas fa-certificate text-primary small" data-toggle="tooltip" data-placement="top" data-original-title="Verified"></i>
                                </h3>
                            </div>
                        </div>

                        {loading && <Loader />}
                        {!loading && (
                        <>
                            <div className="page-header">
                                <div className="content-page-header">
                                    <h6>Investment ID: {fetchedInvestment.investmentid}</h6>
                                    <h6>Investor's Code: {fetchedInvestment.investor}</h6>
                                </div>
                            </div>
                            <div className="text-center mb-5">
                                {/* <h2>Bike Investment <i className="fas fa-certificate text-primary small" data-toggle="tooltip" data-placement="top" data-original-title="Verified"></i>
                                </h2> */}
                                
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <i className="fas fa-user"></i>&nbsp;
                                        Investor Name:&nbsp;
                                        <span className='fw-bold'>
                                            {capitalizeEveryWord(fetchedInvestor.firstname+" "+fetchedInvestor.lastname+" "+fetchedInvestor.othername)}
                                        </span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="far fa-building"></i>&nbsp;
                                        Amount Paid: <span className='fw-bold'>
                                            {fetchedInvestment.amountpaid ? "₦"+formatNumberWithCommas(fetchedInvestment.amountpaid) : ""}
                                        </span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="far fa-building"></i>&nbsp;
                                        Amount to be Returned: <span className='fw-bold'>
                                            {fetchedInvestment.amount_to_be_returned ? "₦"+formatNumberWithCommas(fetchedInvestment.amount_to_be_returned) : ""}
                                        </span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="far fa-building"></i>&nbsp;
                                        Period: <span className='fw-bold'>{fetchedInvestment.timeduration+" "+planDurationMap[fetchedInvestment.period]}</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="far fa-calendar-alt"></i>&nbsp;
                                        Start Date: <span className='fw-bold'>{fetchedInvestment.startdate}</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <i className="far fa-calendar-alt"></i>&nbsp;
                                        Due Date: <span className='fw-bold'>{fetchedInvestment.stopdate}</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card card-body">
                                        <h5>Return Status</h5>
                                        <div className='d-flex align-item-center'>
                                            <div className='me-1'>Amount Returned so Far: </div>
                                            <h6 className=''>
                                            {fetchedInvestment.amountpaidsofar ? "₦"+formatNumberWithCommas(fetchedInvestment.amountpaidsofar) : ""}
                                            </h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="progress progress-md flex-grow-1">
                                                {/* <div className="progress-bar bg-primary" role="progressbar" style={{width: '30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div> */}
                                                <div className="progress-bar bg-primary" role="progressbar" 
                                                    style={{ width: `${calculatePercentagePaid(fetchedInvestment.amountpaidsofar, fetchedInvestment.amount_to_be_returned)}%` }} 
                                                    aria-valuenow={calculatePercentagePaid(fetchedInvestment.amountpaidsofar, fetchedInvestment.amount_to_be_returned)}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                </div>
                                            </div>
                                            <span className="ms-4">
                                                {calculatePercentagePaid(fetchedInvestment.amountpaidsofar, fetchedInvestment.amount_to_be_returned)}%
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title d-flex justify-content-between">
                                                <span>Profile</span>
                                            </h5>
                                        </div>
                                        <div className="card-body">
                                            <ul className="list-unstyled mb-0">
                                                {/* ---------------------------- Investor Profile ---------------------------- */}
                                                <li className="py-0">
                                                    <h6>Investor</h6>
                                                </li>
                                                <li> Name: {capitalizeEveryWord(fetchedInvestor.firstname+" "+fetchedInvestor.lastname+" "+fetchedInvestor.othername)} </li>
                                                <li> Email: {fetchedInvestor.email} </li>
                                                <li> Phone: {fetchedInvestor.phonenumber} </li>
                                                <li> Address: {fetchedInvestor.address} </li>
                                                <br/>
                                                {/* ---------------------------- Investor Profile ---------------------------- */}

                                                {/* ---------------------------- Bank Details---------------------------- */}
                                                <li className="py-0">
                                                    <h6>Bank Details</h6>
                                                </li>
                                                <li> Bank Name: {getBankNameFromCode(fetchedBankAccount.bankcode)} </li>
                                                <li> Account Number: {fetchedBankAccount.accountnumber} </li>
                                                <li> Account Name: {fetchedBankAccount.accountname} </li>
                                                <br/>
                                                {/* ---------------------------- Bank Details---------------------------- */}
                                                
                                                {/* ---------------------------- Next of Kin---------------------------- */}
                                                {/* <li className="py-0">
                                                    <h6>Next of Kin</h6>
                                                </li>
                                                <li> Charles Hafner </li>
                                                <li> Brother </li>
                                                <li> Phone number </li>
                                                <li> Address </li>
                                                <br/> */}
                                                {/* ---------------------------- Next of Kin---------------------------- */}
                                                
                                                {/* ---------------------------- WItness ---------------------------- */}
                                                <li className="py-0">
                                                    <h6>Witness</h6>
                                                </li>
                                                <li> {fetchedInvestment.witnessname} </li>
                                                <li> {fetchedInvestment.witnessphone} </li>
                                                <li> {fetchedInvestment.witnessaddress} </li>
                                                <br/>
                                                {/* ---------------------------- WItness ---------------------------- */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card">
                                        <div className="card-header">
                                        <h5 className="card-title">Payment History</h5>
                                        </div>
                                        <div className="card-body card-body-height">
                                        <ul className="activity-feed">
                                            {/* <li className="feed-item">
                                                <div className="feed-date">Nov 16, 2023</div>
                                                <span className="feed-text">
                                                    <span id='transfer_code'>#123432.</span> Amount: <span>$20000</span>
                                                </span>
                                            </li> */}
                                            {fetchedPaymentHistory.map((trans,index) => (
                                                <li className="feed-item" key={index}>
                                                    <div className="feed-date">{trans.pdate}</div>
                                                    <span className="feed-text">
                                                        <span id='transfer_code'>#{trans.transfercode}</span>
                                                                &nbsp;|&nbsp;    
                                                        <span className='text-success'>  
                                                            Amount: <span>{trans.amount ? "₦"+trans.amount : ""}</span>
                                                        </span>
                                                        <div>Desc: {trans.narration}</div>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestmentDetails