import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import Investors from './pages/Dashboard/Investors'

import { handleDataTable } from './customjs/script';
import AddInvestor from './pages/Dashboard/AddInvestor'
import InvestorDetails from './pages/Dashboard/InvestorDetails'
import EditInvestor from './pages/Dashboard/EditInvestor'
import ActiveInvestor from './pages/Dashboard/ActiveInvestors'
import DeactiveInvestor from './pages/Dashboard/DeactiveInvestors'
import MembershipPlans from './pages/Dashboard/MembershipPlans'
import Subscribers from './pages/Dashboard/Subscribers'
import Transactions from './pages/Dashboard/Transactions'
import AddUser from './pages/Dashboard/AddUser'
import Users from './pages/Dashboard/Users'
import RolesPermission from './pages/Dashboard/RolesPermission'
import Settings from './pages/Dashboard/Settings'
import EditUser from './pages/Dashboard/EditUser';
import UserProfile from './pages/Dashboard/UserProfile';
import AddInvestment from './pages/Dashboard/AddInvestment';
import InvestorBanks from './pages/Dashboard/InvestorBanks';
import InvestorBanks2 from './pages/Dashboard/InvestorBanks2';
import InvestorWitnesses from './pages/Dashboard/InvestorWitnesses';
import InvestorNoks from './pages/Dashboard/InvestorNoks';
import InvestmentDetails from './pages/Dashboard/InvestmentDetails';
import InvestmentPlans from './pages/Dashboard/InvestmentPlans';
import AddInvestmentPlan from './pages/Dashboard/AddInvestmentPlan';
import SingleInvestmentPlan from './pages/Dashboard/SingleInvestmentPlan';
import ActionLogs from './pages/Dashboard/ActionLogs';
import EditNok from './pages/Dashboard/EditNok';
import AddInvestmentNew from './pages/Dashboard/AddInvestmentNew';
import Investments from './pages/Dashboard/Investments';
import EditInvestment from './pages/Dashboard/EditInvestment';
import InvestmentsWeekly from './pages/Dashboard/InvestmentsWeekly';
import InvestmentsMonthly from './pages/Dashboard/InvestmentsMonthly';
import PaymentsHistory from './pages/Dashboard/PaymentsHistory';
import EditInvestmentStartStopDate from './pages/Dashboard/EditInvestmentStartStopDate';
import EditInvestmentStartStopDateM from './pages/Dashboard/EditInvestmentStartStopDateM';

const AppRoutes = () => {

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        // Check if the current route matches the customer page route
        if (location.pathname === '/subscribers' || location.pathname === '/transactions'
        || location.pathname === '/roles-permission'
        || location.pathname === '/active-investors'
        || location.pathname === '/investor-noks' || location.pathname === '/investor-witnesses'
        || location.pathname === '/action-logs') {
            // Call your function from script.js
            handleDataTable();
        }
    }, [location,navigate]);


    return (
        <Routes>
            <Route path="/" element={ <Dashboard/> } />
            <Route path="/dashboard" element={ <Dashboard/> } />

            <Route path="/investors" element={ <Investors/> } />
            <Route path="/add-investor" element={ <AddInvestor/> } />
            <Route path="/edit-investor" element={ <EditInvestor/> } />
            <Route path="/investor-details" element={ <InvestorDetails/> } />
            <Route path="/active-investors" element={ <ActiveInvestor/> } />
            <Route path="/deactive-investors" element={ <DeactiveInvestor/> } />
            <Route path="/investor-banks" element={ <InvestorBanks/> } />
            <Route path="/investor-banks2" element={ <InvestorBanks2/> } />
            <Route path="/investor-noks" element={ <InvestorNoks/> } />
            <Route path="/edit-nok" element={ <EditNok/> } />
            <Route path="/investor-witnesses" element={ <InvestorWitnesses/> } />

            <Route path="/add-investment" element={ <AddInvestment/> } />
            <Route path="/add-newinvestment" element={ <AddInvestmentNew/> } />
            <Route path="/investments" element={ <Investments/> } />
            <Route path="/investments-weekly" element={ <InvestmentsWeekly/> } />
            <Route path="/investments-monthly" element={ <InvestmentsMonthly/> } />
            <Route path="/edit-investment" element={ <EditInvestment/> } />
            <Route path="/edit-investment-startstopdate" element={ <EditInvestmentStartStopDate/> } />
            <Route path="/edit-investment-startstopdate-month" element={ <EditInvestmentStartStopDateM/> } />
            <Route path="/investment-details" element={ <InvestmentDetails/> } />

            <Route path="/paymenthistory" element={ <PaymentsHistory/> } />

            <Route path="/add-investmentplan" element={ <AddInvestmentPlan/> } />
            <Route path="/single-investmentplan" element={ <SingleInvestmentPlan/> } />
            <Route path="/investment-plans" element={ <InvestmentPlans/> } />

            <Route path="/membership-plans" element={ <MembershipPlans/> } />
            <Route path="/subscribers" element={ <Subscribers/> } />
            <Route path="/transactions" element={ <Transactions/> } />
            <Route path="/add-user" element={ <AddUser/> } />
            <Route path="/edit-user" element={ <EditUser/> } />
            <Route path="/users" element={ <Users/> } />
            <Route path="/user-profile" element={ <UserProfile/> } />
            <Route path="/roles-permission" element={ <RolesPermission/> } />
            <Route path="/action-logs" element={ <ActionLogs/> } />
            <Route path="/settings" element={ <Settings/> } />

            {/* Redirect for unknown routes */}
            {/* <Route path="*" element={ <Dashboard/> } /> */}
            <Route path="/login" element={<Navigate to="/dashboard" />} />
        </Routes>
    )
}

export default AppRoutes