import React, { useEffect } from 'react'
import $ from 'jquery';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AddInvestor = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  useEffect(() => {
    handleInvestorManagement();
  }, []);

  const handleInvestorManagement =() =>{
    $('#addInvestor').off('submit');  // Unbind previous event handlers

    $('#addInvestor').on('submit',function(e){
        e.preventDefault();
        var investor = $(this).serialize();
        console.log(investor)
        if(investor){
          $.ajax({
            type: 'POST',
            url: uRL+'admin/investors',
            data: investor,
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
              $(".lbtn").hide(); $(".nlbtn").show();
              // console.log(response);
              toast.success(response.message);
              navigate('/investors');
            },
            error: function (xhr, textStatus, errorThrown) {
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".lbtn").hide(); $(".nlbtn").show(); },1500);
                }
            }
        });
      }
    });
  }

  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Add Investor</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form id='addInvestor'>
                <div className="card-body">
                  {/* ------------------------------ Basic Details ----------------------------- */}
                  <div className="form-group-item">
                    <h5 className="form-title">Basic Details</h5>
                    <div className="row">
                      {/* <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Title</label>
                          <input type="text" className="form-control" placeholder="Title"
                          name="title" />
                        </div>
                      </div> */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="text" className="form-control" placeholder="Enter First Name"
                          name="firstname" required />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="text" className="form-control" placeholder="Enter Last Name"
                          name="lastname" required />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Other Name (Optional)</label>
                          <input type="text" className="form-control" placeholder="Enter Other Name"
                          name="othername" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Email (Optional)</label>
                          <input type="email" className="form-control" placeholder="Enter Email Address"
                          name="email" /> 
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input type="number" id="mobile_code" className="form-control" placeholder="Phone Number" 
                          name="phonenumber" required />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Address (Optional)</label>
                          <input type="text" className="form-control" placeholder="Address"
                          name="address" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------------------------------ Basic Details ----------------------------- */}
                  
                  <div className="add-customer-btns text-end">
                    <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                    <button type="submit" className="btn btn-primary nlbtn">Save Investor</button>
                    <button type="button" className="btn btn-danger lbtn" disabled style={{display:'none'}}>Loading...</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AddInvestor