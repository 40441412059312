import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Loader, addEllipses, calculateToBePaid, checkInternetConnection, decryptData, formatNumberWithCommas, planCategoryMap, planDurationMap, planIconMap, todayDate } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';

// Initialize pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddInvestmentNew = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        $(function() {
          handleNewInvestmentManagement();
        });
    }, []);

    const [fetchedPlans, setFetchedPlans] = useState([]);
    const [fetchedBanks, setFetchedBanks] = useState([]);
    const [fetchedAccounts, setFetchedAccounts] = useState([]);
    const [fetchedNoks, setFetchedNoks] = useState([]);

    const [loading, setLoading] = useState(true);

    const params = new URLSearchParams(window.location.search);
    const encryptedInvestorCodeNumber = params.get('investorcodenumber');
    const encryptedInvestorID = params.get('investor');
    const acc_id = params.get('acc_id');
    console.log(encryptedInvestorCodeNumber)
    // Decrypt the Investor Code Number
    const decryptedInvestorCodeNumber = decryptData(encryptedInvestorCodeNumber, 'investorcode')
    console.log('Decrypted Investor CodeNumber:', decryptedInvestorCodeNumber);


    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA
    const getBankNameFromCode = (bankCode) => {
        const bank = fetchedBanks.find(bank => bank.bankcode === bankCode);
        console.log(bank)
        return bank ? bank.name : 'Unknown Bank';
    };
    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA


    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */
    
    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */

    const handleNewInvestmentManagement =() =>{
        if(!encryptedInvestorCodeNumber){
            navigate('/investors');
            return;
        }

        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
        $.ajax({
          type: 'GET',
          url: uRL+'admin/plans',
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function(response) {
            console.log('Plans:', response);
            setLoading(false);
            setFetchedPlans(response.data);
          },
          error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
          },
          complete: function() {
            
          }
        });
        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'fetchbanks'}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Banks:', response);
              setLoading(false);
              setFetchedBanks(response);

              if(acc_id){
                // alert(acc_id)
                const account = document.querySelector('#bank_account');
                account.value = acc_id;
              }

            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */


        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/accounts?investor='+decryptedInvestorCodeNumber,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Bank Accounts:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */


        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/nextofkins?investor='+decryptedInvestorCodeNumber,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Nok:', response);
              setLoading(false);
              setFetchedNoks(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                             ADD NEW INVESTMENT                             */
        /* -------------------------------------------------------------------------- */
        $('#addNewInvestment').off('submit');  // Unbind previous event handlers

        $('#addNewInvestment').on('submit',function(e){
            e.preventDefault();

            const receiptFile = $(this).find('input[name="pop"]').prop('files')[0];
            const agreementFile = $(this).find('input[name="agreementdoc"]').prop('files')[0];
            // Validate Receipt File
            // if (!receiptFile) {
            //     toast.error('Please upload a receipt file.');
            //     return;
            // }
            // // Validate Agreement File
            // if (!agreementFile) {
            //     toast.error('Please upload an agreement file.');
            //     return;
            // }
            console.log("receiptFile ",receiptFile);
            console.log("agreementFile ",agreementFile);

            // var investment = $(this).serialize();
            // const investor = decryptedInvestorCodeNumber;
            // // Append the additional value to the serialized form data
            // const updatedInvestment = investment + `&investor=${investor}`;

            const formData = new FormData(this);
            formData.append('investor', decryptedInvestorCodeNumber);

            console.log(formData);
            if (!checkInternetConnection()) {
                toast.error('ERR_INTERNET_DISCONNECTED. Please Check your Internet Connection');
                return;
            }
            // return;
            if(formData){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/investments/create',
                    data: formData,
                    dataType:'json',
                    processData: false,
                    contentType: false,
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        navigate('/investor-details?investor='+encryptedInvestorID+'&investorcode='+encryptedInvestorCodeNumber);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        setTimeout(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                             ADD NEW INVESTMENT                             */
        /* -------------------------------------------------------------------------- */

    }


    const [receiptFile, setReceiptFile] = useState(null);
    const [agreementFile, setAgreementFile] = useState(null);

    const onReceiptFileChange = (e) => {
        if (!checkInternetConnection()) {
            toast.error('ERR_INTERNET_DISCONNECTED. Please Check your Internet Connection');
            return;
        }
        setReceiptFile(e.target.files[0]);
        // console.log(e.target.files[0])
    };

    const onAgreementFileChange = (e) => {
        if (!checkInternetConnection()) {
            toast.error('ERR_INTERNET_DISCONNECTED. Please Check your Internet Connection');
            return;
        }
        setAgreementFile(e.target.files[0]);
    };



    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="content-page-header">
                    <h5>Add New Investment</h5>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        {/* ------------------------------ Add Investment ------------------------------ */}
                        <form id='addNewInvestment'>
                            <div className="card-body">
                                <div className="form-group-customer customer-additional-form">
                                    <div className="row">
                                        <h5 className="form-title">
                                            <span className='text-danger'>*</span> Required fields
                                        </h5>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Select Plan <span className='text-danger'>*</span></label>
                                                <select className="form-control" name="plan" required>
                                                    <option value="">Select Plan</option>
                                                    {/* <option value="Bike">Bike</option> */}
                                                    {fetchedPlans.map((plan, index) => (
                                                        <option key={index} value={plan.id}>{plan.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>How Many? <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" placeholder='How Many? Eg. 1' 
                                                name="howmany" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Select Bank Account <span className='text-danger'>*</span></label>
                                                <select className="form-control" id='bank_account'
                                                name="account" required>
                                                    <option value="">Select Bank Account</option>
                                                    {/* <option value="UBA">UBA</option> */}
                                                    {fetchedAccounts.map((acc, index) => (
                                                        <option key={index} value={acc.id}>
                                                            {getBankNameFromCode(acc.bankcode)} - {acc.accountnumber}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Select Next of Kin</label>
                                                <select className="form-control" 
                                                name="nextofkin">
                                                    <option value="">---</option>
                                                    {/* <option value="UBA">UBA</option> */}
                                                    {fetchedNoks.map((nok, index) => (
                                                        <option key={index} value={nok.id}>
                                                            {nok.firstname+" "+nok.lastname+" - "+nok.relationship} 
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Enter Witness Name <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" placeholder='Witness Name' 
                                                name="witnessname" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Witness Phone Number <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Phone Number"
                                                name="witnessphone" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Witness Address <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Address"
                                                name="witnessaddress" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Agreement Date <span className='text-danger'>*</span></label>
                                                {/* <input type="text" className="form-control" placeholder="Agreement Date" readOnly value={todayDate()} */}
                                                <input type="date" className="form-control" placeholder="Agreement Date"
                                                name="agreementdate" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Proof of Payment</label>
                                                <input type="file" className="form-control-file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" name="pop" onChange={onReceiptFileChange}
                                                    id="receiptFileInput" // Unique ID for this file input
                                                    style={{ display: 'none' }} // Hide the default file input
                                                />
                                                <label htmlFor="receiptFileInput" className="custom-file-label">
                                                    Choose file
                                                </label>
                                                {/* Display PDF previews using react-pdf */}
                                                {!receiptFile ? "" : receiptFile && receiptFile.type === 'application/pdf' ? (
                                                    <div className="col-lg-12" style={{ overflow: 'auto', height: '250px', background: 'whitesmoke' }}>
                                                        <Document file={receiptFile}>
                                                            <Page pageNumber={1} width={1000} />
                                                        </Document>
                                                    </div>
                                                ) : receiptFile && receiptFile.type.startsWith('image/') ? (
                                                    <img src={URL.createObjectURL(receiptFile)} alt="Receipt Preview" style={{ overflow:'auto', width:'100%', height:'250px', objectFit:'contain', background:'whitesmoke' }} />
                                                ) : (
                                                    receiptFile.name
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Agreement File</label>
                                                <input type="file" className="form-control-file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" name="agreementdoc" onChange={onAgreementFileChange} 
                                                    id="agreementFileInput" // Unique ID for this file input
                                                    style={{ display: 'none' }} // Hide the default file input
                                                />
                                                <label htmlFor="agreementFileInput" className="custom-file-label">
                                                    Choose file
                                                </label>
                                                {/* Display PDF previews using react-pdf */}
                                                {!agreementFile ? "" : agreementFile && agreementFile.type === 'application/pdf' ? (
                                                    <div className="col-lg-12" style={{ overflow: 'auto', height: '250px', background: 'whitesmoke' }}>
                                                        <Document file={agreementFile}>
                                                            <Page pageNumber={1} width={1000} />
                                                        </Document>
                                                    </div>
                                                ) : agreementFile && agreementFile.type.startsWith('image/') ? (
                                                    <img src={URL.createObjectURL(agreementFile)} alt="Agreement Preview" style={{ overflow:'auto', width:'100%', height:'250px', objectFit:'contain', background:'whitesmoke' }} />
                                                ) : (
                                                    agreementFile.name
                                                )}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="text-end">
                                        <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                        <button type="submit" className="nlbtn btn btn-primary">Add New Investment</button>
                                        <button type="button" className="lbtn btn btn-danger" style={{display:'none'}}>Loading...</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* ------------------------------ Add Investment ------------------------------ */}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddInvestmentNew