import React, { useEffect } from 'react'
import $ from 'jquery';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AddInvestmentPlan = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        handleInvestmentManagement();
        calculateAmountAndProfit();
        // Listen for input changes on specified elements using event delegation
        $('body').on('input', 'input[name="amount"], input[name="returns"], input[name="no_of"], select[name="duration"]', calculateAmountAndProfit);
        $('body').on('input', 'input[name="amount"]', intitiateInputsToZeroExceptAmount);
        // Cleanup the event listener when the component is unmounted
        return () => {
            $('body').off('input', 'input[name="amount"], input[name="returns"], input[name="no_of"], select[name="duration"]', calculateAmountAndProfit);
            $('body').off('input', 'input[name="amount"]', intitiateInputsToZeroExceptAmount);
        };
    });

    // Function to calculate to_be_paid and percentage_profit
    const calculateAmountAndProfit = () => {
        let amountInvested = parseFloat($('input[name="amount"]').val()) || 0;
        let returns = parseFloat($('input[name="returns"]').val()) || 0;
        let noOf = parseInt($('input[name="no_of"]').val()) || 0;
        let durationType = $('select[name="duration"]').val();
        
        let toBePaid = 0;
        let percentageProfit = 0;
        
        if (durationType === '1') {
            toBePaid = returns * noOf;
            console.log(toBePaid)
        } else if (durationType === '2') {
            toBePaid = returns * (noOf * 4); // Assuming 4 weeks in a month
        }

        let profit = toBePaid - amountInvested;
        percentageProfit = ((profit / amountInvested) * 100).toFixed();
        percentageProfit = percentageProfit < 0 ? 0 : percentageProfit;
  
        $('.to_be_paid').text(toBePaid);
        $('.to_be_paid').val(toBePaid);
        $('.percentage_profit').text(percentageProfit);
        $('.percentage_profit').val(percentageProfit);
    };

    const calculateTotalAmountToBePaid = () => {
        const amount = parseFloat($('input[name="amount"]').val()) || 0;
        const returnAmount = parseFloat($('input[name="returns"]').val()) || 0;
        const noOfPayments = parseInt($('input[name="no_of"]').val()) || 0;
        const durationType = parseInt($('select[name="duration"]').val()) || 0;
        const percentage = parseFloat($('input[name="percentage"]').val()) || 0;

        console.log(amount)
      
        let totalToBePaid = 0;
      
        if (durationType === 1) {  // Weekly
          totalToBePaid = returnAmount * noOfPayments;
        } else if (durationType === 2) {  // Monthly
          totalToBePaid = returnAmount * (noOfPayments * 4);  // Assuming 4 weeks in a month
        }
      
        // Adjust totalToBePaid based on percentage (if provided)
        totalToBePaid = totalToBePaid + (totalToBePaid * (percentage / 100));
      
        // Add the original investment amount to the total
        totalToBePaid += amount;
      
        // Update the DOM element to display the total to be paid
        $('.total_to_be_paid').text(totalToBePaid.toFixed(2));
        $('.total_to_be_paid').val(totalToBePaid.toFixed(2));
    };


    const intitiateInputsToZeroExceptAmount = () => {
        let amount = $('input[name="amount"]').val();
        let returnAmount = $('input[name="returns"]');
        let noOfPayments = $('input[name="no_of"]');
        let durationType = $('select[name="duration"]');
        
        if (amount > 0) {  // Weekly
            returnAmount.value = "";
            noOfPayments.value = "444";
            durationType.value = "";
            $('.to_be_paid').text("---");
            $('.to_be_paid').val('');
            $('.percentage_profit').text("---");
            $('.percentage_profit').val('');
        }
    };
      
      
    const handleInvestmentManagement =() =>{
        $('#addInvestmentPlan').off('submit');  // Unbind previous event handlers

        $('#addInvestmentPlan').on('submit',function(e){
            e.preventDefault();

            var plan = $(this).serialize();
            if(plan){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/plans',
                    data: plan,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        navigate('/investment-plans');
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
    }
    
    
    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
            <div className="page-header">
                <div className="content-page-header">
                <h5>New Investment Plan</h5>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    <form id='addInvestmentPlan'>
                        <div className="card-body">
                            <div className="form-group-customer customer-additional-form">
                                <div className="row">
                                    <h5 className="form-title">Add Investment Plan</h5>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Select Category</label>
                                            <select className="form-control" name="category" required>
                                                <option value="">Select Category</option>
                                                <option value="1">Bike Investment</option>
                                                <option value="2">Vehicle Investment</option>
                                                <option value="3">Bond Yield</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Plan Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Plan Name Eg. Standard Bike, Cruiser Bike, Mini Bus etc"
                                            name="name" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Amount</label>
                                            <input type="number" className="form-control" placeholder="Enter Amount, Eg. 500000"
                                            name="amount" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Percentage Profit (%)</label>
                                            <input type="number" max={1000} min={1} className="form-control percentageNumber" placeholder='%' name='percentage' />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Payment Duration Type</label>
                                            <select className="form-control" name="duration" required>
                                                <option value="">Select Duration Type</option>
                                                <option value="1">Weekly</option>
                                                <option value="2">Monthly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>No of Payment Duration</label>
                                            <input type="number" className="form-control" placeholder="Enter No of Duration Eg. 50 weeks, 10 months etc."
                                            name="no_of" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Returns Amount (Based on duration types Eg. Weekly) </label>
                                            <input type="number" className="form-control" placeholder="Enter Returns, Eg. 16000"
                                            name="returns" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Description <span className='text-muted'>(Optional)</span></label>
                                            <textarea type="text" className="form-control" placeholder="Enter Description (Optional)"
                                            name="description" defaultValue="Optional"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary nlbtn">Add Plan</button>
                                    <button type="button" className="btn btn-danger lbtn" style={{display:'none'}} disabled>Loading...</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    
                </div>
            </div>
            </div>
        </div>
    )
}

export default AddInvestmentPlan