import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Loader, addEllipses, calculateToBePaid, checkInternetConnection, convertDateFormatToyyyy_mm_dd, decryptData, formatNumberWithCommas, planCategoryMap, planDurationMap, planIconMap, todayDate } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';

// Initialize pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EditInvestment = () => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        $(function() {
          handleInvestmentManagement();
        });
    }, []);

    const [investmentToEdit, setInvestmentToEdit] = useState([]);
    const [fetchedPlans, setFetchedPlans] = useState([]);
    const [fetchedBanks, setFetchedBanks] = useState([]);
    const [fetchedAccounts, setFetchedAccounts] = useState([]);
    const [fetchedNoks, setFetchedNoks] = useState([]);
    const [loading, setLoading] = useState(true);

    const params = new URLSearchParams(window.location.search);
    const encryptedInvestmentID = params.get('investment');
    console.log(encryptedInvestmentID)
    // Decrypt the Investor Code Number
    const decryptedInvestmentID = decryptData(encryptedInvestmentID, 'investmentid')
    // console.log('Decrypted Investment ID: ', decryptedInvestmentID);
    const encryptedInvestorCode = Cookies.get('investorcode');
    const decryptedInvestorCode = decryptData(encryptedInvestorCode, 'investorcode');


    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA
    const getBankNameFromCode = (bankCode) => {
        const bank = fetchedBanks.find(bank => bank.bankcode === bankCode);
        console.log(bank)
        return bank ? bank.name : 'Unknown Bank';
    };
    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA


    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */
    
    /* ------------------------- PERCENTAGE CALCULATION ------------------------- */

    const handleInvestmentManagement =() =>{
        if(!encryptedInvestmentID){
            navigate('/investors');
            return;
        }

        /* -------------------------- CALCULATE PERCENTAGE -------------------------- */
        const amountPaidInput = document.querySelector('input[name="amountpaid"]');
        const amountToBeReturnedInput = document.querySelector('input[name="amounttobereturned"]');

        amountPaidInput.addEventListener('input', calculatePercentage);
        amountToBeReturnedInput.addEventListener('input', calculatePercentage);

        function calculatePercentage() {
            const amountPaid = parseFloat(amountPaidInput.value) || 0;
            const amountToBeReturned = parseFloat(amountToBeReturnedInput.value) || 0;

            // Calculate the percentage increase
            const percentageIncrease = ((amountToBeReturned - amountPaid) / amountPaid) * 100;

            // Update the "Percentage" input field
            document.querySelector('input[name="percentage"]').value = isNaN(percentageIncrease) ? '0' : Math.max(0, percentageIncrease).toFixed(0);
        }
        /* -------------------------- CALCULATE PERCENTAGE -------------------------- */


        /* --------------------- Fetch Single Investment Details -------------------- */
        setLoading(true);
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investments/get-investment/'+decryptedInvestmentID,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Single Investment to Edit:', response);
              setLoading(false);
              setInvestmentToEdit(response.investment);
              $('#edit_investmentplan').val(response.investment.planid);
              $('#edit_investmentbankaccount').val(response.investment.account);
              $('#edit_investmentnok').val(response.investment.nextofkin);
              $('#edit_investmentwitnessname').val(response.investment.witnessname);
              $('#edit_investmentwitnessphone').val(response.investment.witnessphone);
              $('#edit_investmentwitnessaddress').val(response.investment.witnessaddress);
              $('#edit_investmentamountpaid').val(response.investment.amountpaid);
              $('#edit_investmentamount_to_be_returned').val(response.investment.amount_to_be_returned);
              $('#percentage').val(response.investment.percentage);
              $('#edit_investmenttimeduration').val(response.investment.timeduration);
              $('#edit_investmentreturn').val(response.investment.return);
              $('#edit_investmenttimeremaining').val(response.investment.timeremaining);
              $('#edit_investmentamountpaidsofar').val(response.investment.amountpaidsofar);
              $('#edit_investmentperiod').val(response.investment.period);
              $('#edit_investmentstatus').val(response.investment.status);
              $('#edit_investmentstartdate').val(convertDateFormatToyyyy_mm_dd(response.investment.startdate));
              $('#edit_investmentstopdate').val(convertDateFormatToyyyy_mm_dd(response.investment.stopdate));
              $('#edit_investmentagreementdate').val(convertDateFormatToyyyy_mm_dd(response.investment.agreementdate));
              
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
            },
            complete: function() {
              
            }
          });
        /* --------------------- Fetch Single Investment Details -------------------- */


        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
        $.ajax({
          type: 'GET',
          url: uRL+'admin/plans',
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function(response) {
            console.log('Plans:', response);
            setLoading(false);
            setFetchedPlans(response.data);
          },
          error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
          },
          complete: function() {
            
          }
        });
        /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'fetchbanks'}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Banks:', response);
              setLoading(false);
              setFetchedBanks(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */


        /* -------------------------------------------------------------------------- */
        /*                          FETCH INVESTMENT BANK ACCOUNTS                    */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'admin/investor/accounts?investor='+decryptedInvestorCode}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investment Account to Edit:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                          FETCH INVESTMENT BANK ACCOUNTS                    */
        /* -------------------------------------------------------------------------- */


        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/accounts?investor='+decryptedInvestorCode,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Bank Accounts:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Accounts Ajax Request ------------------------------ */


        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/investor/nextofkins?investor='+decryptedInvestorCode,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Nok:', response);
              setLoading(false);
              setFetchedNoks(response.data);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* ------------------------------ Fetch Investor's Nok Ajax Request ------------------------------ */


        /* -------------------------------------------------------------------------- */
        /*                                EDIT INVESTMENT                             */
        /* -------------------------------------------------------------------------- */
        $('#editInvestment').off('submit');  // Unbind previous event handlers

        $('#editInvestment').on('submit',function(e){
            e.preventDefault();

            let percentage = document.querySelector('input[name="percentage"]').value;
            if(percentage<1){
                toast.error("Percentage should not be 0, Amount to be returned should be greater than amount paid")
                return;
            }

            const receiptFile = $(this).find('input[name="pop"]').prop('files')[0];
            const agreementFile = $(this).find('input[name="agreementdoc"]').prop('files')[0];
            // Validate Receipt File
            // if (!receiptFile) {
            //     toast.error('Please upload a receipt file.');
            //     return;
            // }
            // // Validate Agreement File
            // if (!agreementFile) {
            //     toast.error('Please upload an agreement file.');
            //     return;
            // }
            console.log("receiptFile ",receiptFile);
            console.log("agreementFile ",agreementFile);

            // var investment = $(this).serialize();
            const formData = new FormData(this);
            if(formData){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/investments/update/'+decryptedInvestmentID,
                    data: formData,
                    dataType:'json',
                    processData: false,
                    contentType: false,
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        // navigate('/investor-details?investor='+encryptedInvestorID+'&investorcode='+encryptedInvestorCodeNumber);
                        window.history.go(-1);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                EDIT INVESTMENT                             */
        /* -------------------------------------------------------------------------- */

    }


    const [receiptFile, setReceiptFile] = useState(null);
    const [agreementFile, setAgreementFile] = useState(null);

    const onReceiptFileChange = (e) => {
        if (!checkInternetConnection()) {
            toast.error('ERR_INTERNET_DISCONNECTED. Please Check your Internet Connection');
            return;
        }
        setReceiptFile(e.target.files[0]);
        // console.log(e.target.files[0])
    };

    const onAgreementFileChange = (e) => {
        if (!checkInternetConnection()) {
            toast.error('ERR_INTERNET_DISCONNECTED. Please Check your Internet Connection');
            return;
        }
        setAgreementFile(e.target.files[0]);
    };




    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
            <div className="page-header">
                <div className="content-page-header">
                <h5>Edit Investment</h5>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    <form id='editInvestment'>
                        <div className="card-body">
                            <div className="form-group-customer customer-additional-form">
                                <div className="row">
                                    <h5 className="form-title">Investment Details 
                                        (<span className='text-danger'>*</span> Required fields)
                                    </h5>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Select Plan <span className='text-danger'>*</span></label>
                                            <select className="form-control" id='edit_investmentplan'
                                            name="plan" required>
                                                <option value="">Select Plan</option>
                                                {/* <option value="Bike">Bike</option> */}
                                                {fetchedPlans.map((plan, index) => (
                                                    <option key={index} value={plan.id}>{plan.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Select Bank Account <span className='text-danger'>*</span></label>
                                            <select className="form-control" id='edit_investmentbankaccount'
                                            name="account" required>
                                                <option value="">Select Bank Account</option>
                                                {/* <option value="UBA">UBA</option> */}
                                                {fetchedAccounts.map((acc, index) => (
                                                    <option key={index} value={acc.id}>
                                                        {getBankNameFromCode(acc.bankcode)} - {acc.accountnumber} - {acc.accountname}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Select Next of Kin</label>
                                            <select className="form-control" id='edit_investmentnok' name="nextofkin">
                                                <option value="">---</option>
                                                {/* <option value="UBA">UBA</option> */}
                                                {fetchedNoks.map((nok, index) => (
                                                    <option key={index} value={nok.id}>
                                                        {nok.firstname+" "+nok.lastname+" - "+nok.relationship} 
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Enter Witness Name</label>
                                            <input type="text" id='edit_investmentwitnessname' className="form-control" placeholder='Witness Name' 
                                            name="witnessname" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Witness Phone Number</label>
                                            <input type="text" id='edit_investmentwitnessphone' className="form-control" placeholder="Enter Phone Number"
                                            name="witnessphone" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Witness Address</label>
                                            <input type="text" id='edit_investmentwitnessaddress' className="form-control" placeholder="Enter Address"
                                            name="witnessaddress" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Amount Paid <span className='text-danger'>*</span></label>
                                            <input type="text" id='edit_investmentamountpaid' className="form-control" placeholder="Enter Amount Paid"
                                            name="amountpaid" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Amount to be Returned <span className='text-danger'>*</span></label>
                                            <input type="text" id='edit_investmentamount_to_be_returned' className="form-control" placeholder="Enter Amount to be Returned"
                                            name="amounttobereturned" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Percentage</label>
                                            <input type="text" id='percentage' className="form-control" placeholder="Calculate Percentage" min={1} max={3000} 
                                            name="percentage" />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Payment Method</label>
                                            <select className="form-control" name="payment_mode">
                                                <option value="">Select Payment Method</option>
                                                <option value="Transfer">Transfer</option>
                                                <option value="Company POS">Company POS</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Payment Duration Type <span className='text-danger'>*</span></label>
                                            <select className="form-control" id='edit_investmentperiod' name="duration" required>
                                                <option value="">Select Duration Type</option>
                                                <option value="1">Weekly</option>
                                                <option value="2">Monthly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>No of Payment Duration <span className='text-danger'>*</span></label>
                                            <input type="text" id='edit_investmenttimeduration' className="form-control" placeholder="Eg. 50 weeks, 10 months etc."
                                            name="no_of" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Returns Amount (Eg. Weekly) <span className='text-danger'>*</span></label>
                                            <input type="text" id='edit_investmentreturn' className="form-control" placeholder="Eg. 16000 Weekly"
                                            name="return" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Remaining No of Payment Duration</label>
                                            <input type="text" id='edit_investmenttimeremaining' className="form-control" placeholder="Eg. 50 weeks, 10 months etc"
                                            name="timeremaining" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Amount Paid so Far</label>
                                            <input type="text" id='edit_investmentamountpaidsofar' className="form-control" placeholder="Amount Paid so Far"
                                            name="amountpaidsofar" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="date" id="edit_investmentstartdate" className="form-control" placeholder="Start Date"
                                            name="startdate" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="date" id="edit_investmentstopdate"className="form-control" placeholder="End Date"
                                            name="stopdate" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-3 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Status <span className='text-danger'>*</span></label>
                                            <select className="form-control" id='edit_investmentstatus'
                                            name="status" required>
                                                <option value="">Select Status</option>
                                                <option value="0">Not ready for payment</option>
                                                <option value="1">On payment</option>
                                                <option value="2">Expired</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Agreement Date</label>
                                            {/* <input type="text" className="form-control" placeholder="Agreement Date" readOnly value={todayDate()} */}
                                            <input type="date" id='edit_investmentagreementdate' className="form-control" placeholder="Agreement Date"
                                            name="agreementdate" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Proof of Payment</label>
                                            <input type="file" className="form-control-file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" name="pop" onChange={onReceiptFileChange}
                                                id="receiptFileInput" // Unique ID for this file input
                                                style={{ display: 'none' }} // Hide the default file input
                                            />
                                            <label htmlFor="receiptFileInput" className="custom-file-label">
                                                Choose file
                                            </label>
                                            {/* Display PDF previews using react-pdf */}
                                            {!receiptFile ? "" : receiptFile && receiptFile.type === 'application/pdf' ? (
                                                <div className="col-lg-12" style={{ overflow: 'auto', height: '250px', background: 'whitesmoke' }}>
                                                    <Document file={receiptFile}>
                                                        <Page pageNumber={1} width={1000} />
                                                    </Document>
                                                </div>
                                            ) : receiptFile && receiptFile.type.startsWith('image/') ? (
                                                <img src={URL.createObjectURL(receiptFile)} alt="Receipt Preview" style={{ overflow:'auto', width:'100%', height:'250px', objectFit:'contain', background:'whitesmoke' }} />
                                            ) : (
                                                receiptFile.name
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Agreement File</label>
                                            <input type="file" className="form-control-file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" name="agreementdoc" onChange={onAgreementFileChange} 
                                                id="agreementFileInput" // Unique ID for this file input
                                                style={{ display: 'none' }} // Hide the default file input
                                            />
                                            <label htmlFor="agreementFileInput" className="custom-file-label">
                                                Choose file
                                            </label>
                                            {/* Display PDF previews using react-pdf */}
                                            {!agreementFile ? "" : agreementFile && agreementFile.type === 'application/pdf' ? (
                                                <div className="col-lg-12" style={{ overflow: 'auto', height: '250px', background: 'whitesmoke' }}>
                                                    <Document file={agreementFile}>
                                                        <Page pageNumber={1} width={1000} />
                                                    </Document>
                                                </div>
                                            ) : agreementFile && agreementFile.type.startsWith('image/') ? (
                                                <img src={URL.createObjectURL(agreementFile)} alt="Agreement Preview" style={{ overflow:'auto', width:'100%', height:'250px', objectFit:'contain', background:'whitesmoke' }} />
                                            ) : (
                                                agreementFile.name
                                            )}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="text-end">
                                    <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                    <button type="submit" className="nlbtn btn btn-primary">Edit Investment</button>
                                    <button type="button" className="lbtn btn btn-danger" style={{display:'none'}}>Loading...</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* ------------------------------ Add Investment ------------------------------ */}
                    
                </div>
            </div>
            </div>
        </div>
    )
}

export default EditInvestment