import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader, encryptData, formatDate, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';


const Users = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  useEffect(() => {
    $(function() {
      handleUserManagement();
    });
  }, []);

  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  // Delete Modal
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  
  const handleUserManagement =(paginationRoute) =>{
    /* ------------------------------ Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: `${!paginationRoute ? uRL+'admin/get-admins?search='+searchValue+'&perpage=10' : 
      paginationRoute+'&search='+searchValue+'&perpage=10'}`,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        console.log('Users:', response);
        setLoading(false);
        setFetchedUsers(response.data);
        setFetchedPagination(response.links);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Ajax Request ------------------------------ */
  }

  /* ------------------------------- DELETE USER ------------------------------ */
  const deleteModal = document.getElementById('delete_modal');
  const deleteUser =(userID)=>{
    $.ajax({
      type: 'DELETE',
      url: uRL+'admin/delete/'+userID,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        $(".lbtn").show(); $(".nlbtn").hide();
      },
      success: function(response) {
        // console.log('Response:', response);
        $('#delete_modal').removeClass('show');
        toast.success(response.message);
        navigate('/users');
        handleUserManagement();
        setLoading(false);
      },
      error: function(xhr, textStatus, errorThrown) {
        $('#delete_modal').removeClass('show');
        if (xhr.responseJSON) {
          if(Array.isArray(xhr.responseJSON.message)){
            toast.error(xhr.responseJSON.message[0]);
            return;
          }
          toast.error(xhr.responseJSON.message);
          setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
        }
      },
      complete: function() {
        
      }
    });
  }
  /* ------------------------------- DELETE USER ------------------------------ */


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log('Search value:', event.target.value);
    searchUser(event.target.value);
  };

  const searchUser =(searchword)=> {
    $.ajax({
      type: 'GET',
      url: `
        ${!searchword ? uRL+'admin/get-admins?perpage=10' : 
        uRL+'admin/get-admins?search='+searchword+'&perpage=10'}
      `,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        console.log('Investment Search:', response);
        setLoading(false);
        setFetchedUsers(response.data);
        setFetchedPagination(response.links);
        if(!searchword){
          handleUserManagement();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
  }



  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Users</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  {/* <li>
                    <span className="btn btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download">
                        <span>
                            <i className="fe fe-download"></i>
                        </span>
                    </span>
                  </li>
                  <li>
                    <span className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </span>
                  </li> */}
                  <li>
                    <Link className="btn btn-primary" to="/add-user">
                      <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add User 
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          {/* <!-- search bar --> */}
          <div className="search-bar d-flex justify-content-end mb-2">
            <form className="form-inline">
              <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" 
              value={searchValue}
              onInput={handleSearchChange}/>
            </form>
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-center table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Role </th>
                            <th>Created On</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>

                          {fetchedUsers.map((user,index) => (
                            <tr key={index}>
                              <td>{index+1}</td>
                              <td>
                                <h2 className="table-avatar">
                                  <Link to="/user-profile" className="avatar avatar-md me-2">
                                    <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-15.jpg" alt="User Gimage" />
                                  </Link>
                                  <Link to="/user-profile">{user.username} <span>
                                    <span className="">{user.email}</span>
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>{user.role === "1" ? 'Sub Admin' : user.role === "2" ? 'Tech Admin' : 'Random Admin'}</td>
                              <td>{formatDate(user.created_at)}</td>
                              <td>
                                {user.status === "1" ? 
                                <span className="badge badge-pill bg-success-light">Active</span> : 
                                <span className="badge badge-pill bg-ash-gray text-gray-light">Restricted</span>
                                }
                              </td>
                              <td className="d-flex align-items-center">
                                <div className="dropdown dropdown-action">
                                  <Link to="/user-profile" className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <ul>
                                      <li>
                                        <Link className="dropdown-item" to={`/edit-user?userID=${encryptData(user.id, 'investible123')}`}>
                                          <i className="far fa-edit me-2"></i>Edit 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" to="/user-profile">
                                          <i className="far fa-eye me-2"></i>View 
                                        </Link>
                                      </li>
                                      <li>
                                        <span className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={() => setUserIdToDelete(user.id)}>
                                          <i className="far fa-trash-alt me-2"></i>Delete </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} onClick={()=>handleUserManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                          // <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                          //   <Link aria-controls="DataTables_Table_0" className="page-link">
                          //     <i className="fa fa-angle-double-left me-2"></i> 
                          //     Previous
                          //   </Link>
                          // </li>
                          // <li className="paginate_button page-item active">
                          //   <Link aria-controls="DataTables_Table_0" className="page-link">
                          //     1
                          //   </Link>
                          // </li>
                          // <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next">
                          //   <Link aria-controls="DataTables_Table_0" className="page-link">
                          //     Next 
                          //     <i className=" fa fa-angle-double-right ms-2"></i>
                          //   </Link>
                          // </li>
                        ))}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>



      <div className="modal modal-backdrop fade" id="delete_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this item?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>deleteUser(userIdToDelete)}>Delete</button>
              <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
            </div>
            </div>
        </div>
      </div>


    </div>
  )
}

export default Users