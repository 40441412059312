import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, decryptData } from '../../utilities/reusablefunctions_variables';

const EditInvestor = () => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  let navigate = useNavigate();

  const [fetchedInvestor, setFetchedInvestor] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstname: fetchedInvestor.firstname,
    lastname: fetchedInvestor.lastname,
    phone: fetchedInvestor.phone,
    email: fetchedInvestor.email,
    username: fetchedInvestor.username
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    handleInvestorManagement();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const encryptedInvestorId = params.get('investor');
  // console.log(encryptedInvestorId)
  // Decrypt the Investor ID
  const decryptedInvestorId = decryptData(encryptedInvestorId, 'investorid')
  // console.log('Decrypted Investor ID:', decryptedUserId);

  const handleInvestorManagement =() =>{

    if(!encryptedInvestorId){
      navigate('/investors');
      return;
    }

    /* -------------------------------- GET INVESTOR ------------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/investors/'+decryptedInvestorId,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        setLoading(true);
      },
      success: function(response) {
        console.log('Single Investor:', response);
        setLoading(false);
        setFetchedInvestor(response.investor);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function() {
        
      }
    });
    /* -------------------------------- GET INVESTOR ------------------------------- */
  }

  /* -------------------------------------------------------------------------- */
  /*                              EDIT SINGLE USER                              */
  /* -------------------------------------------------------------------------- */
  const handleEditInvestorSubmit = (e) => {
    e.preventDefault();

    const user = {};
    user.firstname = e.target.firstname.value;
    user.lastname = e.target.lastname.value;
    user.othername = e.target.othername.value;
    user.email = e.target.email.value;
    user.phonenumber = e.target.phonenumber.value;
    user.address = e.target.address.value;

    console.log(user)
    if(user){
        $.ajax({
            type: 'PUT',
            url: uRL+'admin/investors/'+decryptedInvestorId,
            data: user,
            dataType:'json',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                $(".lbtn").show(); $(".nlbtn").hide();
            },
            success: function(response){
                // console.log(response);
                toast.success(response.message);
                navigate('/investors');
            },
            error: function (xhr, textStatus, errorThrown) {
                if (xhr.responseJSON) {
                    if(Array.isArray(xhr.responseJSON.message)){
                        toast.error(xhr.responseJSON.message[0]);
                        return;
                    }
                    toast.error(xhr.responseJSON.message);
                    // console.error('Error message:', xhr.responseJSON.message);
                    // console.error('Error status:', xhr.responseJSON.status);
                    setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                }
            }
        });
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              EDIT SINGLE USER                              */
  /* -------------------------------------------------------------------------- */


    

  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Edit Investor</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleEditInvestorSubmit}>
                <div className="card-body">
                  {/* ------------------------------ Basic Details ----------------------------- */}
                  <div className="form-group-item">
                    <h5 className="form-title">Basic Details</h5>
                    <div className="row">
                      {/* <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Title</label>
                          <input type="text" className="form-control" placeholder="Title"
                          name="title" />
                        </div>
                      </div> */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>First Name</label>
                          <input defaultValue={fetchedInvestor.firstname} type="text" className="form-control" placeholder="Enter First Name"
                          name="firstname" required onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input defaultValue={fetchedInvestor.lastname} type="text" className="form-control" placeholder="Enter Last Name"
                          name="lastname" required onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Other Name</label>
                          <input defaultValue={fetchedInvestor.othername} type="text" className="form-control" placeholder="Enter Other Name"
                          name="othername" required onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input defaultValue={fetchedInvestor.email} type="email" className="form-control" placeholder="Enter Email Address"
                          name="email" required onChange={handleInputChange} /> 
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input defaultValue={fetchedInvestor.phonenumber} type="number" id="mobile_code" className="form-control" placeholder="Phone Number" 
                          name="phonenumber" required onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input defaultValue={fetchedInvestor.address} type="text" className="form-control" placeholder="Address"
                          name="address" required onChange={handleInputChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------------------------------ Basic Details ----------------------------- */}
                  
                  <div className="add-customer-btns text-end">
                    <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                    <button type="submit" className="btn btn-primary nlbtn">Edit Investor</button>
                    <button type="button" className="btn btn-danger lbtn" disabled style={{display:'none'}}>Loading...</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  )
}

export default EditInvestor