import React from 'react'
import { Link } from 'react-router-dom'

const Transactions = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Transactions</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <div className="dropdown dropdown-action" data-bs-toggle="tooltip" data-bs-placement="top" title="Download">
                      <Link className="btn btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>
                          <i className="fe fe-download"></i>
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <Link className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Print">
                      <span>
                        <i className="fe fe-printer"></i>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="filter_inputs" className="card filter-card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Type</th>
                          <th>Amount </th>
                          <th>Date</th>
                          <th>Payment Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,220</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$1,862</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-danger-light">Cancel</span>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$2,789</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$7,923</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$2,763</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>

                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Purchase</td>
                          <td class="text-success-light">+$4,642</td>
                          <td>19 Dec 2023, 06:12 PM</td>
                          <td>Flutter Wave</td>
                          <td>
                            <span class="badge badge-pill bg-success-light">Completed</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="toggle-sidebar">
        <div className="sidebar-layout-filter">
          <div className="sidebar-header">
            <h5>Filter</h5>
            <Link className="sidebar-closes">
              <i className="fa-regular fa-circle-xmark"></i>
            </Link>
          </div>
          <div className="sidebar-body">
            <form action="customers.html#" autoComplete="off">
              <div className="accordion" id="accordionMain1">
                <div className="card-header-new" id="headingOne">
                  <h6 className="filter-title">
                    <Link className="w-100" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Customer <span className="float-end">
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </Link>
                  </h6>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                  <div className="card-body-chat">
                    <div className="row">
                      <div className="col-md-12">
                        <div id="checkBoxes1">
                          <div className="form-custom">
                            <input type="text" className="form-control" id="member_search1" placeholder="Search here" />
                            <span>
                              <img src="assets/img/icons/search.svg" alt="img" />
                            </span>
                          </div>
                          <div className="selectBox-cont">
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Brian Johnson </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Russell Copeland </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> Greg Lynch </label>
                            <label className="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span className="checkmark"></span> John Blair </label>
                            <div className="view-content">
                              <div className="viewall-One">
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Barbara Moore </label>
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Hendry Evan </label>
                                <label className="custom_check w-100">
                                  <input type="checkbox" name="username" />
                                  <span className="checkmark"></span> Richard Miles </label>
                              </div>
                              <div className="view-all">
                                <Link className="viewall-button-One">
                                  <span className="me-2">View All</span>
                                  <span>
                                    <i className="fa fa-circle-chevron-down"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion border-0" id="accordionMain3">
                <div className="card-header-new" id="headingThree">
                  <h6 className="filter-title">
                    <Link className="w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"> By Status <span className="float-end">
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </Link>
                  </h6>
                </div>
                <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                  <div className="card-body-chat">
                    <div id="checkBoxes2">
                      <div className="selectBox-cont">
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> All Status </label>
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> Activate </label>
                        <label className="custom_check w-100">
                          <input type="checkbox" name="bystatus" />
                          <span className="checkmark"></span> Deactivate </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-buttons">
                <button type="submit" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"> Apply </button>
                <button type="submit" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"> Reset </button>
              </div>
            </form>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Transactions