import React from 'react'

const InvestorWitnesses = () => {
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Investor Witnesses</h5>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
                {/* ------------------------------ Witness Details ------------------------------ */}
                <form id='addInvestorWitness'>
                    <div className="card-body">
                        <div className="form-group-customer customer-additional-form">
                            <div className="row">
                                <h5 className="form-title">Witness Details</h5>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                        name="nokName" required />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input type="number" className="form-control" placeholder="Enter Phone Number"
                                        name="witnessPhone" required />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control" placeholder="Enter Address"
                                        name="witnessAddress" required />
                                    </div>
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                <button type="submit" className="btn btn-primary">Add Witness</button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* ------------------------------ Witness Details ------------------------------ */}


                {/* -------------------------------- Witnesses List ------------------------------- */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card-table">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-center table-hover datatable">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone Number</th>
                                                <th>Address</th>
                                                <th>Created</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Peter Ijeoma</td>
                                                <td>2112322211</td>
                                                <td>Wisdom Peter Street</td>
                                                <td>19 Dec 2022, 06:12 PM</td>
                                                <td>
                                                    <span className="badge badge-pill bg-success-light">Active</span>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>1</td>
                                                <td>Peter Ijeoma</td>
                                                <td>2112322211</td>
                                                <td>Wisdom Peter Street</td>
                                                <td>19 Dec 2022, 06:12 PM</td>
                                                <td>
                                                    <span className="badge badge-pill bg-success-light">Active</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>1</td>
                                                <td>Peter Ijeoma</td>
                                                <td>2112322211</td>
                                                <td>Wisdom Peter Street</td>
                                                <td>19 Dec 2022, 06:12 PM</td>
                                                <td>
                                                    <span className="badge badge-pill bg-success-light">Active</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- Witnesses List ------------------------------- */}

                
            </div>
          </div>
        </div>
    </div>
  )
}

export default InvestorWitnesses