import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader, encryptData, formatDate, formatNumberWithCommas, formatOnlyDate } from '../../utilities/reusablefunctions_variables';

const Dashboard = () => {

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    const navigate = useNavigate();

    const [payableAmount, setPayableAmount] = useState('');
    const [payableAmountM, setPayableAmountM] = useState('');
    const [collectedAmount, setCollectedAmount] = useState('');
    const [returningAmount, setReturningAmount] = useState('');
    const [paidoutAmount, setPaidoutAmount] = useState('');
    const [debtAmount, setDebtAmount] = useState('');
    const [totalInvestors, setTotalInvestors] = useState('');
    const [totalInvestments, setTotalInvestments] = useState('');
    const [ongoingInvestments, setOngoingInvestments] = useState('');
    const [recentInvestments, setRecentInvestments] = useState([]);
    const [recentInvestors, setRecentInvestors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleDashboardManagement();
    },[]);

    const handleDashboardManagement =() =>{

        /* --------------------------- GET WEEKLY PAYABLE AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/payableamount',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setPayableAmount(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET WEEKLY PAYABLE AMOUNT --------------------------- */

        /* --------------------------- GET MONTHLY PAYABLE AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/payableamountmonth',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setPayableAmountM(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET MONTHLY PAYABLE AMOUNT --------------------------- */

         /* --------------------------- GET COLLECTED AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/collectedamount',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setCollectedAmount(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET COLLECTED AMOUNT --------------------------- */

        /* --------------------------- GET RETURNING AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/returningamount',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setReturningAmount(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET RETURNING AMOUNT --------------------------- */

        /* --------------------------- GET PAIDOUT AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/paidoutamount',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setPaidoutAmount(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET PAIDOUT AMOUNT --------------------------- */

        /* --------------------------- GET DEBT AMOUNT --------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/debtamount',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
            //   console.log('Payable Amount:', response);
              setDebtAmount(response.amount);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET DEBT AMOUNT --------------------------- */

        /* --------------------------- GET TOTAL INVESTORS -------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/totalinvestors',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              // console.log('Total Investors:', response);
              setTotalInvestors(response.total);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------------- GET TOTAL INVESTORS -------------------------- */

        /* -------------------------- GET TOTAL INVESTMENTS ------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/totalinvestments',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              // console.log('Total Investments:', response);
              setTotalInvestments(response.total);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------- GET TOTAL INVESTMENTS ------------------------- */

        /* --------------------- GET TOTAL OUTGOING INVESTMENTS --------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/totalongoinginvestment',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              // console.log('Total Ongoing Investment:', response);
              setOngoingInvestments(response.total);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --------------------- GET TOTAL OUTGOING INVESTMENTS --------------------- */

        /* ------------------------ FETCH RECENT INVESTMENTS ------------------------ */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/recentinvestments',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              // console.log('Recent Investments:', response);
              setLoading(false);
              setRecentInvestments(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* ------------------------ FETCH RECENT INVESTMENTS ------------------------ */

        /* ------------------------- FETCH RECENT INVESTORS ------------------------- */
        $.ajax({
            type: 'GET',
            url: uRL+'admin/dashboard/recentinvestors',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              // console.log('Recent Investors:', response);
              setLoading(false);
              setRecentInvestors(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* ------------------------- FETCH RECENT INVESTORS ------------------------- */
    }


    const storeInvestorIDandCode =(investorID, investorCode)=> {
        // alert(investorID);
        Cookies.set('investorid', encryptData(investorID, 'investorid'), { expires: 5 });
        Cookies.set('investorcode', encryptData(investorCode, 'investorcode'), { expires: 5 });
    }

    const saveInvestorID_Code =(investorid, investorcode)=>{
        // alert(investorid);
        Cookies.set('investorid', investorid, { expires: 5 });
        Cookies.set('investorcode', investorcode, { expires: 5 });
    }


    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-1">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Weekly Payable Amount</div>
                                    <div className="dash-counts">
                                        <p>{payableAmount ? '₦'+formatNumberWithCommas(payableAmount) : "..."}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-2">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Monthly Payable Amount</div>
                                    <div className="dash-counts">
                                        <p>{payableAmountM ? '₦'+formatNumberWithCommas(payableAmountM) : "..."}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-3">
                                    <i className="fas fa-users"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title">Total Investors</div>
                                        <div className="dash-counts">
                                            <p>{totalInvestors ? formatNumberWithCommas(totalInvestors) : "..."}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-4">
                                    <i className="fas fa-file-alt"></i>
                                    </span>
                                    <div className="dash-count">
                                        <div className="dash-title d-flex">
                                            <small style={{fontSize: '10px'}}>Total Investments</small>
                                            <div className="dash-counts ms-1">
                                                <p>{totalInvestments ? formatNumberWithCommas(totalInvestments) : "..."}</p>
                                            </div>
                                        </div>
                                        
                                        <div className="dash-title d-flex">
                                            <small style={{fontSize: '10px'}}>Ongoing Investment</small>
                                            <div className="dash-counts ms-1">
                                                <p>{ongoingInvestments ? formatNumberWithCommas(ongoingInvestments) : "..."}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-7">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Total Collected Amount</div>
                                    <div className="dash-counts">
                                        <p>{collectedAmount ? '₦'+formatNumberWithCommas(collectedAmount) : "0"}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-6">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Total Returning Amount</div>
                                    <div className="dash-counts">
                                        <p>{returningAmount ? '₦'+formatNumberWithCommas(returningAmount) : "0"}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-8">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Total PaidOut Amount</div>
                                    <div className="dash-counts">
                                        <p>{paidoutAmount ? '₦'+formatNumberWithCommas(paidoutAmount) : "0"}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon bg-5">
                                    <i className="fas fa-naira-sign"></i>
                                    </span>
                                    <div className="dash-count">
                                    <div className="dash-title">Total Debt Amount</div>
                                    <div className="dash-counts">
                                        <p>{debtAmount ? '₦'+formatNumberWithCommas(debtAmount) : "0"}</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-center">
                                    <div className="col">
                                        <h5 className="card-title">Recent Investments</h5>
                                    </div>
                                    <div className="col-auto">
                                        <Link className="btn-right btn btn-sm btn-outline-primary" to="/investments"> View All </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {loading && <Loader />}
                                {!loading && (
                                <>
                                    <div className="mb-3">
                                        <div className="row">
                                        <div className="col-auto">
                                            <i className="fas fa-circle text-success me-1"></i> On Payment
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-circle text-warning me-1"></i> Not Ready for Payment
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-circle text-danger me-1"></i> Expired
                                        </div>
                                        <div className="col-auto">
                                            {/* <i className="fas fa-circle text-info me-1"></i> Undefined */}
                                            <i className="fas fa-circle text-secondary me-1"></i> Not Defined
                                        </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-stripped table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Investor</th>
                                                <th>Amount</th>
                                                <th>Stop Date</th>
                                                <th>Status</th>
                                                <th className="text-right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recentInvestments.map((investment,index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <span className='d-flex align-items-center'>
                                                                <img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="User" /> 
                                                                <span>
                                                                    <b>{investment.investment_owner.firstname} {investment.investment_owner.lastname}</b>
                                                                    <br/><small>{investment.investment_owner.codenumber}</small>
                                                                </span>
                                                            </span>
                                                        </h2>
                                                    </td>
                                                    <td>{investment.amountpaid ? "₦"+formatNumberWithCommas(investment.amountpaid) : ""}</td>
                                                    <td>{!investment.stopdate ? "" : formatOnlyDate(investment.stopdate)}</td>
                                                    <td>
                                                    {
                                                        investment.status === "0" ? <i className="fas fa-circle text-warning me-1"></i> :
                                                        investment.status === "1" ? <i className="fas fa-circle text-success me-1"></i> :
                                                        investment.status === "2" ? <i className="fas fa-circle text-danger me-1"></i> :
                                                        <i className="fas fa-circle text-secondary me-1"></i>
                                                    }
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <span className="action-icon dropdown-toggle" role='button' data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fas fa-ellipsis-h"></i>
                                                            </span>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <Link onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                                                    to={`/edit-investment?investment=${encryptData(investment.id, 'investmentid')}`}>
                                                                    <span className="dropdown-item">
                                                                    <i className="far fa-edit me-2"></i>Edit 
                                                                    </span>
                                                                </Link>
                                                                
                                                                <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                                                    to={`/edit-investment-startstopdate?investment=${encryptData(investment.id, 'investmentid')}`}>
                                                                    <i className="far fa-calendar me-2"></i>Start/Stop Date 
                                                                </Link>

                                                                <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                                                    to={`/investment-details?investment=${encryptData(investment.id, 'investmentid')}`}>
                                                                    <i className="far fa-eye me-2"></i>View 
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </table>
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-header">
                            <div className="row align-center">
                                <div className="col">
                                    <h5 className="card-title">Recent Investors</h5>
                                </div>
                                <div className="col-auto">
                                    <Link className="btn-right btn btn-sm btn-outline-primary" to="/investors"> View All </Link>
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                                {loading && <Loader />}
                                {!loading && (
                                <>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-auto">
                                                <i className="fas fa-circle text-success me-1"></i> Active
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-circle text-danger me-1"></i> Inactive
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-circle text-default me-1"></i> Not Defined
                                            </div>
                                        </div><br/>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Investor</th>
                                                    <th>Phone</th>
                                                    <th>Created</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recentInvestors.map((investor,index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <h2 className="table-avatar">
                                                                <span className='d-flex align-items-center'>
                                                                    <img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="User" /> 
                                                                    <span>
                                                                        <Link onClick={()=>saveInvestorID_Code(encryptData(investor.id,'investorid'), encryptData(investor.codenumber,'investorcode'))}
                                                                        to={`/investor-details?investor=${encryptData(investor.id, 'investorid')}&investorcode=${encryptData(investor.codenumber, 'investorcode')}`}
                                                                        >
                                                                            {investor.firstname+" "+investor.lastname}<br/>
                                                                            {investor.email} <br/>
                                                                            {investor.codenumber}
                                                                        </Link>
                                                                    </span>
                                                                </span>
                                                            </h2>
                                                        </td>
                                                        <td>{investor.phonenumber}</td>
                                                        <td>{formatDate(investor.created_at)}</td>
                                                        <td>
                                                        {
                                                            investor.status === "0" ? <i className="fas fa-circle text-danger me-1"></i>
                                                            : investor.status === "1" ? <i className="fas fa-circle text-success me-1"></i>
                                                            : <i className="fas fa-circle text-default me-1"></i>
                                                        }
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="dropdown dropdown-action" role='button'>
                                                                <span className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-ellipsis-h"></i>
                                                                </span>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <Link className="dropdown-item" to={`/edit-investor?investor=${encryptData(investor.id, 'investorid')}`}>
                                                                        <i className="far fa-edit me-2"></i>Edit 
                                                                    </Link>
                                                                    <Link className="dropdown-item" 
                                                                        to={`/investor-details?investor=${encryptData(investor.id, 'investorid')}&investorcode=${encryptData(investor.codenumber, 'investorcode')}`}>
                                                                        <i className="far fa-eye me-2"></i>View 
                                                                    </Link>
                                                                    <Link className="dropdown-item">
                                                                        <i className="fa-solid fa-power-off me-2"></i>Activate 
                                                                    </Link>
                                                                    <Link className="dropdown-item">
                                                                        <i className="far fa-bell-slash me-2"></i>Deactivate 
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard