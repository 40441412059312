import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Loader, decryptData, encryptData, formatDate, formatNumberWithCommas, planDurationMap, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';

const CustomerDetails = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const navigate = useNavigate();

  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedInvestor, setFetchedInvestor] = useState([]);
  const [fetchedInvestorInvestments, setFetchedinvestorInvestments] = useState([]);
  const [fetchedPagination, setFetchedPagination] = useState([]);

  const [loading, setLoading] = useState(true);

  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */
  const getPlanNameFromPlanID = (planID) => {
    let plan = fetchedPlans.find(plan => plan.id === parseInt(planID));
    return plan ? plan.name : 'Unknown Plan';
  };
  /* --------- GET PLAN NAME FROM LOOPED PLAN ID THROUGH fetchedplans --------- */

  const params = new URLSearchParams(window.location.search);
  const encryptedInvestorId = params.get('investor');
  const encryptedInvestorCode = params.get('investorcode');
  // console.log(encryptedInvestorId)

  const handleInvestorManagement =() =>{
    
    if(!encryptedInvestorId){
        navigate('/investors');
        return;
    }
    // Decrypt the user ID
    const decryptedInvestorId = decryptData(encryptedInvestorId, 'investorid')
    const decryptedInvestorCode = decryptData(encryptedInvestorCode, 'investorcode')
    // console.log('Decrypted User ID:', decryptedUserId);


    /* -------------------------------- GET INVESTOR ------------------------------- */
    $.ajax({
        type: 'GET',
        url: uRL+'admin/investors/'+decryptedInvestorId,
        dataType: 'json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          // console.log('Single Investor:', response);
          setLoading(false);
          setFetchedInvestor(response.investor);
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
          
        }
    });
    /* -------------------------------- GET INVESTOR ------------------------------- */


    /* -------------------------------------------------------------------------- */
    /*                        FETCH INVESTOR'S INVESTMENTS                        */
    /* -------------------------------------------------------------------------- */
    if(fetchedInvestor){
      $.ajax({
        type: 'GET',
        url: uRL+'admin/investments/fetchinvestments?investor='+decryptedInvestorCode,
        dataType: 'json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: function(response) {
          // console.log('Investor Investments:', response);
          setLoading(false);
          setFetchedinvestorInvestments(response.data);
          setFetchedPagination(response.links);
        },
        error: function(xhr, textStatus, errorThrown) {
          console.error('Error:', textStatus);
          console.error('Status:', xhr.status);
          // Handle the error as needed
        },
        complete: function() {
          
        }
      });
    }
    /* -------------------------------------------------------------------------- */
    /*                        FETCH INVESTOR'S INVESTMENTS                        */
    /* -------------------------------------------------------------------------- */
    


    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL+'admin/plans',
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function(response) {
        // console.log('Plans:', response);
        setLoading(false);
        setFetchedPlans(response.data);
      },
      error: function(xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
      },
      complete: function() {
        
      }
    });
    /* ------------------------------ Fetch Plans Ajax Request ------------------------------ */ 

    $(document).on('ready',function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

  }

  const storeInvestorIDandCode =(investorID, investorCode)=> {
    // alert(investorID);
    Cookies.set('investorid', encryptData(investorID, 'investorid'), { expires: 5 });
    Cookies.set('investorcode', encryptData(investorCode, 'investorcode'), { expires: 5 });
  }


  useEffect(() => {
    handleInvestorManagement();
  }, []);


  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Investor Details</h5>
            </div>
          </div>

          {/*  --------------------------- One Single Card Row --------------------------  */}
          {loading && <Loader />}
          {!loading && (
            <div className="card customer-details-group">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="customer-details">
                      <div className="d-flex align-items-center">
                        <span className="customer-widget-img d-inline-flex">
                          <img className="rounded-circle" src="assets/img/profiles/avatar-14.jpg" alt="Gimage" />
                        </span>
                        <div className="customer-details-cont">
                          <h6>{fetchedInvestor.firstname+" "+fetchedInvestor.lastname+" "+fetchedInvestor.othername}</h6>
                          <p>{fetchedInvestor.codenumber}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="customer-details">
                      <div className="d-flex align-items-center">
                        <span className="customer-widget-icon d-inline-flex">
                          <i className="fe fe-mail"></i>
                        </span>
                        <div className="customer-details-cont">
                          <h6>Email Address</h6>
                          <p>
                            <span className="">{fetchedInvestor.email}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="customer-details">
                      <div className="d-flex align-items-center">
                        <span className="customer-widget-icon d-inline-flex">
                          <i className="fe fe-phone"></i>
                        </span>
                        <div className="customer-details-cont">
                          <h6>Phone Number</h6>
                          <p>{fetchedInvestor.phonenumber}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="customer-details">
                      <div className="d-flex align-items-center">
                        <span className="customer-widget-icon d-inline-flex">
                          <i className="fe fe-briefcase"></i>
                        </span>
                        <div className="customer-details-cont">
                          <h6>Address</h6>
                          <p>{fetchedInvestor.address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  --------------------------- One Single Card Row --------------------------  */}


          {/*  --------------------------- Multiple Button Row --------------------------  */}
          {loading && <Loader />}
          {!loading && (
            <div className='row form-group-item'>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex my-2">
                <Link className="btn btn-danger w-100" 
                to={`/add-investment?investor=${encryptedInvestorId}&investorcodenumber=${encryptedInvestorCode}`}>
                  <i className="fa fa-plus-circle" aria-hidden="true"></i> Old Investment 
                </Link>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex my-2">
                <Link className="btn btn-success w-100" 
                to={`/add-newinvestment?investor=${encryptedInvestorId}&investorcodenumber=${encryptedInvestorCode}`}>
                  <i className="fa fa-plus-circle" aria-hidden="true"></i> New Investment 
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-4 col-12 d-flex my-2">
                <Link className="btn btn-primary w-100" to={`/investor-banks?investorcodenumber=${encryptedInvestorCode}`}>
                  <i className="fa fa-bank" aria-hidden="true"></i> Bank Details 
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-4 col-12 d-flex my-2">
                <Link className="btn btn-outline-danger w-100" to={`/investor-banks2?investorcodenumber=${encryptedInvestorCode}`}>
                  <i className="fa fa-bank" aria-hidden="true"></i> Bank Details 2
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-4 col-12 d-flex my-2">
                <Link className="btn btn-outline-primary w-100" to={`/investor-noks?investorcodenumber=${encryptedInvestorCode}`}>
                  <i className="fa fa-list" aria-hidden="true"></i> Next of Kins 
                </Link>
              </div>
            </div>
          )}
          {/*  --------------------------- Multiple Button Row --------------------------  */}


          {/*  --------------------------- Multiple Cards Row ---------------------------  */}
          {/* <div className="row">
            <div className="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
              <div className="card inovices-card w-100">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="inovices-widget-icon bg-info-light">
                      <img src="assets/img/icons/receipt-item.svg" alt="Gimage" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Total Investments</div>
                      <div className="dash-counts">
                        <p>2</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="inovices-all">No of Plans</p>
                    <p className="inovice-trending text-success-light">1</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
              <div className="card inovices-card w-100">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="inovices-widget-icon bg-primary-light">
                      <img src="assets/img/icons/transaction-minus.svg" alt="Gimage" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Total <br/>Deposits</div>
                      <div className="dash-counts">
                        <p>₦325,215</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="inovices-all">Last Deposit</p>
                    <p className="inovice-trending text-success-light">4/4/2023</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
              <div className="card inovices-card w-100">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="inovices-widget-icon bg-warning-light">
                      <img src="assets/img/icons/archive-book.svg" alt="Gimage" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Total Supposed<br/>Returns</div>
                      <div className="dash-counts">
                        <p>₦7825</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
              <div className="card inovices-card w-100">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="inovices-widget-icon bg-green-light">
                      <img src="assets/img/icons/message-edit.svg" alt="Gimage" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Total <br/>Payment</div>
                      <div className="dash-counts">
                        <p>₦125,586</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="inovices-all">Last Payment</p>
                    <p className="inovice-trending text-success-light">4/4/2023</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
              <div className="card inovices-card w-100">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="inovices-widget-icon bg-danger-light">
                      <img src="assets/img/icons/3d-rotate.svg" alt="Gimage" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Outstanding <br/>Payment</div>
                      <div className="dash-counts">
                        <p>₦86,892</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*  --------------------------- Multiple Cards Row ---------------------------  */}


          


          {/*  --------------------------- Investor Investment Table --------------------------  */}
          <div className="page-header">
              <div className="content-page-header">
              <h5>Investments</h5>
              </div>
          </div>

          {loading && <Loader />}
          {!loading && (
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-stripped table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>ID</th>
                            <th>Plan</th>
                            <th>Period</th>
                            <th>Agreement<br/>Date</th>
                            <th>Amount<br/>Paid</th>
                            <th>Amount to<br/>be Returned</th>
                            <th>Return Per <br/>Period</th>
                            <th>Amount Paid<br/>So Far</th>
                            <th>Last Payment<br/>Date</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedInvestorInvestments.map((investment,index) => (
                            <tr key={index}>
                              <td>
                                <b>{investment.investmentid}</b><br/>
                                <span className='d-flex'>
                                  {!investment.pop ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.pop} target="_blank">
                                            <img src="assets/img/receipt.png" width={30} alt="Receipt"
                                              data-toggle="tooltip"
                                              title="Proof of Payment"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }

                                  {!investment.agreementdoc ? "" : 
                                    <>
                                        <Link to={process.env.REACT_APP_FILELINK + investment.agreementdoc} target="_blank">
                                            <img src="assets/img/document.png" width={30} alt="Agreement doc" 
                                              data-toggle="tooltip"
                                              title="Agreement Document"
                                            />
                                        </Link>
                                        &nbsp;&nbsp;
                                    </>
                                  }
                                </span>

                              </td>
                              <td>{getPlanNameFromPlanID(investment.planid)}</td>
                              <td>{planDurationMap[investment.period]}</td>
                              <td>{investment.agreementdate}</td>
                              <td>{investment.amountpaid ? "₦"+formatNumberWithCommas(investment.amountpaid) : ""}</td>
                              <td>{investment.amount_to_be_returned ? "₦"+formatNumberWithCommas(investment.amount_to_be_returned) : ""}</td>
                              <td>{investment.return ? "₦"+formatNumberWithCommas(investment.return) : ""}</td>
                              <td>{investment.amountpaidsofar ? "₦"+formatNumberWithCommas(investment.amountpaidsofar) : ""}</td>
                              <td>{investment.lastpaymentdate ? "₦"+formatNumberWithCommas(investment.lastpaymentdate) : ""}</td>
                              <td>{investment.startdate}</td>
                              <td>{investment.stopdate}</td>
                              <td>
                                {
                                  investment.status === "0" ? <span className="badge bg-warning-light text-warning">Not Ready for Payment</span> :
                                  investment.status === "1" ? <span className="badge bg-success-light">On Payment</span> :
                                  investment.status === "2" ? <span className="badge bg-danger-light text-danger">Expired</span> :
                                  <span className="badge bg-light-gray text-secondary">Undefined Status</span>
                                }
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <span role='button' className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-end customer-dropdown">
                                    <ul>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/edit-investment?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-edit me-2"></i>Edit
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/edit-investment-startstopdate?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-calendar me-2"></i>Start/Stop Date 
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className="dropdown-item" onClick={()=>storeInvestorIDandCode(investment.investment_owner.id, investment.investment_owner.codenumber)}
                                        to={`/investment-details?investment=${encryptData(investment.id, 'investmentid')}`}>
                                          <i className="far fa-eye me-2"></i>View 
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                      <ul className="pagination">
                        {fetchedPagination.map((data, index) => (
                          <li key={index} role='button' onClick={()=>handleInvestorManagement(data.url)} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                          ${data.active ? 'active':'disabled'}`} 
                          id="DataTables_Table_0_previous">
                            <Link aria-controls="DataTables_Table_0" className="page-link">
                              {replaceQuotationMarks(data.label).trim()}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  --------------------------- Investor Investment Table --------------------------  */}








          <div className="modal custom-modal fade" id="delete_modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Customer Details</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-continue-btn">Delete</span>
                      </div>
                      <div className="col-6">
                        <span data-bs-dismiss="modal" className="btn btn-primary paid-cancel-btn">Cancel</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CustomerDetails