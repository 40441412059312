import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader, decryptData, replaceQuotationMarks } from '../../utilities/reusablefunctions_variables';


const InvestorBanks = () => {

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    let navigate = useNavigate();

    useEffect(() => {
        $(function() {
            handleInvestorBankManagement();
            fetchAccountDetails();
        });
    }, []);

    const [fetchedAccounts, setFetchedAccounts] = useState([]);
    const [fetchedPagination, setFetchedPagination] = useState(null);
    const [fetchedBanks, setFetchedBanks] = useState([]);
    const [loading, setLoading] = useState(true);

    // Delete Modal
    const [accountIdForAction, setAccountIdForAction] = useState(null);
    const changeAccountID =(accID)=>{
        setAccountIdForAction(accID);
    }

    const params = new URLSearchParams(window.location.search);
    const encryptedInvestorCode = params.get('investorcodenumber');
    // console.log(encryptedInvestorCode)
    // Decrypt the user ID
    const decryptedInvestorCode = decryptData(encryptedInvestorCode, 'investorcode')

    const handleInvestorBankManagement =(paginationRoute) =>{

        if(!encryptedInvestorCode){
            navigate('/investors');
            return;
        }

        /* ------------------------------ Ajax Request ------------------------------ */
        $.ajax({
          type: 'GET',
          url: `${!paginationRoute ? uRL+'admin/investor/accounts?investor='+decryptedInvestorCode : paginationRoute}`,
          dataType: 'json',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: function(response) {
            console.log('Investor Account:', response);
            setLoading(false);
            setFetchedAccounts(response.data);
            setFetchedPagination(response.links);
          },
          error: function(xhr, textStatus, errorThrown) {
            console.error('Error:', textStatus);
            console.error('Status:', xhr.status);
            // Handle the error as needed
          },
          complete: function() {
            
          }
        });
        /* ------------------------------ Ajax Request ------------------------------ */


        /* --- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx --- */
        // Get Investor's Details
        $.ajax({
            type: 'GET',
            url: `${uRL+'admin/investor/accounts?investor='+decryptedInvestorCode}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Investor Account:', response);
              setLoading(false);
              setFetchedAccounts(response.data);
              setFetchedPagination(response.links);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* --- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx --- */


        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */
        $.ajax({
            type: 'GET',
            url: `${uRL+'fetchbanks'}`,
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: function(response) {
              console.log('Banks:', response);
              setLoading(false);
              setFetchedBanks(response);
            },
            error: function(xhr, textStatus, errorThrown) {
              console.error('Error:', textStatus);
              console.error('Status:', xhr.status);
              // Handle the error as needed
            },
            complete: function() {
              
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                                 FETCH BANKS                                */
        /* -------------------------------------------------------------------------- */


        /* -------------------------------------------------------------------------- */
        /*                               ADD INVESTOR'S BANK                             */
        /* -------------------------------------------------------------------------- */
        $('#addInvestorBank').off('submit');  // Unbind previous event handlers

        $('#addInvestorBank').on('submit',function(e){
            e.preventDefault();

            var bank = $(this).serialize();
            // Append "investor" field
            var updatedSerializedData = `${bank}&investor=${decryptedInvestorCode}`;
            console.log(updatedSerializedData)
            if(bank){
                $.ajax({
                    type: 'POST',
                    url: uRL+'admin/investor/accounts',
                    data: updatedSerializedData,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        $(".nlbtn").show(); $(".lbtn").hide();
                        toast.success(response.message);
                        handleInvestorBankManagement();
                        $('#addInvestorBank').trigger('reset');
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                               ADD INVESTOR'S BANK                             */
        /* -------------------------------------------------------------------------- */


        /* -------------------------------------------------------------------------- */
        /*                            EDIT INVESTOR'S BANK                            */
        /* -------------------------------------------------------------------------- */
        $('#editInvestorBank').off('submit');  // Unbind previous event handlers

        const editModal = document.getElementById('edit_modal');
        $('#editInvestorBank').on('submit',function(e){
            e.preventDefault();
            var IDForEdit=$('#aid').val();
            var bank = $(this).serialize();
            console.log(bank)
            if(bank){
                $.ajax({
                    type: 'PUT',
                    url: uRL+'admin/investor/accounts/'+IDForEdit,
                    data: bank,
                    dataType:'json',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        $(".lbtn").show(); $(".nlbtn").hide();
                    },
                    success: function(response){
                        // console.log(response);
                        toast.success(response.message);
                        handleInvestorBankManagement();
                        editModal.style.display="none";
                        $('#addInvestorBank').trigger('reset');
                        setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                    },
                    error: function (xhr, textStatus, errorThrown) {
                        editModal.style.display="none";
                        if (xhr.responseJSON) {
                            if(Array.isArray(xhr.responseJSON.message)){
                                toast.error(xhr.responseJSON.message[0]);
                                return;
                            }
                            toast.error(xhr.responseJSON.message);
                            // console.error('Error message:', xhr.responseJSON.message);
                            // console.error('Error status:', xhr.responseJSON.status);
                            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
                        }
                    }
                });
            }
        });
        /* -------------------------------------------------------------------------- */
        /*                            EDIT INVESTOR'S BANK                            */
        /* -------------------------------------------------------------------------- */
    }


    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA
    const getBankNameFromCode = (bankCode) => {
        const bank = fetchedBanks.find(bank => bank.bankcode === bankCode);
        console.log(bank)
        return bank ? bank.name : 'Unknown Bank';
    };
    // RETRIEVE BANK NAME WITH THE HELP OF BANK CODE USING THE FETCHBANKS MAPPED API DATA


    /* -------------------------------------------------------------------------- */
    /*                            FETCH ACCOUNT DETAILS                           */
    /* -------------------------------------------------------------------------- */
    const fetchAccountDetails =()=>{
        let accountnumber = $('input[name="accountnumber"]').val();
        let accountnumber_length;
        let bank = $('#bankcode').val();
        // console.log(accountnumber.length);
        // console.log(bank);
        if(accountnumber && bank){
            if(accountnumber.length<10){
                $('#accountname').val('');
                return;
            }
            if(accountnumber.length>10){
                $('#accountname').val('');
                toast.error("Account number should be 10 digits");
                return;
            }
            accountnumber_length = accountnumber.length;
            if(bank === "" || bank === null){
                toast.error("Please Select a Bank");
                return;
            }
        }

        // For Edit
        let edit_accountnumber = $('#edit_accountnumber').val();
        let edit_bankcode = $('#edit_bankcode').val();
        console.log(edit_accountnumber, " ", edit_bankcode);
        if(edit_accountnumber){
            if(edit_accountnumber.length<10){
                return;
            }
            if(edit_accountnumber.length>10){
                toast.error("Account number should be 10 digits");
                return;
            }
            accountnumber_length = edit_accountnumber.length;
            if(edit_bankcode === "" || edit_bankcode === null){
                toast.error("Please Select a Bank");
                return;
            }
        }

        // API CALL
        if(accountnumber_length === 10){
            if(accountnumber && bank){
                $('.fetchaccount_loader').html("<span class='text-success'><i>...Fetching Account Details</i></span>");
                $('.fetchaccount_loader').show();
            }
            if(edit_accountnumber && edit_bankcode){
                console.log(accountnumber_length);
                $('.edit_fetchaccount_loader').html("<span class='text-success'><i>...Fetching Account Details</i></span>");
                $('.edit_fetchaccount_loader').show();
            }
            $.ajax({
                type: 'GET',
                url: uRL+`fetchaccountdetails?accountnumber=${accountnumber}&bank=${bank}`,
                dataType: 'json',
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                success: function(response) {
                    console.log('Account Details:', response);
                    $('.fetchaccount_loader').hide();
                    if(response.account.status === "error"){
                        $('#accountname').val('');
                        toast.error(response.account.message);
                        $('.fetchaccount_loader').html("<span class='text-danger'>"+response.account.message+"</span>");
                        $('.fetchaccount_loader').show();
                        return;
                    }
                    $('#accountname').val(response.account.data.account_name);
                },
                error: function(xhr, textStatus, errorThrown) {
                    $('.fetchaccount_loader').hide();
                    console.error('Error:', textStatus);
                    console.error('Status:', xhr.status);
                    // Handle the error as needed
                },
                complete: function() {
                
                }
            });
        }
        // END OF API CALL
    }


    // When Bank is Changed in Edit
    const editBankcodeChange =()=>{
        $('#edit_accountnumber').val('');
        $('#edit_accountname').val('');
    }
    // When Bank is Changed in Edit


    const edit_fetchAccountDetails =()=>{
        let accountnumber = $('#edit_accountnumber').val();
        let accountname = $('#edit_accountname').val();
        let bank = $('#edit_bankcode').val();
        // console.log(accountnumber.length);
        // console.log(bank);
        if(accountnumber.length<10){
            $('#edit_accountname').val('');
            return;
        }
        if(accountnumber.length>10){
            $('#edit_accountname').val('');
            toast.error("Account number should be 10 digits");
            return;
        }
        if(bank === "" || bank === null){
            toast.error("Please Select a Bank");
            return;
        }
        // if(accountname === "" || accountname === null){
        //     toast.error("Please Enter a vailid account details");
        //     return;
        // }

        // API CALL
        if(accountnumber.length === 10){
            $('.edit_fetchaccount_loader').html("<span class='text-success'><i>...Fetching Account Details</i></span>");
            $('.edit_fetchaccount_loader').show();
            
            $.ajax({
                type: 'GET',
                url: uRL+`fetchaccountdetails?accountnumber=${accountnumber}&bank=${bank}`,
                dataType: 'json',
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                success: function(response) {
                    console.log('Account Details:', response);
                    $('.edit_fetchaccount_loader').hide();
                    if(response.account.status === "error"){
                        $('#edit_accountname').val('');
                        toast.error(response.account.message);
                        $('.edit_fetchaccount_loader').html("<span class='text-danger'>"+response.account.message+"</span>");
                        $('.edit_fetchaccount_loader').show();
                        return;
                    }
                    $('#edit_accountname').val(response.account.data.account_name);
                },
                error: function(xhr, textStatus, errorThrown) {
                    $('.fetchaccount_loader').hide();
                    console.error('Error:', textStatus);
                    console.error('Status:', xhr.status);
                    // Handle the error as needed
                },
                complete: function() {
                
                }
            });
        }
        // END OF API CALL
    }
    /* -------------------------------------------------------------------------- */
    /*                            FETCH ACCOUNT DETAILS                           */
    /* -------------------------------------------------------------------------- */


    /* ------------------------------- DELETE BANK ACCOUNT ------------------------------ */
    const deleteModal = document.getElementById('delete_modal');
    const deleteBankAccount =(accountID)=>{
        $.ajax({
        type: 'DELETE',
        url: uRL+'admin/investor/accounts/'+accountID,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            $(".lbtn").show(); $(".nlbtn").hide();
        },
        success: function(response) {
            // console.log('Response:', response);
            deleteModal.style.display="none";
            toast.success(response.message);
            $(".lbtn").hide(); $(".nlbtn").show();
            handleInvestorBankManagement();
            setLoading(false);
        },
        error: function(xhr, textStatus, errorThrown) {
            deleteModal.style.display="none";
            if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
                toast.error(xhr.responseJSON.message[0]);
                return;
            }
            toast.error(xhr.responseJSON.message);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
            }
        },
        complete: function() {
            
        }
        });
    }
    /* ------------------------------- DELETE BANK ACCOUNT ------------------------------ */

    
    /* ----------------------------- GET ONE ACCOUNT ---------------------------- */
    const editModal = document.getElementById('edit_modal');
    const editBankAccountGrabID =(accountID)=>{
        $("#aid").val(accountID);
        $.ajax({
        type: 'GET',
        url: uRL+'admin/investor/accounts/'+accountID,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            $("#bankDetails_fields_loader").html("Loading...");
            $("#bankDetails_fields").hide();
        },
        success: function(response) {
            console.log('Single Account:', response);
            $("#bankDetails_fields_loader").html("");
            $("#bankDetails_fields").show();
            $('#edit_bankcode').val(response.account.bankcode);
            $('#edit_accountnumber').val(response.account.accountnumber);
            $('#edit_accountname').val(response.account.accountname);
        },
        error: function(xhr, textStatus, errorThrown) {
            editModal.style.display="none";
            $("#bankDetails_fields_loader").html("");
            $("#bankDetails_fields").show();
            if (xhr.responseJSON) {
            if(Array.isArray(xhr.responseJSON.message)){
                toast.error(xhr.responseJSON.message[0]);
                return;
            }
            toast.error(xhr.responseJSON.message);
            setInterval(function() {$(".nlbtn").show(); $(".lbtn").hide(); },1500);
            }
        },
        complete: function() {
            
        }
        });
    }
    /* ----------------------------- GET ONE ACCOUNT ---------------------------- */

    /* --------------- CLICK BANK ROW TO GO TO ADD OLD INVESTMENT --------------- */
    // const gotoAddInvestment =(acc_id)=>{
    //     // alert(acc_id);
    //     const investorid = Cookies.get('investorid');
    //     const investorcode = Cookies.get('investorcode');
    //     if(investorid && investorcode){
    //         navigate(`/add-investment?investor=${investorid}&investorcodenumber=${investorcode}&acc_id=${acc_id}`);
    //     }
    // }


    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {loading && <Loader />}
                {!loading && (
                    <div className="page-header">
                        <div className="content-page-header d-flex">
                            <h5>Investor Banks</h5>
                            <h6>Investor's Code Number: {decryptedInvestorCode}</h6>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-md-12">
                        {/* ------------------------------ Bank Details ------------------------------ */}
                        <form id='addInvestorBank'>
                            <div className="card-body">
                                <div className="form-group-customer customer-additional-form">
                                    <div className="row">
                                        <h5 className="form-title">Bank Details</h5>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <select className="form-control" id='bankcode'  name="bank" required>
                                                    <option value={''}>Select Bank</option>
                                                    {!fetchedBanks ? "" :
                                                        fetchedBanks.map((bank,index) => (
                                                            <option key={index} value={bank.bankcode}>{bank.name}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label>Account Number <span className='text-success fetchaccount_loader' style={{display:'none'}}><i>...Fetching Account Details</i></span></label>
                                                <input type="number" maxLength={10} minLength={10} className="form-control" placeholder="Enter Account Number"
                                                name="accountnumber" onInput={fetchAccountDetails} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input type="text" id='accountname' className="form-control" placeholder="Enter Account Holder Name"
                                            name="accountName" readOnly required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="reset" className="btn btn-primary cancel me-2">Cancel</button>
                                        <button type="submit" className="btn btn-primary">Add Bank</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* ------------------------------ Bank Details ------------------------------ */}


                        {/* ------------------------------ MENU BUTTONS ------------------------------ */}
                        <div className='row form-group-item'>
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex my-2">
                                <Link className="btn btn-danger w-100" 
                                to={`/add-investment?investor=${Cookies.get('investorid')}&investorcodenumber=${encryptedInvestorCode}`}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> Upload Old Investment 
                                </Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex my-2">
                                <Link className="btn btn-success w-100" 
                                to={`/add-newinvestment?investor=${Cookies.get('investorid')}&investorcodenumber=${encryptedInvestorCode}`}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> Add New Investment 
                                </Link>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex my-2">
                                <Link className="btn btn-primary w-100" to={`/investor-noks?investorcodenumber=${encryptedInvestorCode}`}>
                                <i className="fa fa-eye" aria-hidden="true"></i> Next of Kins 
                                </Link>
                            </div>
                        </div>
                        {/* ------------------------------ MENU BUTTONS ------------------------------ */}


                        {/* -------------------------------- Bank List ------------------------------- */}
                        {loading && <Loader />}
                        {!loading && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-center table-hover datatable">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Bank Name</th>
                                                            <th>Acc. Number</th>
                                                            <th>Acc. Name</th>
                                                            <th>Created</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fetchedAccounts.map((acc,index) => (
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{getBankNameFromCode(acc.bankcode)}</td>
                                                                <td>{acc.accountnumber}</td>
                                                                <td>{acc.accountname}</td>
                                                                <td>{acc.created_at}</td>
                                                                <td>
                                                                    {
                                                                       acc.status==="0"? <span className="badge badge-pill bg-danger-light">Inactive</span> :
                                                                       acc.status==="1"? <span className="badge badge-pill bg-success-light">Active</span> :
                                                                       <span className="badge badge-pill bg-success-light">Active</span>
                                                                    }
                                                                </td>
                                                                <td className="d-flex align-items-center">
                                                                    <div className="dropdown dropdown-action">
                                                                        <Link to="/user-profile" className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </Link>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <ul>
                                                                                <li role='button'>
                                                                                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#edit_modal" 
                                                                                    onClick={() => {
                                                                                        editBankAccountGrabID(acc.id);
                                                                                        changeAccountID(acc.id);
                                                                                    }}>
                                                                                    <i className="far fa-edit me-2"></i>View 
                                                                                    </Link>
                                                                                </li>
                                                                                <li role='button'>
                                                                                    <span className="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={() => changeAccountID(acc.id)}>
                                                                                    <i className="far fa-trash-alt me-2"></i>Delete </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                                <ul className="pagination">
                                                    {fetchedPagination.map((data, index) => (
                                                    <li key={index} className={`paginate_button page-item ${replaceQuotationMarks(data.label).trim()} 
                                                    ${data.active ? 'active':'disabled'}`} 
                                                    id="DataTables_Table_0_previous">
                                                        <Link aria-controls="DataTables_Table_0" className="page-link" onClick={()=>handleInvestorBankManagement(data.url)}>
                                                        {replaceQuotationMarks(data.label).trim()}
                                                        </Link>
                                                    </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* -------------------------------- Bank List ------------------------------- */}
                        
                    </div>
                </div>
                
            </div>

            {/* Delete Modal */}
            <div className="modal modal-backdrop fade" id="delete_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-danger ms-2 nlbtn" onClick={()=>deleteBankAccount(accountIdForAction)}>Delete</button>
                    <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Delete Modal */}

            {/* Edit Modal */}
            <div className="modal modal-backdrop fade" id="edit_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form id='editInvestorBank'>
                            <input type="hidden" id="aid" name="accountid" />
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit Account</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div id='bankDetails_fields_loader'></div>
                            <div className="modal-body">
                                <div className="row" id='bankDetails_fields'>
                                    <h5 className="form-title">Bank Details</h5>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Bank Name</label>
                                            <select className="form-control" onChange={editBankcodeChange} id='edit_bankcode'  name="bank" required>
                                                <option value={''}>Select Bank</option>
                                                {!fetchedBanks ? "" :
                                                    fetchedBanks.map((bank,index) => (
                                                        <option key={index} value={bank.bankcode}>{bank.name}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Account Number <span className='text-success edit_fetchaccount_loader' style={{display:'none'}}><i>...Fetching Account Details</i></span></label>
                                            <input type="number" id='edit_accountnumber' maxLength={10} minLength={10} className="form-control" placeholder="Enter Account Number"
                                            name="accountnumber" onInput={edit_fetchAccountDetails} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                        <label>Account Holder Name</label>
                                        <input type="text" value={''} id='edit_accountname' className="form-control" placeholder="Enter Account Holder Name"
                                        name="accountName" readOnly required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-danger ms-2 nlbtn">Edit</button>
                                <button type="button" className="btn btn-danger ms-2 lbtn" style={{display:'none'}} disabled>Loading...</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Edit Modal */}

        </div>
    )
}

export default InvestorBanks